import React, { forwardRef, useState } from "react";
import { TextareaAutosize, FormControl, InputLabel, OutlinedInput, FormHelperText, styled, OutlinedInputProps } from "@mui/material";

interface CustomTextareaProps extends Omit<OutlinedInputProps, "inputComponent"> {
  label?: string;
  helperText?: string;
  minRows?: number;
  maxLength?: number;
}

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  resize: "vertical",
  minHeight: "1.4375em", // Set the minimum height to match a typical TextField
}));

const TextareaComponent = forwardRef<HTMLTextAreaElement, any>(({ minRows, ...props }, ref) => (
  <StyledTextareaAutosize ref={ref} minRows={minRows} {...props} />
));

const TextArea: React.FC<CustomTextareaProps> = ({ label, required, error, helperText, minRows = 4, maxLength, ...props }) => {
  const { onChange, ...outlinedInputProps } = props;

  const [isFocused, setIsFocused] = useState(false);

  // Determine if the label should shrink
  const shouldLabelShrink = isFocused || Boolean(props.value); // Ensure this is never null

  return (
    <FormControl fullWidth required={required} error={error} variant="outlined">
      {label && (
        <InputLabel shrink={shouldLabelShrink} htmlFor="custom-textarea">
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        id="custom-textarea"
        notched={shouldLabelShrink}
        label={label}
        inputComponent={TextareaComponent as any}
        inputProps={{
          maxLength,
          minRows,
          ...props.inputProps,
          onFocus: (e) => {
            setIsFocused(true);
            if (props.inputProps?.onFocus) props.inputProps.onFocus(e);
          },
          onBlur: (e) => {
            setIsFocused(false);
            if (props.inputProps?.onBlur) props.inputProps.onBlur(e);
          },
        }}
        onChange={onChange} // Explicitly pass onChange to satisfy the expected type
        {...outlinedInputProps}
      />
      {helperText && <FormHelperText id="custom-textarea-helper-text">{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default TextArea;
