import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Box, TextField, Typography, Chip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ISkill, IWorkExperienceSkill } from "../models/models";
import { Controls } from "./controls/Controls";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

interface ISkillProps {
  parentId: number;
  skillType?: string;
  maxSkills?: number;
  initialSkillSet?: string[];
  resumeOrWorkExperienceskillSet?: ISkill[] | IWorkExperienceSkill[];
  regenerateWithAi?: boolean;
  generateSkillSet?: (skillType: string) => void;
  isFetching?: boolean;
  onSkillDelete: (skillName: string) => void;
  onSkillAdd: (skillName: string, skillType: string) => void;
  // resumeId: string;
  // resumeData: IResume | IWorkExperience;
}

const Skills = React.memo((props: ISkillProps) => {
  const {
    onSkillDelete,
    onSkillAdd,
    parentId,
    skillType = "Skill",
    maxSkills = 10,
    regenerateWithAi = true,
    initialSkillSet,
    resumeOrWorkExperienceskillSet = [],
    generateSkillSet,
    isFetching,
  } = props;
  const [skillSet, setSkillSet] = useState<string[]>([]);
  const [skill, setSkill] = useState<string>("");

  useEffect(() => {
    initialSkillSet && setSkillSet(initialSkillSet);
  }, [initialSkillSet]);

  const deleteSkill = useCallback((skillToDelete: string) => {
    setSkillSet((prevSkills) => prevSkills.filter((skill) => skill !== skillToDelete));
  }, []);

  const addSkill = useCallback((newSkill: string) => {
    setSkillSet((prevSkills) => {
      // Check if the skill already exists to prevent duplicates
      if (prevSkills.includes(newSkill)) {
        return prevSkills;
      }
      return [...prevSkills, newSkill];
    });
  }, []);

  const handleDelete = useCallback(
    (skillName: string) => {
      // Add the skill back to the pool of available skills
      onSkillDelete(skillName);
      addSkill(skillName);
    },
    [addSkill, onSkillDelete]
  );

  const handleClick = useCallback(
    (skillName: string) => {
      onSkillAdd(skillName, skillType);
      deleteSkill(skillName);
    },
    [deleteSkill, onSkillAdd, skillType]
  );

  const handleTextInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSkill(e.target.value);
  }, []);

  const handleSkillAddButtonClick = useCallback(() => {
    onSkillAdd(skill, skillType);
    setSkill("");
  }, [onSkillAdd, skill, skillType]);

  // Filter skills based on skillType
  const filteredSkills = skillType
    ? (resumeOrWorkExperienceskillSet as Array<ISkill | IWorkExperienceSkill>).filter((skill) => (skill.type ? skill.type === skillType : true))
    : resumeOrWorkExperienceskillSet;

  const filteredSkillsByTypeLength = (
    skillType
      ? (resumeOrWorkExperienceskillSet as Array<ISkill | IWorkExperienceSkill>).filter((skill) => (skill.type ? skill.type === skillType : true))
      : resumeOrWorkExperienceskillSet
  ).length;

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "left", flexWrap: "wrap", listStyle: "none", width: "100%" }}>
        {filteredSkills.map((skill: ISkill | IWorkExperienceSkill) => (
          <ListItem key={`${skill.name}-${parentId}`}>
            <Chip label={skill.name} onDelete={() => handleDelete(skill.name)} color="success" />
          </ListItem>
        ))}
      </Box>
      <Box display="flex" flexDirection="row" gap={1} alignItems="center" sx={{ mb: filteredSkillsByTypeLength >= maxSkills ? 0 : 1, mt: 3 }}>
        <TextField
          value={skill}
          id={`"skillAddField-"${skillType ? skillType : ""}-${parentId}`}
          label={filteredSkillsByTypeLength >= maxSkills ? "" : "Skill"}
          fullWidth
          onChange={handleTextInputChange}
          disabled={filteredSkillsByTypeLength >= maxSkills}
          error={filteredSkillsByTypeLength >= maxSkills}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !(filteredSkillsByTypeLength >= maxSkills)) {
              handleSkillAddButtonClick();
            }
          }}
        />
        <Controls.Button text="Add" onClick={handleSkillAddButtonClick} disabled={filteredSkillsByTypeLength >= maxSkills} />
      </Box>
      <Box sx={{ mb: filteredSkillsByTypeLength >= maxSkills ? 1 : 0, lineHeight: 1 }}>
        <Typography variant="caption" style={{ whiteSpace: "pre-wrap" }} color="red">
          {filteredSkillsByTypeLength >= maxSkills
            ? `Maximum of ${maxSkills} skills reached in this field.\nThis field serves as keywords for your work experience bullets. Please focus on your main skills.`
            : " "}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "left", flexWrap: "wrap", listStyle: "none", width: "100%" }}>
        {skillSet.map((skill: string) => (
          <ListItem key={skill}>
            <Chip icon={<AddIcon />} label={skill} onClick={() => handleClick(skill)} disabled={filteredSkillsByTypeLength >= maxSkills} />
          </ListItem>
        ))}
        {regenerateWithAi && (
          <Box sx={{ width: "100%", mt: skillSet.length > 0 ? 2 : 0 }}>
            <Controls.Button
              text={isFetching ? "Loading.." : skillSet.length === 0 ? "Generate with AI" : "Regenerate with AI"}
              onClick={generateSkillSet}
              disabled={isFetching || filteredSkillsByTypeLength >= maxSkills}
              isLoading={isFetching}
            />
          </Box>
        )}
      </Box>
    </>
  );
});

export default Skills;
