import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import { ICertificate, IResume } from "../../../models/models";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controls } from "../../../components/controls/Controls";
import { cvApi, useCreateCertificateMutation, useDeleteCertificateMutation } from "../../../store/api/resume.api";
import { useAppDispatch } from "../../../store/hooks";
import AlertDialog from "../../../components/AlertDialog";
import CertificateForm from "./CertificateForm";

interface CertificateProps {
  resumeData: IResume;
  showActionButtons?: boolean;
}

export default function Certificate({ resumeData, showActionButtons = true }: CertificateProps) {
  const [deletingIetemId, setDeletingIetemId] = useState<null | string>(null);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [createCertificate, { data: newCertificateData, isLoading: isLoadingCreate }] = useCreateCertificateMutation();
  const [deleteCertificate] = useDeleteCertificateMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    newCertificateData && setExpanded(newCertificateData.id.toString());
  }, [newCertificateData]);

  const handleDeleteCertificate = async (id: string | null) => {
    if (id) {
      setDeletingIetemId(null);
      await deleteCertificate(id);
      dispatch(cvApi.util.invalidateTags([{ type: "Resume", id: resumeData.id }]));
    }
  };

  const handleAddCertificate = async (resumeId: string) => {
    await createCertificate(resumeData);
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        {resumeData.certificates.map((item: ICertificate, index) => (
          <Box display={"flex"} flexDirection={"row"} key={`edu-${index}`} alignItems={"flex-start"}>
            {/* <Accordion key={item.id} expanded={expand && elementsCount === index}> */}
            <Accordion key={item.id} sx={{ width: "100%" }} expanded={expanded === item.id.toString()} onChange={handleAccordionChange(item.id.toString())}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography>{item.name !== "" ? item.name : "Certificate Name"}</Typography>
                  {/* 
                
                  <Typography>{item.name}</Typography>
                  <Typography>{item.degree}</Typography> */}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <CertificateForm certificate={item} resumeData={resumeData} />
              </AccordionDetails>
            </Accordion>
            {showActionButtons && (
              <Tooltip title="Delete">
                <IconButton onClick={() => setDeletingIetemId(item.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ))}
      </Box>
      {showActionButtons && (
        <Box sx={{ mt: 1 }}>
          <Controls.Button
            disabled={isLoadingCreate}
            text={isLoadingCreate ? "Creating.." : "Add new certificate"}
            onClick={() => handleAddCertificate(resumeData.id.toString())}
            isLoading={isLoadingCreate}
          ></Controls.Button>
        </Box>
      )}
      <AlertDialog
        title="Do you want to delete this Certificate?"
        buttonOneCallback={() => handleDeleteCertificate(deletingIetemId)}
        onClose={() => setDeletingIetemId(null)}
        isOpen={!!deletingIetemId}
      />
    </>
  );
}
