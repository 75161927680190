// MainContent.tsx
import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useActions } from "../../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

/**
 * Props for MainContent
 * @param {boolean} isDrawerOpen - Indicates if the navigation drawer is open.
 * @param {number} appBarHeight - The height of the AppBar to adjust content placement.
 * @param {React.ReactNode} leftContent - Content for the left side of the main area.
 * @param {React.ReactNode} rightContent - Content for the right side of the main area.
 * @param {React.CSSProperties} [style] - Optional custom styles for the main content container.
 */
interface MainContentProps {
  isDrawerOpen: boolean;
  appBarHeight: number;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  style?: React.CSSProperties;
}

/**
 * MainContent
 * Renders the main content area of the application with two sections (left and right).
 * It adjusts its top margin based on the AppBar's height and can contain custom content in both sections.
 *
 * @param {MainContentProps} props - Props for the MainContent component.
 * @returns {React.ReactElement} - A flex-box styled container with two content areas.
 */
const MainContent: React.FC<MainContentProps> = ({ isDrawerOpen, appBarHeight, leftContent, rightContent, style }: MainContentProps): React.ReactElement => {
  const hasLeftContent = Boolean(leftContent);
  const hasRightContent = Boolean(rightContent);
  const [isDragging, setIsDragging] = useState(false); // New state to track dragging
  const { setSplitRatio } = useActions();
  const splitRatio = useSelector((state: RootState) => state.settings.splitRatio);
  const [leftFlexBasis, setLeftFlexBasis] = useState(splitRatio); // Initial split ratio
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // Update local state if splitRatio changes externally
    setLeftFlexBasis(splitRatio);
  }, [splitRatio]);

  // Adjustments for small screens
  useEffect(() => {
    if (isXsScreen) {
      setLeftFlexBasis("50%");
    } else {
      setLeftFlexBasis(splitRatio);
    }
  }, [isXsScreen, splitRatio]);

  const handleMouseDown = (e: any) => {
    e.preventDefault(); // Prevent text selection during drag
    setIsDragging(true); // Set dragging to true when mouse is down
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: any) => {
    e.preventDefault();
    const xOffset = isDrawerOpen ? 180 : 57;
    const cursorPosition = e.clientX - xOffset; // Subtract drawer width if open

    // Calculate the new flex basis based on cursor movement
    // You might need to adjust this calculation based on your layout
    const newFlexBasis = `${Math.max(10, Math.min(90, (cursorPosition / (window.innerWidth - xOffset)) * 100))}%`;
    setLeftFlexBasis(newFlexBasis);
    setSplitRatio(newFlexBasis);
  };

  const handleMouseUp = () => {
    setIsDragging(false); // Reset dragging to false when mouse is up
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        height: `calc(100vh - ${appBarHeight}px)`,
        overflow: "hidden",
        width: "100%",
        ml: isDrawerOpen ? `calc(180px - 57px)` : "0",
        mt: `${appBarHeight}px`,
        ...style,
      }}
    >
      {/* Left side content */}
      {hasLeftContent && (
        <Box
          sx={{
            flexBasis: hasRightContent ? leftFlexBasis : "100%", // Full width if right content is not provided
            overflowY: "auto",
            height: "100%",
            p: 1,
          }}
          role="region"
          aria-label="Left Content Area"
        >
          {leftContent}
        </Box>
      )}

      {/* Draggable Divider - Only render if both left and right content are present */}
      {hasLeftContent && hasRightContent && !isXsScreen && (
        <Box
          sx={{
            cursor: "col-resize",
            width: "5px",
            backgroundColor: isDragging ? "primary.main" : "background.default", // Change based on dragging state
            height: "100%",
            transition: "background-color 0.3s",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onMouseDown={handleMouseDown}
        />
      )}

      {/* Right side content */}
      {hasRightContent && (
        <Box
          sx={{
            flexBasis: hasLeftContent ? `calc(100% - ${leftFlexBasis})` : "100%", // Full width if left content is not provided
            overflowY: "auto",
            height: "100%",
            p: 1,
            pl: (theme) => `calc(${theme.spacing(1)} - 5px)`, // Reduce left padding by 2px
          }}
          role="region"
          aria-label="Right Content Area"
        >
          {rightContent}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(MainContent);
