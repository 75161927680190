import Button from "./Button";
import DatePicker from "./DatePicker";
import Input from "./Input";
import Select from "./Select";

export const Controls = {
  Button,
  DatePicker,
  Input,
  Select,
};
