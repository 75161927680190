import React from "react";
import { Box, CircularProgress, Button as MuiButton } from "@mui/material/";

export default function Button(props: any) {
  const { text, size, color, variant, onClick, isLoading, ...other } = props;

  return (
    <Box position={"relative"} width={"fit-content"}>
      <MuiButton size={size || "large"} color={color || "primary"} variant={variant || "contained"} onClick={onClick} {...other}>
        {text}
      </MuiButton>
      {isLoading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
}
