import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";

export default function DatePicker(props: any) {
  const convertToDefEventPara = (name: any, value: any) => ({
    target: {
      name,
      value,
    },
  });
  const [open, setOpen] = useState(false);

  const { id, name, label, value, onChange, helperText, ...other } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        onChange={(newValue) => onChange(convertToDefEventPara(name, newValue))}
        label={label}
        views={["year", "month"]}
        maxDate={new Date()}
        slotProps={{ textField: { fullWidth: true, name, id, helperText, onClick: (e) => setOpen(true), sx: { input: { cursor: "pointer" } } } }}
        {...other}
      />
    </LocalizationProvider>
  );
}
