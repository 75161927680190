import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Typography, AppBar, Toolbar, Paper } from "@mui/material";
// import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface Message {
  id: string;
  text: string;
  sender: "user" | "assistant";
  type: "normal" | "warning"; // New property to distinguish between normal messages and warnings
}

const ChatWithAI: React.FC<{ notifyNewMessage: () => void; updateWarningState: (warningExists: boolean) => void }> = ({
  notifyNewMessage,
  updateWarningState,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  // const [inputValue, setInputValue] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null); // Ref for auto-scrolling

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  // const handleSend = useCallback(
  //   (event: React.FormEvent | React.MouseEvent) => {
  //     event.preventDefault();
  //     if (inputValue.trim()) {
  //       const newMessage: Message = {
  //         id: Date.now().toString(),
  //         text: inputValue,
  //         sender: "user",
  //         type: "normal", // Mark this message as a warning
  //       };
  //       setMessages((prevMessages) => [...prevMessages, newMessage]);
  //       setInputValue("");
  //       notifyNewMessage();
  //     }
  //   },
  //   [inputValue, notifyNewMessage]
  // );

  const PDFTotalPages = useSelector((state: RootState) => state.chatapp.pdf.totalPages);

  useEffect(() => {
    // Case when the PDF page count suggests a warning is necessary
    if (PDFTotalPages > 1) {
      const autoResponseText = "Warning: Your resume might be more than the recommended one-page format.";
      const existingWarning = messages.find((m) => m.text === autoResponseText && m.type === "warning");

      if (!existingWarning) {
        const autoMessage: Message = {
          id: `assistant-${Date.now()}`,
          text: autoResponseText,
          sender: "assistant",
          type: "warning",
        };
        setMessages((prevMessages) => [...prevMessages, autoMessage]);
      }
    } else if (PDFTotalPages === 1) {
      // Case when no warning is needed, remove any existing warning
      const updatedMessages = messages.filter((m) => m.type !== "warning");
      if (updatedMessages.length !== messages.length) {
        setMessages(updatedMessages);
      }
    }
  }, [PDFTotalPages, messages]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]); // Depend on scrollToBottom, which itself depends on messages

  useEffect(() => {
    // Determine if there are any warning messages
    const hasWarningMessage = messages.some((message) => message.type === "warning");
    updateWarningState(hasWarningMessage);
  }, [messages, updateWarningState]);

  // Group messages into warnings and regular messages
  const warningMessages = messages.filter((message) => message.type === "warning");
  const regularMessages = messages.filter((message) => message.type === "normal");

  // Combine them with warnings first
  const orderedMessages = [...warningMessages, ...regularMessages];

  return (
    <Box sx={{ position: "fixed", bottom: 80, right: 16, width: 300 }} hidden={warningMessages.length === 0}>
      <AppBar position="static" sx={{ borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}>
        <Toolbar>
          <Typography variant="h5" color={"white"} p={0}>
            Recomendations
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ maxHeight: "400px", overflowY: "auto", background: "#f5f5f5" }}>
        {orderedMessages.map((message) => (
          <Box key={message.id} sx={{ textAlign: message.sender === "user" ? "right" : "left", padding: "8px" }}>
            <Paper
              sx={{
                display: "inline-block",
                padding: "8px",
                maxWidth: "75%",
                wordWrap: "break-word",
                backgroundColor: message.type === "warning" ? "#ffcccc" : "white",
              }}
            >
              <Typography color={message.type === "warning" ? "red" : "inherit"}>{message.text}</Typography>
            </Paper>
          </Box>
        ))}
        <div ref={messagesEndRef} /> {/* Invisible element for auto-scrolling */}
      </Box>
      {/* <Paper component="form" sx={{ display: "flex", alignItems: "center", padding: "2px 4px" }} onSubmit={handleSend}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Write a message"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSend(e);
            }
          }}
        />
        <IconButton sx={{ p: "10px" }} aria-label="send" onClick={handleSend}>
          <SendIcon />
        </IconButton>
      </Paper> */}
    </Box>
  );
};

export default ChatWithAI;
