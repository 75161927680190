import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { states } from "../utils/stateUtils";

export default function StateSelect(props: any) {
  const { handleChange, currentValue, disabled, id, label } = props;
  const [currenttValue] = useState(currentValue !== "" ? states.find((state) => state.code === currentValue) : null);

  return (
    <Autocomplete
      id={id || "state"}
      disabled={disabled}
      defaultValue={currenttValue}
      onChange={handleChange}
      fullWidth
      options={states}
      autoHighlight
      getOptionLabel={(option: any) => option.label}
      isOptionEqualToValue={(option, value) => {
        if (value && value !== undefined) return option.label === value.label;
        return false;
      }}
      renderOption={(props, option: any) => (
        <Box component="li" {...props}>
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || `State ("WA" or "CA")`}
          inputProps={{
            ...params.inputProps,
            autoComplete: "address-level1", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
