import { useEffect } from "react";

/**
 * Custom hook to handle the browser's beforeunload event in TypeScript.
 *
 * @param onBeforeUnload - The function to execute when the user tries to leave the page.
 */
const useBeforeUnload = (onBeforeUnload: () => string | void) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const returnValue = onBeforeUnload();
      if (returnValue) {
        event.preventDefault(); // Standard for most browsers
        event.returnValue = returnValue; // Required for Chrome
      }
    };

    // Adding the event listener for the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [onBeforeUnload]); // The hook reruns if onBeforeUnload function changes
};

export default useBeforeUnload;
