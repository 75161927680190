import {} from "immutable";
import { MiddlewareAPI, combineReducers, configureStore, isFulfilled, isRejectedWithValue } from "@reduxjs/toolkit";
import { reducer as authReducer, logout } from "./auth/auth.slice";
import { reducer as settingsReducer } from "./settings/settings.slice";
import { reducer as chatappReducer } from "./chatapp/chatapp.slice";

import { api } from "./api/api";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, createMigrate } from "redux-persist";
import { enqueueSnackbar } from "notistack";

// import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
// import { createLogger } from "redux-logger";
// const logger = createLogger({
//   // ...options
// });

const migrations = {
  // Migration to add tableSorts to the settings slice
  3: (state: any) => ({
    ...state,
    settings: {
      ...state.settings,
      tableSorts: {
        resumesTable: { field: "createdAt", direction: "asc" },
        tailoredResumesTable: { field: "createdAt", direction: "asc" },
      },
    },
  }),
  4: (state: any) => ({
    ...state,
    settings: {
      ...state.settings,
      lastSelectedResumes: [],
    },
  }),
  5: (state: any) => ({
    ...state,
    settings: {
      ...state.settings,
      // Assuming a default split ratio of "50%" for existing users
      splitRatio: state.settings.splitRatio ?? "50%",
    },
  }),
  // Add other migrations as needed
};

const persistConfig = {
  key: "root",
  version: 5,
  storage,
  migrate: createMigrate(migrations, { debug: false }),
  // blacklist: [api.reducerPath, "resume"],
  whitelist: ["auth", "settings"],

  // stateReconciler: autoMergeLevel2, //TODO fix this
};

interface IError {
  message: string;
  context?: { [key: string]: unknown };
}

const rtkQueryErrorLogger = (api: MiddlewareAPI) => (next: any) => (action: any) => {
  const { dispatch } = api;
  if (isFulfilled(action)) {
    if (action.payload.successMessage) {
      enqueueSnackbar(action.payload.successMessage, { variant: "success" });
    }
  }
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 402) {
      window.location.href = "/profile";
    } else if (action.payload.status === 404) {
      window.location.href = "/404";
    } else if (action.payload.originalStatus === 401) {
      dispatch(logout());
      window.location.href = "/signin";
    } else
      action.payload.data.errors.forEach((error: IError) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  }

  return next(action);
};

const rootReducer = combineReducers({ auth: authReducer, [api.reducerPath]: api.reducer, settings: settingsReducer, chatapp: chatappReducer });

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(api.middleware)
      .concat(rtkQueryErrorLogger),
  // .concat(logger),
});

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
