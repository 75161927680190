import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Outlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Controls } from "./controls/Controls";

export default function ProtectedRoute() {
  const { token } = useSelector((state: RootState) => state.auth);

  if (!token) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h1">Unauthorized!</Typography>
        <Typography variant="h6">
          Sorry, you are not authorized to access this page. Please check your login credentials or contact the administrator for access.
        </Typography>
        <Controls.Button text="Login in" href="/signin" sx={{ mt: 1 }}></Controls.Button>
      </Box>
    );
  }

  // returns child route elements
  return <Outlet />;
}
