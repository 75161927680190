import { bindActionCreators } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { actions as authActions } from "../store/auth/auth.slice";
import { actions as settingsActions } from "../store/settings/settings.slice";
import { actions as chatappActions } from "../store/chatapp/chatapp.slice";

const rootActions = {
  ...authActions,
  ...settingsActions,
  ...chatappActions,
};

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};
