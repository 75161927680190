import React from "react";

import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import { AppBarButtonsProps, IResume } from "../models/models";
import { Controls } from "./controls/Controls";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { format } from "date-fns";
import { useActions } from "../hooks/useActions";
import { loadAndSetPageCount } from "../utils/documentUtils";

// const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
//   "& .MuiBadge-badge": {
//     right: 185,
//     top: 5,
//     padding: "0 4px",
//   },
// }));

function isIResume(object: any): object is IResume {
  return object.type === "IResume";
}

const AppBarButtons: React.FC<AppBarButtonsProps> = ({
  isLoadingGenerateCv,
  downloadDOCXHandle,
  isLoadingUpdateResume,
  updateResume,
  resumeData,
  document,
}) => {
  const { setPDFTotalPages } = useActions();

  const handlePDFLoad = async (pdfBlob: Blob) => {
    try {
      await loadAndSetPageCount(pdfBlob, setPDFTotalPages);
    } catch (error) {
      console.error("Error loading PDF:", error);
    } finally {
    }
  };
  return (
    <>
      {/* <StyledBadge badgeContent={"Beta"} color="secondary"> */}
      <Controls.Button
        disabled={isLoadingGenerateCv}
        text={isLoadingGenerateCv ? "Downloading.." : "Download DOCX"}
        onClick={downloadDOCXHandle}
        isLoading={isLoadingGenerateCv}
        sx={{ maxWidth: "100%", ml: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
        startIcon={<DownloadIcon />}
      />
      {/* </StyledBadge> */}
      <PDFDownloadLink
        document={document}
        fileName={`${isIResume(resumeData) ? resumeData.firstName : resumeData.customizedResume.firstName} ${
          isIResume(resumeData) ? resumeData.lastName : resumeData.customizedResume.lastName
        } ${resumeData.jobTitle} ${format(new Date(), "M-d-Y")}.pdf`}
      >
        {({ blob, url, loading, error }) => {
          if (error) {
            // return <div>Something went wrong: {error.message}</div>;
          }
          if (loading) {
            // return <div>Loading...</div>;
          }
          if (blob) {
            handlePDFLoad(blob);
          }
          return (
            <Controls.Button
              text={"Download PDF"}
              sx={{ maxWidth: "100%", mx: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              startIcon={<DownloadIcon />}
            />
          );
        }}
      </PDFDownloadLink>
      <Controls.Button
        disabled={isLoadingUpdateResume}
        text={"Save"}
        onClick={async () => {
          await updateResume(resumeData);
        }}
        isLoading={isLoadingUpdateResume}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%", // Set a maxWidth to contain the button within its parent
        }}
        startIcon={<SaveIcon />}
      />
    </>
  );
};

export default AppBarButtons;
