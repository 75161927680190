import Paper from "@mui/material/Paper";
import { IResume } from "../../../models/models";
import { Controls } from "../../../components/controls/Controls";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { customColors } from "../../../theme";
import { useNavigate } from "react-router-dom";
import { useGetActiveSubscriptionQuery } from "../../../store/api/billing.api";
import { prepareCv } from "../../../utils/documentUtils";
import { Box, Link, Typography } from "@mui/material";

const styles = {
  body: {
    padding: "18.90px",
    maxWidth: { xs: "100%", md: "793.7px" }, // 96 DPI A4
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "12pt",
    fontFamily: "Spectral",
    lineHeight: 1,
    whiteSpace: "nowrap",
  },
  title: {
    fontWeight: 700,
    fontSize: "14pt",
    textAlign: "center",
    fontFamily: "Spectral",
    lineHeight: 1,
  },
  jobTitle: {
    fontWeight: 700,
    fontSize: "14pt",
    textAlign: "center",
    fontFamily: "Spectral",
    lineHeight: 1,
  },
  contacts: {
    marginTop: "4px",
    textAlign: "center",
    fontSize: "12pt",
    fontFamily: "Spectral",
    lineHeight: 1,
  },
  header: {
    fontSize: "14pt",
    textAlign: "left",
    fontWeight: 700,
    textTransform: "uppercase",
    borderBottom: "0.8px solid",
    marginBottom: "4px",
    marginTop: "4px",
    fontFamily: "Spectral",
    lineHeight: 1,
  },
  text: {
    textAlign: "justify",
    fontSize: "12pt",
    fontFamily: "Spectral",
    lineHeight: 1,
  },

  summary: {
    textAlign: "justify",
    fontSize: "12pt",
    fontFamily: "Spectral",
    lineHeight: 1,
    whiteSpace: "pre-wrap",
  },

  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row", // Explicitly define if you want horizontal layout
  },
  flexRow: {
    display: "flex",
    flexDirection: "row", // Explicitly define if you want horizontal layout
  },
  companyName: {
    fontSize: "12pt",
    fontWeight: 700,
    fontFamily: "Spectral",
    lineHeight: 1,
  },
  countryCityStateText: {
    fontSize: "12pt",
    fontWeight: 700,
    fontFamily: "Spectral",
    lineHeight: 1,
  },
  companyDesc: {
    fontSize: "12pt",
    fontStyle: "italic",
    fontFamily: "Spectral",
    lineHeight: 1,
  },
  bulletPoint: {
    fontSize: "12pt",
    marginLeft: "13.33px",
    fontFamily: "Spectral",
    lineHeight: 1,
    whiteSpace: "pre-wrap",
  },
  lineHeightOne: {
    lineHeight: 1,
  },
};

export default function Preview(props: { resumeData: IResume }) {
  const { resumeData } = props;
  const resume = prepareCv(resumeData);
  const { data } = useGetActiveSubscriptionQuery();

  const navigate = useNavigate();

  return (
    <>
      <Paper sx={styles.body}>
        <Typography sx={styles.title}>
          {resume.firstName} {resume.lastName}
        </Typography>
        <Typography sx={styles.jobTitle}>{resume.jobTitle}</Typography>
        <Typography sx={styles.contacts}>
          {`${resume.city}, ${resume.state} • ${resume.email} • `}
          <Link sx={styles.link} href={`tel:${resume.phone}`}>
            {resume.phone}
          </Link>
          {resume.linkedInProfileLink && ` • `}
          {resume.linkedInProfileLink && (
            <Link sx={styles.link} href={resume.linkedInProfileLink}>
              {resume.linkedInProfileLink}
            </Link>
          )}
        </Typography>

        {/* Summary Section */}
        <Typography sx={styles.header}>SUMMARY</Typography>
        <Typography sx={styles.summary}>{resume.summary}</Typography>

        {/* Professional Experience Section */}
        <Typography sx={styles.header}>PROFESSIONAL EXPERIENCE</Typography>
        {resume.workExperiences.map((experience, index) => (
          <Box key={index} sx={index > 0 ? { marginTop: "4px" } : {}}>
            <Box sx={styles.spaceBetween}>
              <Typography sx={styles.companyName}>{experience.companyName}</Typography>
              <Typography sx={styles.countryCityStateText}>{experience.countryCityStateText}</Typography>
            </Box>
            <Box sx={styles.spaceBetween}>
              <Typography sx={[styles.lineHeightOne, styles.text]}>{experience.position}</Typography>
              <Typography sx={[styles.lineHeightOne, styles.text]}>{experience.startDate}</Typography>
            </Box>
            <Typography sx={styles.companyDesc}>{experience.companyDesc}</Typography>
            {/* Assuming bullets is an array of strings */}
            <Typography sx={styles.bulletPoint}>{experience.bullets}</Typography>
          </Box>
        ))}

        {/* Education Section */}
        {resume.educations.length > 0 && (
          <>
            <Typography sx={styles.header}>EDUCATION</Typography>
            {resume.educations.map((education, index) => (
              <Box key={index}>
                <Typography sx={[styles.lineHeightOne, styles.text]}>
                  {/* Conditionally render comma */}
                  <b>{education.degree}</b>
                  {education.degree && education.name && ", "}
                  {education.name}
                </Typography>
              </Box>
            ))}
          </>
        )}

        {/* Certificates Section */}
        {resume.certificates.length > 0 && (
          <>
            <Typography sx={styles.header}>CERTIFICATES</Typography>
            {resume.certificates.map((certificate, index) => (
              <Box key={index}>
                <Typography sx={[styles.lineHeightOne, styles.text]}>
                  {certificate.name}
                  {certificate.name && certificate.issueDate && ", "}
                  {certificate.issueDate}
                </Typography>
              </Box>
            ))}
          </>
        )}

        {/* Skills Section */}
        {resume.otherSkillsText && resume.otherSkillsText.length > 0 && (
          <>
            <Typography sx={styles.header}>SKILLS</Typography>
            <Typography sx={styles.text}>{resume.otherSkillsText}</Typography>
          </>
        )}

        {/* Technology/Tools Section */}
        {resume.techSkillsText && resume.techSkillsText.length > 0 && (
          <>
            <Typography sx={styles.header}>TECHNOLOGY/TOOLS</Typography>
            <Typography sx={styles.text}>{resume.techSkillsText}</Typography>
          </>
        )}
      </Paper>
      {data && data.subscriptionStatus !== "ACTIVE" && (
        <Controls.Button
          onClick={() => navigate("/profile/payment-info")}
          text="Unlock full version"
          sx={{
            mt: 2,
            maxWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "bold",
            backgroundColor: customColors.darkGold.background,
            color: customColors.darkGold.text,
            "&:hover": {
              backgroundColor: customColors.darkGold.hover,
            },
          }}
          startIcon={<UpgradeIcon />}
        />
      )}
    </>
  );
}
