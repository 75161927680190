import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useResetPasswordRequestMutation } from "../../store/api/auth.api";
import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { IPasswordRequestResponse } from "../../models/models";
import Copyright from "./Copyright";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function ResetPasswordRequest() {
  const [resetPasswordRequest, { isLoading, data }] = useResetPasswordRequestMutation();
  const [alert, setAlert] = useState<IPasswordRequestResponse>();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    await resetPasswordRequest({ email: data.get("email") });
  };

  useEffect(() => {
    if (data) {
      setAlert(data);
    }
  }, [data]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {alert ? <Alert severity={alert.severity || "info"}>{alert.message}</Alert> : <></>}
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Typography variant="body2" component="p">
            Please enter your email address below. Further instructions on how to reset your password will be sent to you. It can take up to 10 min.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
            <Grid container>
              <Grid item>
                {"Don't have an account? "}
                <Link href="/signup" variant="body2">
                  {"Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                {"Have an account? "}
                <Link href="/signin" variant="body2">
                  {"Sign In"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
