import React from "react";
import SalesResumePage from "./templates/SalesResumePage";
import TechResumePage from "./templates/TechResumePage";
import { useLocation } from "react-router-dom";

export default function ResumePage() {
  let location = useLocation();
  if (location.state === null) {
    window.location.href = "/404";
  }
  const resumeTemplate = location.state.template;
  return (
    <>
      {resumeTemplate === "sales" && <SalesResumePage title={location.state.title} />}
      {resumeTemplate === "tech" && <TechResumePage title={location.state.title} />}
    </>
  );
}
