import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  pdf: {
    totalPages: 0,
  },
};

const chatappSlice = createSlice({
  name: "chatapp",
  initialState,
  reducers: {
    setPDFTotalPages: (state, action: PayloadAction<number>) => {
      const totalPages = action.payload;
      state.pdf.totalPages = totalPages;
    },
  },
});

export const { actions, reducer } = chatappSlice;

export const { setPDFTotalPages } = chatappSlice.actions;
