import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select as MuiSelect } from "@mui/material";
import React from "react";

export default function Select(props: any) {
  const { id, name, label, value, onChange, items, ...other } = props;
  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}Label`}>{label}</InputLabel>
      <MuiSelect labelId={`${id}Label`} id={id} name={name} value={value} label={label} onChange={onChange} {...other}>
        <MenuItem key="None" value="">
          <em>None</em>
        </MenuItem>
        {items.map((item: any, index: any) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
