import React, { useEffect, useState } from "react";
import { Alert, Divider, Paper, Typography } from "@mui/material";
import { useCreatePortalSessionMutation, useGetActiveSubscriptionQuery } from "../../store/api/billing.api";
import LoaderComponent from "../../components/LoaderComponent";
import Plan from "../../components/Plan";
import { useLocation } from "react-router-dom";
import { SubscriptionInfo } from "../../components/SubscriptionInfo";

export default function PaymentInfoPage() {
  const { data } = useGetActiveSubscriptionQuery();

  const location = useLocation();
  // Parse the search query parameters
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const canceled = searchParams.get("canceled");

  const [createPortalSession, { data: portalData, isLoading }] = useCreatePortalSessionMutation();

  useEffect(() => {
    if (portalData) {
      window.location.href = portalData.url;
    }
  }, [portalData]);

  const handleCreatePortalSession = async (sessionId: string) => {
    await createPortalSession({ sessionId });
  };

  const [showSuccessMessage, setShowSuccessMessage] = useState(!!sessionId);
  const [showCancellationMessage, setShowCancellationMessage] = useState(!!canceled);

  useEffect(() => {
    // Set a timer for showing success message, if applicable
    if (showSuccessMessage) {
      const timer = setTimeout(() => setShowSuccessMessage(false), 5000);
      return () => clearTimeout(timer);
    }
    // Set a timer for showing cancellation message, if applicable
    if (showCancellationMessage) {
      const timer = setTimeout(() => setShowCancellationMessage(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage, showCancellationMessage]);

  // Helper function to render session success info using Alert
  const renderSessionSuccess = () => (
    <Alert severity="success" sx={{ mb: 2, width: "50%" }}>
      Payment Successful!
    </Alert>
  );

  // Helper function to render cancellation info using Alert
  const renderCancellationInfo = () => (
    <Alert severity="error" sx={{ mb: 2, width: "50%" }}>
      Payment was canceled.
    </Alert>
  );

  return (
    <Paper sx={{ p: 3 }}>
      <Divider textAlign="center" id="contactUsDivider" sx={{ mb: 3, fontSize: 14 }}>
        <Typography>Subscription info</Typography>
      </Divider>
      {!data ? (
        <LoaderComponent />
      ) : (
        <>
          {showSuccessMessage && renderSessionSuccess()}
          {showCancellationMessage && renderCancellationInfo()}
          {data.subscription ? (
            <SubscriptionInfo
              productName={data.subscription.productName}
              startedAt={data.subscription.startedAt}
              endedAt={data.subscription.endedAt}
              customer={data.subscription.customer}
              handleCreatePortalSession={handleCreatePortalSession}
              isLoading={isLoading}
            />
          ) : (
            data.subscriptionStatus !== "ACTIVE" && (
              <Alert severity="info" sx={{ mb: 2, width: "50%" }}>
                {data.subscriptionStatus === "CANCELED" && "Your subscription has expired. Please renew your subscription to continue use our services."}
                {data.subscriptionStatus === "NONE" && "There is no active subscription."}
              </Alert>
            )
          )}

          {data.partner && (
            <Typography variant="h5" sx={{ mt: 1 }}>
              {data.partner.payInfo}
            </Typography>
          )}
          {data.plans && (
            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
              {data.plans.map((plan: any) => (
                <Plan
                  id={plan.id}
                  key={plan.id}
                  title={plan.name}
                  price={plan.price}
                  description={plan.description}
                  featured={plan.featured}
                  buttonText={"Upgrade"}
                  lookupKey={plan.lookup_key}
                />
              ))}
            </div>
          )}
        </>
      )}
    </Paper>
  );
}
