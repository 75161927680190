import React from "react";
import { Document, Page, Text, View, StyleSheet, Link, Font } from "@react-pdf/renderer";
import { IResume } from "../../../models/models";
import { prepareCv } from "../../../utils/documentUtils";
import SpectralRegular from "../../../fonts/Spectral/Spectral-Regular.ttf";
import SpectralBold from "../../../fonts/Spectral/Spectral-Bold.ttf";
import SpectralItalic from "../../../fonts/Spectral/Spectral-Italic.ttf";

Font.register({
  family: "Spectral",
  fonts: [{ src: SpectralRegular }, { src: SpectralBold, fontWeight: 700, fontStyle: "normal" }, { src: SpectralItalic, fontWeight: 400, fontStyle: "italic" }],
});

// Define the styles
const styles = StyleSheet.create({
  body: {
    fontFamily: "Spectral",
    padding: 14.17,
    lineHeight: 1,
    fontSize: 12,
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  title: {
    fontWeight: 700,
    fontSize: 14,
    textAlign: "center",
  },
  jobTitle: {
    fontWeight: 700,
    fontSize: 14,
    textAlign: "center",
  },
  contacts: {
    marginTop: 3,
    textAlign: "center",
  },
  header: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: 700,
    textTransform: "uppercase",
    borderBottomWidth: 0.8,
    paddingBottom: 3,
    marginTop: 3,
  },
  text: {
    textAlign: "justify",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row", // Explicitly define if you want horizontal layout
  },
  flexRow: {
    display: "flex",
    flexDirection: "row", // Explicitly define if you want horizontal layout
  },
  companyName: {
    fontWeight: 700,
  },
  countryCityStateText: {
    fontWeight: 700,
  },
  companyDesc: {
    fontStyle: "italic",
  },
  bulletPoint: {
    marginLeft: 10,
  },
});

// Map your resume data to the React PDF structure
const ResumeDocument = (props: { resumeData: IResume }) => {
  const { resumeData } = props;
  const resume = prepareCv(resumeData);
  return (
    <Document pageLayout="singlePage">
      <Page style={styles.body} size={"A4"}>
        <Text style={styles.title}>
          {resume.firstName} {resume.lastName}
        </Text>
        <Text style={styles.jobTitle}>{resume.jobTitle}</Text>
        <Text style={styles.contacts}>
          {`${resume.city}, ${resume.state} • ${resume.email} • `}
          <Link style={styles.link} src={`tel:${resume.phone}`}>
            {resume.phone}
          </Link>
          {resume.linkedInProfileLink && ` • `}
          {resume.linkedInProfileLink && (
            <Link style={styles.link} src={resume.linkedInProfileLink}>
              {resume.linkedInProfileLink}
            </Link>
          )}
        </Text>

        {/* Summary Section */}
        <Text style={styles.header}>SUMMARY</Text>
        <Text style={styles.text}>{resume.summary}</Text>

        {/* Professional Experience Section */}
        <Text style={styles.header}>PROFESSIONAL EXPERIENCE</Text>
        {resume.workExperiences.map((experience, index) => (
          <View key={index} style={[index > 0 ? { marginTop: 3 } : {}]}>
            <View style={styles.spaceBetween}>
              <Text style={styles.companyName}>{experience.companyName}</Text>
              <Text style={styles.countryCityStateText}>{experience.countryCityStateText}</Text>
            </View>
            <View style={styles.spaceBetween}>
              <Text>{experience.position}</Text>
              <Text>{experience.startDate}</Text>
            </View>
            <Text style={styles.companyDesc}>{experience.companyDesc}</Text>
            {/* Assuming bullets is an array of strings */}
            <Text style={styles.bulletPoint}>{experience.bullets}</Text>
          </View>
        ))}

        {/* Education Section */}
        {resume.educations.length > 0 && (
          <>
            <Text style={styles.header}>EDUCATION</Text>
            {resume.educations.map((education, index) => (
              <View key={index}>
                <Text style={{ fontWeight: 700 }}>
                  {education.degree}
                  <Text style={{ fontWeight: 400 }}>
                    {education.degree && education.name && ", "}
                    {education.name}
                  </Text>
                </Text>
              </View>
            ))}
          </>
        )}

        {/* Certificates Section */}
        {resume.certificates.length > 0 && (
          <>
            <Text style={styles.header}>CERTIFICATES</Text>
            {resume.certificates.map((certificate, index) => (
              <View key={index}>
                <Text>
                  {certificate.name}
                  {certificate.name && certificate.issueDate && ", "}
                  {certificate.issueDate}
                </Text>
              </View>
            ))}
          </>
        )}

        {/* Skills Section */}
        {resume.otherSkillsText && resume.otherSkillsText.length > 0 && (
          <>
            <Text style={styles.header}>SKILLS</Text>
            <Text style={styles.text}>{resume.otherSkillsText}</Text>
          </>
        )}

        {/* Technology/Tools Section */}
        {resume.techSkillsText && resume.techSkillsText.length > 0 && (
          <>
            <Text style={styles.header}>TECHNOLOGY/TOOLS</Text>
            <Text style={styles.text}>{resume.techSkillsText}</Text>
          </>
        )}
      </Page>
    </Document>
  );
};

export default ResumeDocument;
