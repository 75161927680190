import React from "react";
import { Card, CardActionArea, CardContent, Typography, Box, SxProps, Theme } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

interface CardButtonProps {
  onClick: () => void;
  icon: SvgIconComponent;
  text: string;
  subtext: string;
}

const CardButton: React.FC<CardButtonProps> = ({ onClick, icon: Icon, text, subtext }) => (
  <Card
    sx={
      {
        maxWidth: 250,
        minWidth: 205,
        borderRadius: 3,
        boxShadow: (theme: Theme) => `0 8px 16px 0 ${theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
        "&:hover": {
          boxShadow: (theme: Theme) => `0 16px 32px 0 ${theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)"}`,
        },
        transition: "box-shadow 0.3s ease-in-out",
        backgroundColor: (theme: Theme) => theme.palette.background.paper,
      } as SxProps<Theme>
    }
  >
    <CardActionArea
      onClick={onClick}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CardContent>
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <Icon color="primary" sx={{ fontSize: 100, mb: 2 }} />
          <Typography variant="h5" color="primary" gutterBottom>
            {text}
          </Typography>
          <Typography variant="body2" color="secondary">
            {subtext}
          </Typography>
        </Box>
      </CardContent>
    </CardActionArea>
  </Card>
);

export default CardButton;
