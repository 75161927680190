import React from "react";
import { SpeedDial } from "@mui/material";
// import ChatIcon from "@mui/icons-material/Chat"; // Default icon
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline"; // Icon to indicate unread messages
import CloseIcon from "@mui/icons-material/Close"; // Icon when SpeedDial is open
import WarningIcon from "@mui/icons-material/Warning"; // Icon to indicate a warning

interface SpeedDialChatProps {
  onOpen: () => void;
  isOpen: boolean;
  hasUnreadMessages: boolean;
  hasWarning: boolean; // New prop to indicate the presence of a warning
}

const SpeedDialChat: React.FC<SpeedDialChatProps> = ({ onOpen, isOpen, hasUnreadMessages, hasWarning }) => {
  // Determine the icon based on the state
  let iconComponent = <WarningIcon />;
  if (isOpen) {
    iconComponent = <CloseIcon />;
  } else if (hasWarning) {
    iconComponent = <WarningIcon />;
  } else if (hasUnreadMessages) {
    iconComponent = <WarningIcon />;

    iconComponent = <ChatBubbleOutlineIcon />;
  }

  return (
    <SpeedDial
      hidden={!hasWarning}
      ariaLabel="SpeedDial toggle chat"
      sx={{
        "&:hover": {
          transform: "scale(1.1)",
          ".MuiSpeedDial-fab": {
            // Apply styles for the fab button on hover
            backgroundColor: hasWarning ? "error.main" : hasUnreadMessages && !isOpen ? "error.main" : "error.main",
            color: "white",
          },
        },
        ".MuiSpeedDial-fab": {
          // Default styles for the fab button
          backgroundColor: hasWarning ? "error.main" : hasUnreadMessages && !isOpen ? "error.main" : "error.main",
          color: "white",
        },
      }}
      icon={iconComponent} // Directly use the determined icon
      onClick={onOpen}
    />
  );
};

export default SpeedDialChat;
