import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WorkExperienceForm from "./WorkExperienceForm";
import { ISkill, IWorkExperience } from "../../../models/models";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controls } from "../../../components/controls/Controls";
import { useDeleteWorkExperienceMutation, useCreateWorkExperienceMutation, cvApi } from "../../../store/api/resume.api";
import { format, parseISO } from "date-fns";
import AlertDialog from "../../../components/AlertDialog";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import LoaderComponent from "../../../components/LoaderComponent";
import { useAppDispatch } from "../../../store/hooks";

interface IWorkExperienceProps {
  resumeId: string;
  tailoredResume?: boolean;
  initialSkills: ISkill[];
  jobTitle: string;
  relevantYears: number;
  expLevel: string;
  resumeTemplateId: number;
  workExperiences: IWorkExperience[];
}

enum ActionType {
  Add = "ADD",
  Delete = "DELETE",
}

const WorkExperience = React.memo((props: IWorkExperienceProps) => {
  const { resumeId, tailoredResume = true, initialSkills = [], workExperiences, jobTitle, relevantYears, expLevel, resumeTemplateId } = props;

  const [expanded, setExpanded] = useState<string | null>(null);
  const [deletingIetemId, setDeletingIetemId] = useState<null | number>(null);
  const [createWorkExperience, { data: newWorkexperienceData, isLoading: isLoadingCreate }] = useCreateWorkExperienceMutation();
  const [deleteWorkExpirience, { isLoading: isLoadingDelete }] = useDeleteWorkExperienceMutation();
  const dispatch = useAppDispatch();

  const updateWorkExperience = useCallback(
    (
      action: ActionType,
      workExperience?: IWorkExperience, // Used for ADD
      workExperienceIdToDelete?: number // Used for DELETE
    ) => {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
          switch (action) {
            case ActionType.Add:
              if (workExperience) {
                // Add a new work experience
                draft.workExperiences.push(workExperience);
              }
              break;
            case ActionType.Delete:
              if (workExperienceIdToDelete) {
                // Delete an existing work experience
                draft.workExperiences = draft.workExperiences.filter((we) => we.id !== workExperienceIdToDelete);
              }
              break;
            // No default case needed as we're only handling add and delete
          }
        })
      );
    },
    [dispatch, resumeId] // Dependencies for useCallback
  );

  useEffect(() => {
    if (newWorkexperienceData) {
      updateWorkExperience(ActionType.Add, newWorkexperienceData);
      setExpanded(newWorkexperienceData.id.toString());
    }
  }, [newWorkexperienceData, updateWorkExperience]);

  const handleDeleteWorkExpirience = async (id: number | null) => {
    if (!id) return;
    await deleteWorkExpirience(id);
    updateWorkExperience(ActionType.Delete, undefined, id);
    setDeletingIetemId(null);
  };

  const handleAddWorkExpirience = async () => {
    await createWorkExperience(resumeId);
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <>
      {!workExperiences ? (
        // <LoaderComponent />
        <LoaderComponent />
      ) : (
        <>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            {workExperiences.map((item: IWorkExperience, index) => (
              <Box display={"flex"} flexDirection={"row"} key={item.id} alignItems={"flex-start"}>
                {/* <Accordion key={item.id} expanded={expand && elementsCount === index}> */}
                <Accordion key={item.id} sx={{ width: "100%" }} expanded={expanded === item.id.toString()} onChange={handleAccordionChange(item.id.toString())}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography>
                        {item.position !== "" ? item.position : "Position"} at {item.companyName !== "" ? item.companyName : "Company"}
                      </Typography>
                      <Typography>
                        {item.startDate === "" || item.startDate === null ? "Start date" : format(parseISO(item.startDate), "MMMM yyyy")} -{" "}
                        {item.endDate === "" || item.endDate === null ? "End date" : format(parseISO(item.endDate), "MMMM yyyy")}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <WorkExperienceForm
                      workExperience={item}
                      resumeId={resumeId}
                      tailoredResume={tailoredResume}
                      initialSkills={initialSkills}
                      jobTitle={jobTitle}
                      relevantYears={relevantYears}
                      expLevel={expLevel}
                      resumeTemplateId={resumeTemplateId}
                    />
                  </AccordionDetails>
                </Accordion>
                {tailoredResume && (
                  <Tooltip title="Delete">
                    <IconButton onClick={() => setDeletingIetemId(item.id)} disabled={isLoadingDelete}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            ))}
          </Box>
          {tailoredResume && (
            <Box sx={{ mt: 1 }}>
              <Controls.Button
                text={isLoadingCreate ? "Creating.." : "Add new work experience"}
                onClick={handleAddWorkExpirience}
                disabled={isLoadingCreate}
                isLoading={isLoadingCreate}
              ></Controls.Button>
            </Box>
          )}
          <AlertDialog
            title="Do you want to delete this work experience?"
            buttonOneCallback={() => handleDeleteWorkExpirience(deletingIetemId)}
            onClose={() => setDeletingIetemId(null)}
            isOpen={!!deletingIetemId}
          />
        </>
      )}
    </>
  );
});

export default WorkExperience;
