import { api } from "./api";

export const billingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getActiveSubscription: build.query<any, void>({
      query: () => `billing/getActiveSubscription`,
      providesTags: ["Subscription"], // Provides the 'Subscription' tag
    }),
    createCheckoutSession: build.mutation({
      query: (body) => ({
        url: "billing/create-checkout-session",
        method: "POST",
        body,
      }),
    }),
    createPortalSession: build.mutation({
      query: (body) => ({
        url: "billing/create-portal-session",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetActiveSubscriptionQuery, useCreateCheckoutSessionMutation, useCreatePortalSessionMutation } = billingApi;
