import { Box, Typography } from "@mui/material";
import { Controls } from "../../components/controls/Controls";

export default function Page404() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h6">The page you’re looking for doesn’t exist.</Typography>
      <Controls.Button text="Back Home" href="/" sx={{ mt: 1 }}></Controls.Button>
    </Box>
  );
}
