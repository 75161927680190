import Layout from "../../components/layout/Layout";
import LoaderComponent from "../../components/LoaderComponent";
import EditableTitle from "../../components/EditableTitle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Preview from "../resume/preview/Preview";
import AppBarButtons from "../../components/AppBarButtons";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useLocation, useParams } from "react-router-dom";
import { useDrawerItems } from "../../components/DrawerItems";
import { useAppDispatch } from "../../store/hooks";
import { useGenerateCvMutation } from "../../store/api/pdf.api";
import { IResume, ISkill, ITailoredResume } from "../../models/models";
import {
  cvtApi,
  useGetTailoredResumeByIdQuery,
  useGetTailoredSummaryMutation,
  useTailorResumeMutation,
  useTailorSkillsMutation,
  useUpdateTailoredResumeMutation,
} from "../../store/api/tailored.api";
import { Controls } from "../../components/controls/Controls";
import WorkExperience from "../resume/workExperience/WorkExperience";
import { cvApi, useLazyGetResumeByIdQuery } from "../../store/api/resume.api";
import InfoIcon from "@mui/icons-material/Info";
import useBeforeUnload from "../../hooks/useBeforeUnload";
import AddSkillToWorkDialog from "../../components/AddSkillToWorkDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Education from "../resume/education/Education";
import Certificate from "../resume/certificates/Certificate";
import { capitalizeFirstLetter } from "../../utils/stringUtils";
import Skills from "../../components/Skills";
import TextArea from "../../components/TextArea";
import ChatApp from "../../components/chat/ChatApp";
import ResumeDocument from "../resume/preview/ResumeDocument";
import StateSelect from "../../components/StateSelect";
// import useRouteChange from "../../hooks/useRouteChange";

export default function TailoredResumePage() {
  let location = useLocation();
  if (location.state === null) {
    window.location.href = "/404";
  }
  const title = location.state.title;
  const token = useSelector((state: RootState) => state.auth.token);
  const { resumeId } = useParams() as { resumeId: string };
  const drawerItems = useDrawerItems();
  const dispatch = useAppDispatch();

  const { data: tailoredResumeData } = useGetTailoredResumeByIdQuery(resumeId);
  const [getCustomizedResumeData, { data: customizedResumeData }] = useLazyGetResumeByIdQuery();
  const [customizedResumeId, setCustomizedResumeId] = useState("");
  const [tailorResume, { isLoading: isLoadingNewTailoredData, isSuccess: isSuccessNewTailoredData, data: newTailoredData }] = useTailorResumeMutation();
  const [tailorSkills, { isLoading: isLoadingTailorSkills, isSuccess: isSuccessTailorSkills, data: tailorSkillsData }] = useTailorSkillsMutation();
  const [getTailoredSummary, { data: tailoredSummaryData, isLoading: tailoredSummaryIsLoading }] = useGetTailoredSummaryMutation();

  const [updateTailoredResume, { isLoading: isLoadingUpdateTailoredResume }] = useUpdateTailoredResumeMutation();

  const [skillSet, setSkillSet] = useState<{ skills: string[]; technologies?: string[] }>({ skills: [] });
  const [tailoredSkills, setTailoredSkills] = useState<{ [skill: string]: number[] }>({ skills: [] });

  const [generateCv, { data: dataGenerateCv, isLoading: isLoadingGenerateCv }] = useGenerateCvMutation();
  // Add new state variable and function to handle dialog open state
  const [isAddSkillDialogOpen, setAddSkillDialogOpen] = useState(false);

  const [jobInfo, setJobInfo] = useState({
    jobTitle: tailoredResumeData?.jobTitle || "",
    companyName: tailoredResumeData?.companyName || "",
    companyInfo: tailoredResumeData?.companyInfo || "",
    jobAbout: tailoredResumeData?.jobAbout || "",
    jobRequirements: tailoredResumeData?.jobRequirements || "",
  });

  const [shouldTailorSkills, setShouldTailorSkills] = useState(true);

  const handleDialogClose = (selectedSkills: { [skill: string]: number[] }) => {
    setAddSkillDialogOpen(false); // Close the dialog
  };

  const [shouldScrollToSummary, setShouldScrollToSummary] = useState(false);

  const handleDialogStepByStepTailor = (selectedSkills: { [skill: string]: number[] }) => {
    setTailoredSkills(selectedSkills);
    setAddSkillDialogOpen(false); // Close the dialog
    setShouldScrollToSummary(true); // Set the flag to trigger scrolling
  };

  const summaryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldScrollToSummary && summaryRef.current) {
      summaryRef.current.scrollIntoView({ behavior: "smooth" });
      setShouldScrollToSummary(false); // Reset the flag
    }
  }, [shouldScrollToSummary]);

  const handleDialogOneClickTailor = (selectedSkills: { [skill: string]: number[] }) => {
    setAddSkillDialogOpen(false); // Close the dialog
    tailorResume({ resume: { ...tailoredResumeData, customizedResume: customizedResumeData }, selectedSkills });
  };

  // const handleUnloadOrRouteChange = () => {
  // if (tailoredResumeData && customizedResumeData) {
  //   updateTailoredResume({ ...tailoredResumeData, customizedResume: customizedResumeData });
  // }
  // };

  // useBeforeUnload(handleUnloadOrRouteChange);

  const isLeavingPage = useRef(false);
  const currentResumeData = useRef(tailoredResumeData);
  const currentCustomizedResumeData = useRef(customizedResumeData);

  useEffect(() => {
    currentResumeData.current = tailoredResumeData;
  }, [tailoredResumeData]);

  useEffect(() => {
    currentCustomizedResumeData.current = customizedResumeData;
  }, [customizedResumeData]);

  const handleUpdateResume = async () => {
    if (tailoredResumeData) {
      await updateTailoredResume({ ...tailoredResumeData, customizedResume: customizedResumeData });
    }
    isLeavingPage.current = false;
  };

  const handleRouteChange = useCallback(() => {
    if (currentResumeData.current && isLeavingPage.current) {
      updateTailoredResume({ ...currentResumeData.current, customizedResume: currentCustomizedResumeData.current });
    }
  }, [updateTailoredResume]); // Add dependencies if any

  const handleUnload = () => {
    if (tailoredResumeData) {
      updateTailoredResume({ ...tailoredResumeData, customizedResume: customizedResumeData });
    }
  };

  useBeforeUnload(handleUnload); // Use the custom hook

  useEffect(() => {
    return () => {
      isLeavingPage.current = true;
      handleRouteChange();
    };
  }, [location.pathname, handleRouteChange]); // Add handleUnloadOrRouteChange to dependencies

  const downloadDOCXHandle = async () => {
    customizedResumeData && (await generateCv({ resumeData: customizedResumeData, docType: "docx" }));
  };

  useEffect(() => {
    if (dataGenerateCv) {
      fetch(dataGenerateCv.link, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", dataGenerateCv.fileName);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          if (link.parentNode) link.parentNode.removeChild(link);
        });
    }
  }, [dataGenerateCv, token]);

  const updateField = useCallback(
    <T extends IResume | ITailoredResume>(apiType: "cvApi" | "cvtApi", queryId: string, fieldId: keyof T, value: T[keyof T]) => {
      const updateDraft = (draft: T) => {
        if (fieldId in draft) {
          draft[fieldId] = value;
        }
      };

      if (apiType === "cvApi") {
        dispatch(cvApi.util.updateQueryData("getResumeById", customizedResumeId, updateDraft as any));
      } else if (apiType === "cvtApi") {
        dispatch(cvtApi.util.updateQueryData("getTailoredResumeById", resumeId, updateDraft as any));
      }
    },
    [dispatch, resumeId, customizedResumeId]
  );

  useEffect(() => {
    const fetchCustomizedResumeData = async () => {
      await getCustomizedResumeData(customizedResumeId);
    };

    if (customizedResumeId) {
      fetchCustomizedResumeData();
    }
  }, [getCustomizedResumeData, customizedResumeId]);

  useEffect(() => {
    if (tailoredResumeData) {
      setCustomizedResumeId(tailoredResumeData.customizedResumeId.toString());
    }
  }, [tailoredResumeData]);

  useEffect(() => {
    if (isSuccessTailorSkills && tailorSkillsData) {
      // updateField("cvApi", "getResumeById", "workExperiences" as keyof IResume, newTailoredData.customizedResume.workExperiences);
      // updateField("cvApi", "getResumeById", "summary" as keyof IResume, newTailoredData.customizedResume.summary);
      setSkillSet({
        skills: [...tailorSkillsData.requiredTechs.skills.required, ...tailorSkillsData.requiredTechs.skills.nice_to_have],
        technologies: [...tailorSkillsData.requiredTechs.technologies.required, ...tailorSkillsData.requiredTechs.technologies.nice_to_have],
      });
      setAddSkillDialogOpen(true);
    }
  }, [tailorSkillsData, isSuccessTailorSkills, updateField]);

  useEffect(() => {
    if (newTailoredData) {
      updateField("cvApi", "getResumeById", "workExperiences" as keyof IResume, newTailoredData.customizedResume.workExperiences);
      updateField("cvApi", "getResumeById", "summary" as keyof IResume, newTailoredData.customizedResume.summary);
      setSkillSet(newTailoredData.requiredSkills);
      setAddSkillDialogOpen(false); // Close the dialog
    }
  }, [newTailoredData, updateField, dispatch, customizedResumeId]);

  const handleTextFieldChange = (apiType: "cvApi" | "cvtApi", queryId: string, fieldId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    updateField(apiType, queryId, fieldId as keyof IResume, value);

    // Update jobInfo state and set flag if there is a change
    setJobInfo((prev: any) => {
      if (prev[fieldId] !== value) {
        setShouldTailorSkills(true);
      }
      return { ...prev, [fieldId]: value };
    });
  };

  const handleOpenDialog = () => {
    if (shouldTailorSkills) {
      // Trigger tailorSkills and open dialog after completion
      tailorSkills({ ...tailoredResumeData, customizedResume: customizedResumeData });
      setShouldTailorSkills(false);
    } else {
      // Open dialog with existing data
      setAddSkillDialogOpen(true);
    }
  };

  const handleTextFieldOnBlur = (apiType: "cvApi" | "cvtApi", queryId: string, fieldId: string) => (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    updateField(apiType, queryId, fieldId as keyof IResume, value);
  };

  const combinedSkills = useMemo(() => {
    return tailorSkillsData ? tailorSkillsData.requiredTechs : { skills: { required: [], nice_to_have: [] }, technologies: { required: [], nice_to_have: [] } };
  }, [tailorSkillsData]);

  useEffect(() => {
    if (tailoredSummaryData) {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", customizedResumeId, (draft) => {
          draft.summary = tailoredSummaryData;
        })
      );
    }
  }, [tailoredSummaryData, dispatch, customizedResumeId]);

  const handleTailoredSummaryClick = async () => {
    getTailoredSummary({ resume: { ...tailoredResumeData, customizedResume: customizedResumeData }, selectedSkills: tailoredSkills });
  };

  // const [jobTitleChanged, setJobTitleChanged] = useState(false);

  function processFieldValue(fieldId: string, fieldValue: string): string {
    if (fieldId === "totalYears" && fieldValue.match(/[^0-9]/)) {
      return fieldValue; // Return the original value if it contains non-numeric characters
    }
    if (fieldId === "city") {
      return capitalizeFirstLetter(fieldValue);
    }
    if (fieldId === "linkedInProfileLink" && !/^https?:\/\//i.test(fieldValue)) {
      return "https://" + fieldValue;
    }
    // Additional processing can be added here as needed
    return fieldValue;
  }

  function handleTextFieldOnChange(e: ChangeEvent<HTMLInputElement>) {
    const fieldId = e.currentTarget.id as keyof IResume;
    let fieldValue = e.currentTarget.value;

    fieldValue = processFieldValue(fieldId, fieldValue);

    updateFieldValue(fieldId, fieldValue);
  }

  const handleChangeUsState = (event: any, newValue: any, reason: any) => {
    if (newValue) {
      updateFieldValue("state", newValue.code);
    } else {
      updateFieldValue("state", undefined);
    }
  };

  function updateFieldValue<K extends keyof IResume>(fieldId: K, value: IResume[K]) {
    dispatch(
      cvApi.util.updateQueryData("getResumeById", customizedResumeId, (draft) => {
        draft[fieldId] = value;
      })
    );
  }

  /**
   * Deletes a specific skill from the resume, updating the resume's skills list in the Redux store.
   *
   * @param {string} skillName - The name of the skill to delete.
   * @returns {void}
   */
  const handleDeleteSkillFromResume = useCallback(
    (skillName: string) => {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", customizedResumeId, (draft) => {
          draft.skills = draft.skills.filter((skill: ISkill) => skill.name !== skillName);
        })
      );
      // Optionally, add the skill back to a pool of available skills if needed
    },
    [dispatch, customizedResumeId]
  );

  /**
   * Adds a new skill to the resume if it doesn't already exist in the resume's skills list.
   *
   * @param {string} skillName - The name of the skill to add.
   * @param {string} skillType - The type of the skill being added.
   * @returns {void}
   */
  const handleAddSkillToResume = useCallback(
    (skillName: string, skillType: string) => {
      if (!customizedResumeData) return;
      const skillExists = customizedResumeData.skills.some((skill) => skill.name.toLowerCase() === skillName.toLowerCase());
      if (!skillExists) {
        dispatch(
          cvApi.util.updateQueryData("getResumeById", customizedResumeId, (draft) => {
            draft.skills.push({
              name: capitalizeFirstLetter(skillName),
              resumeId: Number(customizedResumeId),
              type: skillType,
            });
          })
        );
      }
    },
    [dispatch, customizedResumeData, customizedResumeId]
  );

  return (
    <>
      {!tailoredResumeData || !customizedResumeData ? (
        // <LoaderComponent />
        <Layout drawerItems={drawerItems} leftContent={<LoaderComponent />} title={title} />
      ) : (
        // Render a loader or spinner here

        // Render your content when data is loaded
        <Layout
          title={
            <EditableTitle
              title={tailoredResumeData.title}
              onChange={handleTextFieldChange("cvtApi", "getTailoredResumeById", "title")}
              onBlur={handleTextFieldOnBlur("cvtApi", "getTailoredResumeById", "title")}
            />
          }
          drawerItems={drawerItems}
          leftContent={
            <>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="skills">
                    <Typography sx={{ mb: 1 }}>Job information</Typography>
                  </Divider>
                  <TextField
                    required
                    fullWidth
                    id="jobTitle"
                    label="Job title"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleTextFieldChange("cvtApi", "getTailoredResumeById", "jobTitle")(e);
                      handleTextFieldChange("cvApi", "getResumeById", "jobTitle")(e);
                    }}
                    value={tailoredResumeData.jobTitle}
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    required
                    fullWidth
                    id="companyName"
                    label="Company name"
                    onChange={handleTextFieldChange("cvtApi", "getTailoredResumeById", "companyName")}
                    value={tailoredResumeData.companyName}
                    sx={{ mb: 3 }}
                  />
                  <Typography sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                    <Tooltip
                      title={
                        <>
                          <Typography color="inherit">
                            Please include ONLY 1-3 sentences about the company or division, including the main business area and/or technologies.
                          </Typography>
                          <Typography color="inherit">DO NOT include:</Typography>
                          <Typography color="inherit" component="span">
                            - Salary information
                            <br />
                            - Perks & Benefits
                            <br />
                            - Why to join us information
                            <br />
                            - Company growth
                            <br />
                            - Equal Employment
                            <br />- Etc
                          </Typography>
                        </>
                      }
                    >
                      <InfoIcon sx={{ marginRight: 1, color: "primary.main" }} />
                    </Tooltip>
                    Company info:
                  </Typography>
                  <TextArea
                    minRows={10}
                    id="companyInfo"
                    placeholder={`Please include ONLY 1-3 sentences about the company or division, including the main business area and/or technologies.
DO NOT include:
- Salary information
- Perks & Benefits
- Why to join us information
- Company growth
- Equal Employment
- Etc.`}
                    onChange={handleTextFieldChange("cvtApi", "getTailoredResumeById", "companyInfo")}
                    value={tailoredResumeData.companyInfo}
                    sx={{ mb: 3 }}
                    maxLength={1000}
                  />

                  <Typography sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                    <Tooltip
                      title={
                        <>
                          <Typography color="inherit">Please include information specific to the role ONLY.</Typography>
                          <Typography color="inherit">This information is typically found under the following headings:</Typography>
                          <Typography color="inherit" component="span">
                            - Responsibilities
                            <br />
                            - You Will
                            <br />
                            - Your role
                            <br />
                            - What You’ll Do
                            <br />
                            - Our Ideal Fit will
                            <br />- How You’ll Make An Impact
                          </Typography>
                        </>
                      }
                    >
                      <InfoIcon sx={{ marginRight: 1, color: "primary.main" }} />
                    </Tooltip>
                    About the Role:
                  </Typography>
                  <TextArea
                    minRows={10}
                    id="jobAbout"
                    placeholder={`Please include information specific to the role ONLY. 
This information is typically found under the following headings:
- Responsibilities
- You Will 
- Your role
- What You’ll Do
- Our Ideal Fit will
- How You’ll Make An Impact`}
                    onChange={handleTextFieldChange("cvtApi", "getTailoredResumeById", "jobAbout")}
                    value={tailoredResumeData.jobAbout}
                    sx={{ mb: 3 }}
                    maxLength={1000}
                  />

                  <Typography sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                    <Tooltip
                      title={
                        <>
                          <Typography color="inherit">For best recommendations, please include job requirements ONLY.</Typography>
                          <Typography color="inherit">This information is typically found under the following headings:</Typography>
                          <Typography color="inherit" component="span">
                            - Qualifications
                            <br />
                            - Must have
                            <br />
                            - Requirements
                            <br />
                            - Your Experience
                            <br />
                            - Minimum Qualifications
                            <br />- Nice to have
                            <br />- Bonus areas of expertise
                            <br />- Other Requirements
                          </Typography>
                        </>
                      }
                    >
                      <InfoIcon sx={{ marginRight: 1, color: "primary.main" }} />
                    </Tooltip>
                    Job Requirements:
                  </Typography>
                  <TextArea
                    rows={14}
                    id="jobRequirements"
                    placeholder={`For best recommendations, please include job requirements ONLY. 
This information is typically found under the following headings:
- Qualifications
- Must have
- Requirements
- Your Experience
- You
- Minimum Qualifications
- Nice to have
- Bonus areas of expertise
- Other Requirements`}
                    onChange={handleTextFieldChange("cvtApi", "getTailoredResumeById", "jobRequirements")}
                    value={tailoredResumeData.jobRequirements}
                    maxLength={1500}
                  />
                </CardContent>
                <CardActions>
                  <Box sx={{ mb: 2 }}>
                    <Controls.Button
                      text={isLoadingTailorSkills || isLoadingNewTailoredData ? "Tailoring..." : "Tailor the resume"}
                      onClick={handleOpenDialog}
                      // onClick={() => setAddSkillDialogOpen(true)}
                      disabled={isLoadingTailorSkills || isLoadingNewTailoredData}
                      isLoading={isLoadingTailorSkills || isLoadingNewTailoredData}
                    />
                  </Box>
                </CardActions>
              </Card>
              {/* <SkillsLayout
                resumeId={customizedResumeData.id.toString()}
                resumeData={customizedResumeData}
                initialSkillSet={skillSet}
                regenerateWithAi={false}
                maxSkills={15}
                resumeTemplateId={tailoredResumeData.sourceResume.resumeTemplateId}
              /> */}
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="skills">
                    <Typography sx={{ mb: 1 }}>Skills</Typography>
                  </Divider>
                  <Alert severity="info" sx={{ mb: 3 }}>
                    <Typography>
                      Please select all the skills relevant to your experience. This field serves as keywords for your resume. Recruiters and HR will use these
                      keywords to search for you, and they will be used for resume generation. Please take 5 minutes to carefully choose your skills. Select up
                      to
                      {10} skills.
                    </Typography>
                  </Alert>
                  <Skills
                    parentId={customizedResumeData.id}
                    skillType="Skill"
                    maxSkills={15}
                    initialSkillSet={skillSet.skills}
                    resumeOrWorkExperienceskillSet={customizedResumeData.skills}
                    regenerateWithAi={false}
                    onSkillDelete={handleDeleteSkillFromResume}
                    onSkillAdd={handleAddSkillToResume}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="skills">
                    <Typography sx={{ mb: 1 }}>Technologies</Typography>
                  </Divider>
                  <Skills
                    parentId={customizedResumeData.id}
                    skillType="Tech"
                    maxSkills={15}
                    initialSkillSet={skillSet.technologies}
                    resumeOrWorkExperienceskillSet={customizedResumeData.skills}
                    regenerateWithAi={false}
                    onSkillDelete={handleDeleteSkillFromResume}
                    onSkillAdd={handleAddSkillToResume}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="summaryDivider" sx={{ mb: 3 }}>
                    <Typography ref={summaryRef}>Summary</Typography>
                  </Divider>
                  <TextArea
                    id="summary"
                    label="Summary will be generated with AI. You can edit it after the generation"
                    rows={6}
                    onChange={handleTextFieldChange("cvApi", "getResumeById", "summary")}
                    value={customizedResumeData.summary}
                  />
                </CardContent>
                <CardActions>
                  <Box sx={{ mb: 2, mr: 2 }}>
                    <Controls.Button
                      text={tailoredSummaryIsLoading ? "Tailoring..." : customizedResumeData.summary === "" ? "Tailor with AI" : "Tailor with AI"}
                      onClick={handleTailoredSummaryClick}
                      disabled={tailoredSummaryIsLoading}
                      isLoading={tailoredSummaryIsLoading}
                    />
                  </Box>
                  {/* <Box sx={{ mb: 2 }}>
                    <Controls.Button
                      text={rephraseSummaryIsLoading ? "Generating..." : "Rephrase with AI"}
                      onClick={handleRephraseSummaryClick}
                      disabled={isLoading || rephraseSummaryIsLoading}ß
                      isLoading={rephraseSummaryIsLoading}
                    />
                  </Box> */}
                </CardActions>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="experience" sx={{ mb: 3 }}>
                    <Typography>Experience</Typography>
                  </Divider>
                  <WorkExperience
                    resumeId={customizedResumeId}
                    initialSkills={customizedResumeData.skills}
                    tailoredResume={false}
                    workExperiences={customizedResumeData.workExperiences}
                    jobTitle={customizedResumeData.jobTitle}
                    relevantYears={customizedResumeData.relevantYears}
                    expLevel={customizedResumeData.expLevel}
                    resumeTemplateId={customizedResumeData.resumeTemplateId}
                  />
                </CardContent>
              </Card>
              <Accordion square={true} key={"additional-info"} sx={{ width: "100%", borderRadius: 1, "&:before": { display: "none" } }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Divider textAlign="left" id="basicInfo" sx={{ fontSize: 14, width: "100%" }}>
                    <Typography>Additional information</Typography>
                  </Divider>
                </AccordionSummary>
                <AccordionDetails>
                  <Card sx={{ mb: 3 }}>
                    <CardContent>
                      <Divider textAlign="left" id="basicInfo" sx={{ mb: 3, fontSize: 14 }}>
                        <Typography>Basic information</Typography>
                      </Divider>
                      <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
                        <TextField
                          required
                          sx={{ flexGrow: 2 }}
                          id="city"
                          label="City"
                          onChange={handleTextFieldOnChange}
                          value={customizedResumeData.city}
                          fullWidth
                        />
                        <StateSelect
                          handleChange={handleChangeUsState}
                          currentValue={customizedResumeData.state}
                          id="basicInfoState"
                          label="State"
                          width="50%"
                        />
                      </Box>
                      <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email"
                          type="email"
                          onChange={handleTextFieldOnChange}
                          value={customizedResumeData.email}
                          autoComplete="email"
                        />
                        <TextField
                          required
                          fullWidth
                          id="phone"
                          label="Phone"
                          type="tel"
                          onChange={handleTextFieldOnChange}
                          value={customizedResumeData.phone}
                          autoComplete="phone"
                        />
                      </Box>
                    </CardContent>
                  </Card>
                  {customizedResumeData.educations.length > 0 && (
                    <Card sx={{ mb: 3 }}>
                      <CardContent>
                        <Divider textAlign="left" id="education" sx={{ mb: 3 }}>
                          <Typography>Education</Typography>
                        </Divider>
                        <Education resumeData={customizedResumeData} showActionButtons={false} />
                      </CardContent>
                    </Card>
                  )}
                  <Card>
                    <CardContent>
                      <Divider textAlign="left" id="certificates" sx={{ mb: 3 }}>
                        <Typography>Certificates</Typography>
                      </Divider>
                      <Certificate resumeData={customizedResumeData} showActionButtons={true} />
                    </CardContent>
                  </Card>
                </AccordionDetails>
              </Accordion>

              {/* And update the AddSkillToWorkDialog component's props: */}
              <AddSkillToWorkDialog
                isOpen={isAddSkillDialogOpen}
                skills={combinedSkills} // Assuming 'skills' is the array of skills you want to display
                experiences={customizedResumeData.workExperiences} // Replace with the actual field that contains experience titles or descriptions
                onClose={handleDialogClose}
                resumeId={Number(customizedResumeId)}
                onOneClickTailor={handleDialogOneClickTailor}
                onStepByStepTailor={handleDialogStepByStepTailor}
              />
            </>
          }
          rightContent={
            <>
              <Preview resumeData={customizedResumeData} />
              <ChatApp />
            </>
          }
          appBarButtons={
            <AppBarButtons
              isLoadingGenerateCv={isLoadingGenerateCv}
              document={<ResumeDocument resumeData={customizedResumeData} />}
              downloadDOCXHandle={downloadDOCXHandle}
              isLoadingUpdateResume={isLoadingUpdateTailoredResume}
              updateResume={handleUpdateResume}
              resumeData={customizedResumeData}
              dispatch={dispatch}
            />
          }
        />
      )}
    </>
  );
}
