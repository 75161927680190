import { Box, Checkbox, Divider, FormControlLabel, Typography, debounce } from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Controls } from "../../../components/controls/Controls";
import { formatISO, parseISO } from "date-fns";
import { IResume, ISkill, IWorkExperience, IWorkExperienceSkill } from "../../../models/models";
import { cvApi, useLazyGetSkillsQuery } from "../../../store/api/resume.api";
import { useAppDispatch } from "../../../store/hooks";
import Skills from "../../../components/Skills";
import Bullets from "../../../components/Bullets";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import { handleDateChange } from "../../../utils/dateUtils";
import TextArea from "../../../components/TextArea";
import CountrySelect from "../../../components/CountrySelect";
import StateSelect from "../../../components/StateSelect";

const roleItems = [
  { id: "Full-Time", name: "Full-Time" },
  { id: "Part-Time", name: "Part-Time" },
  { id: "Self-Employed", name: "Self-Employed" },
  { id: "Freelance", name: "Freelance" },
  { id: "Contractor", name: "Contractor" },
  { id: "Internship", name: "Internship" },
  { id: "Volunteer", name: "Volunteer" },
];

const onsiteItems = [
  { id: "On-site", name: "On-site" },
  { id: "Hybrid", name: "Hybrid" },
  { id: "Remote", name: "Remote" },
];

interface IWorkExperienceFormProps {
  resumeId: string;
  workExperience: IWorkExperience;
  jobTitle: string;
  relevantYears: number;
  expLevel: string;
  resumeTemplateId: number;
  initialSkills: ISkill[];
  tailoredResume?: boolean;
}

const WorkExperienceForm = React.memo((props: IWorkExperienceFormProps) => {
  const { workExperience, resumeId, tailoredResume = true, initialSkills = [], jobTitle, relevantYears, expLevel, resumeTemplateId } = props;
  const [localWorkExperience, setLocalWorkExperience] = useState({ ...workExperience });
  const [localInitialSkills, setLocalInitialSkills] = useState<string[]>([]);
  const [initialGeneratedSkills, setInitialGeneratedSkills] = useState<string[]>([]);

  const [getSkills, { data: generatedSkills, isLoading: isFetching }] = useLazyGetSkillsQuery();

  useEffect(() => {
    setLocalWorkExperience({ ...workExperience });
  }, [workExperience]); // Depend on workExperience prop to trigger the effect

  useEffect(() => {
    if (generatedSkills) setInitialGeneratedSkills(generatedSkills);
  }, [generatedSkills]);

  const dispatch = useAppDispatch();

  const [checked, setChecked] = React.useState(localWorkExperience.currentPosition ? localWorkExperience.currentPosition : false);

  const handleChangeCurrentPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateLocalWorkExperience("endDate", formatISO(new Date()));
    setChecked(event.target.checked);
    updateLocalWorkExperience(event.target.name, event.target.checked);
  };

  const deleteSkill = useCallback((skillToDelete: string) => {
    setLocalInitialSkills((prevSkills) => prevSkills.filter((skill) => skill !== skillToDelete));
  }, []);

  const addSkill = useCallback((newSkill: string) => {
    setLocalInitialSkills((prevSkills) => {
      // Check if the skill already exists to prevent duplicates
      if (prevSkills.includes(newSkill)) {
        return prevSkills;
      }
      return [...prevSkills, newSkill];
    });
  }, []);

  const generateSkillSet = () => {
    getSkills({
      id: localWorkExperience.id,
      jobTitle: localWorkExperience.position || "",
      skills: localWorkExperience.skills,
    });
  };

  const getFirstPartOfId = (id: string) => id.split("-")[0];

  const updateFieldValue = useCallback(
    <K extends keyof IResume>(fieldId: K, value: IResume[K]) => {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId, (draft: IResume) => {
          const objWithIdIndex = draft.workExperiences.findIndex((obj: IWorkExperience) => obj.id === localWorkExperience.id);
          if (objWithIdIndex !== -1) {
            draft.workExperiences[objWithIdIndex] = { ...draft.workExperiences[objWithIdIndex], [fieldId]: value };
          }
        })
      );
    },
    [dispatch, localWorkExperience.id, resumeId]
  );

  const handleDeleteSkillFromWorkExperience = useCallback(
    (skillName: string) => {
      // Update local state first
      const updatedSkills = localWorkExperience.skills.filter((skill) => skill.name !== skillName);
      setLocalWorkExperience((prevState) => ({
        ...prevState,
        skills: updatedSkills,
      }));
      addSkill(skillName);
      // Then dispatch to update the global state
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
          const index = draft.workExperiences.findIndex((exp) => exp.id === localWorkExperience.id);
          if (index !== -1) {
            draft.workExperiences[index].skills = updatedSkills;
          }
        })
      );
    },
    [localWorkExperience.skills, localWorkExperience.id, addSkill, dispatch, resumeId]
  );

  const handleAddSkillToWorkExperience = useCallback(
    (skillName: string, skillType: string) => {
      const skillExists = localWorkExperience.skills.some((skill) => skill.name.toLowerCase() === skillName.toLowerCase());
      if (!skillExists) {
        const newSkill = {
          name: capitalizeFirstLetter(skillName),
          workExperienceId: localWorkExperience.id, // Make sure this ID is correctly assigned
          type: skillType,
        };
        // Update local state
        setLocalWorkExperience((prevState) => ({
          ...prevState,
          skills: [...prevState.skills, newSkill],
        }));
        deleteSkill(skillName);
        // Dispatch update to Redux store
        dispatch(
          cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
            const expIndex = draft.workExperiences.findIndex((exp) => exp.id === localWorkExperience.id);
            if (expIndex !== -1) {
              draft.workExperiences[expIndex].skills.push(newSkill);
            }
          })
        );
      }
    },
    [localWorkExperience.skills, localWorkExperience.id, deleteSkill, dispatch, resumeId]
  );

  const debouncedUpdateFieldValue = React.useMemo(() => {
    // Create a debounced function that can accept multiple arguments
    return debounce((fieldId, value) => {
      updateFieldValue(fieldId, value);
    }, 500);
  }, [updateFieldValue]);

  const updateLocalWorkExperience = (key: any, value: any) => {
    setLocalWorkExperience((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    debouncedUpdateFieldValue(key, value);
  };

  function onBulletChange(value: string) {
    if (value !== null) {
      updateLocalWorkExperience("bullets", value);
    }
  }

  function handleTextFieldOnChange(e: ChangeEvent<HTMLInputElement>) {
    const fieldId = getFirstPartOfId(e.currentTarget.id);
    var fieldValue = e.currentTarget.value;
    if (fieldId === "companyName") fieldValue = capitalizeFirstLetter(fieldValue);
    if (fieldId === "city") fieldValue = capitalizeFirstLetter(fieldValue);
    if (fieldId === "position") fieldValue = capitalizeFirstLetter(fieldValue);

    updateLocalWorkExperience(fieldId, fieldValue);
  }

  const handleEmploymentTypeChange = (e: any) => {
    updateLocalWorkExperience(getFirstPartOfId(e.target.name), e.target.value);
  };
  const handleLocationTypeChange = (e: any) => {
    updateLocalWorkExperience(getFirstPartOfId(e.target.name), e.target.value);
  };

  const handleCountryChange = (event: any, newValue: any, reason: any) => {
    if (newValue) {
      updateLocalWorkExperience("country", newValue.label);
    } else {
      updateLocalWorkExperience("country", "");
    }
  };

  const handleStateChange = (event: any, newValue: any, reason: any) => {
    if (newValue) {
      updateLocalWorkExperience("state", newValue.code);
    } else {
      updateLocalWorkExperience("state", "");
    }
  };

  // Create a Set of workExperience skill names for efficient lookup

  const initialSkillSet = useMemo(() => {
    const workExperienceSkillNames = new Set(localWorkExperience.skills.map((skill: IWorkExperienceSkill) => skill.name));
    const initialSetFromProps = initialSkills.map((skill) => skill.name).filter((skillName) => !workExperienceSkillNames.has(skillName));
    // Combine and remove duplicates
    return Array.from(new Set([...initialSetFromProps, ...localInitialSkills]));
  }, [initialSkills, localInitialSkills, localWorkExperience.skills]);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Controls.Input
          id={`position-workExp-${localWorkExperience.id}`}
          name="position"
          label="Position"
          value={localWorkExperience.position}
          onChange={handleTextFieldOnChange}
          sx={{ mb: 3 }}
          fullWidth
        ></Controls.Input>
        {tailoredResume && (
          <Controls.Input
            id={`companyName-workExp-${localWorkExperience.id}`}
            name="companyName"
            label="Company name"
            value={localWorkExperience.companyName}
            onChange={handleTextFieldOnChange}
            sx={{ mb: 3 }}
            fullWidth
          />
        )}
        <Controls.Input
          id={`companyDesc-workExp-${localWorkExperience.id}`}
          name="companyDesc"
          label="Company description"
          value={localWorkExperience.companyDesc}
          onChange={handleTextFieldOnChange}
          sx={{ mb: 3 }}
          fullWidth
        />
        {tailoredResume && (
          <>
            <Controls.Input
              id={`city-workExp-${localWorkExperience.id}`}
              name="city"
              label="City"
              value={localWorkExperience.city}
              onChange={handleTextFieldOnChange}
              sx={{ mb: 3 }}
              fullWidth
            />
            <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
              <CountrySelect
                handleChange={handleCountryChange}
                currentValue={localWorkExperience.country}
                id={`country-workExp-${localWorkExperience.id}`}
                label="Country"
              />
              <StateSelect
                handleChange={handleStateChange}
                currentValue={localWorkExperience.state}
                id={`state-workExp-${localWorkExperience.id}`}
                label="State"
                disabled={localWorkExperience.country! !== "United States"}
              />
            </Box>
            <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
              <Controls.Select
                id={`employmentType-workExp-${localWorkExperience.id}`}
                name="employmentType"
                value={localWorkExperience.employmentType}
                defaultValue=""
                label="Employment type"
                onChange={handleEmploymentTypeChange}
                items={roleItems}
              />
              <Controls.Select
                id={`locationType-workExp-${localWorkExperience.id}`}
                name="locationType"
                defaultValue=""
                value={localWorkExperience.locationType}
                label="On-site/remote"
                onChange={handleLocationTypeChange}
                items={onsiteItems}
              />
            </Box>
            <Box display={"flex"} flexDirection={"row"} gap={1}>
              <Controls.DatePicker
                id={`startDate-workExp-${localWorkExperience.id}`}
                name="startDate"
                label="Start Date"
                value={localWorkExperience.startDate ? parseISO(localWorkExperience.startDate) : null}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleDateChange(e, updateLocalWorkExperience, getFirstPartOfId)}
                maxDate={localWorkExperience.endDate ? parseISO(localWorkExperience.endDate) : new Date()}
              />
              <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                <Controls.DatePicker
                  id={`endDate-workExp-${localWorkExperience.id}`}
                  name="endDate"
                  label="End Date"
                  value={localWorkExperience.endDate && !checked ? parseISO(localWorkExperience.endDate) : null}
                  disabled={checked}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleDateChange(e, updateLocalWorkExperience, getFirstPartOfId)}
                  minDate={localWorkExperience.startDate ? parseISO(localWorkExperience.startDate) : undefined}
                />
                <FormControlLabel
                  id={`currentPosition-workExp-${localWorkExperience.id}`}
                  name="currentPosition"
                  control={<Checkbox checked={checked} onChange={handleChangeCurrentPosition} inputProps={{ "aria-label": "controlled" }} />}
                  label="My current position"
                />
              </Box>
            </Box>
          </>
        )}
        {/* <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
          <FormControlLabel control={<Checkbox id="nonRelevantExp" />} label="This experience is not relevant to my target postion" />
        </Box> */}
        <>
          <Divider textAlign="left" id="skills" sx={{ mb: 1, mt: 1 }}>
            <Typography>Skills & Techologies/Tools</Typography>
          </Divider>
          <Skills
            regenerateWithAi={false}
            maxSkills={20}
            initialSkillSet={initialGeneratedSkills.length > 0 ? initialGeneratedSkills : initialSkillSet}
            onSkillDelete={handleDeleteSkillFromWorkExperience}
            onSkillAdd={handleAddSkillToWorkExperience}
            parentId={localWorkExperience.id}
            resumeOrWorkExperienceskillSet={localWorkExperience.skills}
            generateSkillSet={generateSkillSet}
            isFetching={isFetching}
          />
          <Divider textAlign="left" id="skills" sx={{ mb: 2, mt: 2 }}>
            <Typography>Describe achievements for this job</Typography>
          </Divider>
          <TextArea
            id={`achievments-workExp-${localWorkExperience.id}`}
            name="achievments"
            label={`Please write the list of your achievements for resume generation`}
            helperText={`e.g.: "increased revenue by 20%", "closed 5 new accounts"`}
            value={localWorkExperience.achievments}
            onChange={handleTextFieldOnChange}
          />
        </>
        <Divider textAlign="left" id="skills" sx={{ mb: 2, mt: 2 }}>
          <Typography>AI Generated Experience</Typography>
        </Divider>
        <Bullets
          onBulletUpdate={onBulletChange}
          tailoredResume={tailoredResume}
          workExperienceId={localWorkExperience.id}
          resumeId={resumeId}
          jobTitle={jobTitle}
          relevantYears={relevantYears}
          expLevel={expLevel}
          position={localWorkExperience.position}
          companyName={localWorkExperience.companyName}
          achievments={localWorkExperience.achievments}
          skills={localWorkExperience.skills}
          bullets={localWorkExperience.bullets}
          resumeTemplateId={resumeTemplateId}
        ></Bullets>
      </Box>
    </>
  );
});

export default WorkExperienceForm;
