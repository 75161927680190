// Import React and necessary MUI components
import React from "react";
import { Typography, Button } from "@mui/material";
import { format, fromUnixTime } from "date-fns";

interface SubscriptionInfoProps {
  productName: string;
  startedAt: number; // Added startedAt field
  endedAt: number;
  customer: string;
  handleCreatePortalSession: (customerId: string) => void;
  isLoading: boolean;
}

export const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({
  productName,
  startedAt, // Use startedAt field
  endedAt,
  customer,
  handleCreatePortalSession,
  isLoading,
}) => {
  return (
    <>
      <Typography variant="h5">
        <span style={{ fontWeight: "bold" }}>Plan name:</span> {productName}
      </Typography>
      <Typography variant="h5">
        <span style={{ fontWeight: "bold" }}>Started at:</span> {format(fromUnixTime(startedAt), "MMMM dd, yyyy")}
      </Typography>
      <Typography variant="h5" sx={{ mb: 2 }}>
        <span style={{ fontWeight: "bold" }}>Ends at:</span> {format(fromUnixTime(endedAt), "MMMM dd, yyyy")}
      </Typography>
      <Button variant="contained" onClick={() => handleCreatePortalSession(customer)} disabled={isLoading}>
        Manage subscription
      </Button>
    </>
  );
};
