import { createTheme, PaletteMode, ThemeOptions } from "@mui/material";
import { createContext, useState, useMemo } from "react";

// Tokens for light mode
const lightModeTokens = {
  primary: {
    main: "#546e7a", // A muted navy blue
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#b0bec5", // A soft light blue/gray
    contrastText: "#000000",
  },
  error: {
    main: "#e57373", // A softer red, similar to dark mode for consistency
  },
  warning: {
    main: "#ffb74d", // A softer amber, also consistent with dark mode
  },
  info: {
    main: "#64b5f6", // A soft light blue
  },
  success: {
    main: "#81c784", // A gentle green
  },
  background: {
    default: "#eceff1", // Light gray background, easy on the eyes
    paper: "#ffffff",
  },
  text: {
    primary: "#000000", // Dark text for readability on light background
    secondary: "#424242",
  },
};

// Tokens for dark mode, adjusted for better aesthetics
const darkModeTokens = {
  primary: {
    main: "#5f6a7d", // A soft dark blue/gray
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#819ca9", // A lighter blue/gray
    contrastText: "#000000",
  },
  error: {
    main: "#e57373", // A softer red
  },
  warning: {
    main: "#ffb74d", // A softer amber
  },
  info: {
    main: "#64b5f6", // A softer blue
  },
  success: {
    main: "#81c784", // A softer green
  },
  background: {
    default: "#212121", // Darker shade for dark mode background
    paper: "#424242",
  },
  text: {
    primary: "#ffffff", // Light text for readability
    secondary: "#bdbdbd",
  },
};

const customColors = {
  brightGold: {
    background: "#FFD700", // Bright Gold
    text: "#000000", // Text color for Bright Gold
    hover: "#CCAC00", // Hover color for Bright Gold
  },
  lightGold: {
    background: "#FFDF00", // Lighter Gold
    text: "#000000", // Text color for Lighter Gold
    hover: "#CCB200", // Hover color for Lighter Gold
  },
  darkGold: {
    background: "#D4AF37", // Darker Gold
    text: "#FFFFFF", // Text color for Darker Gold
    hover: "#B89A2E", // Hover color for Darker Gold
  },
};

export const tokens = (mode: PaletteMode) => (mode === "dark" ? darkModeTokens : lightModeTokens);

export const themeSettings = (mode: PaletteMode): ThemeOptions => {
  const colors = tokens(mode);

  return {
    palette: {
      mode,
      primary: colors.primary,
      secondary: colors.secondary,
      error: colors.error,
      warning: colors.warning,
      info: colors.info,
      success: colors.success,
      background: colors.background,
      text: colors.text,
    },
    typography: {
      fontFamily: ["Nunito", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Nunito", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Nunito", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Nunito", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Nunito", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Nunito", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Nunito", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
    // Define typography and other customizations
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState<PaletteMode>("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => setMode((prevMode) => (prevMode === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode] as const;
};

export { customColors };
