import Box from "@mui/material/Box";
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Controls } from "../../../components/controls/Controls";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  cvApi,
  useGetRephraseSummaryMutation,
  useGetResumeByIdQuery,
  useGetSummaryMutation,
  useLazyGetSkillsQuery,
  useUpdateResumeMutation,
} from "../../../store/api/resume.api";
import { useAppDispatch } from "../../../store/hooks";
import { IResume, ISkill } from "../../../models/models";
import { useLocation, useParams } from "react-router-dom";
import WorkExperience from "../workExperience/WorkExperience";
import Education from "../education/Education";
import { useGenerateCvMutation } from "../../../store/api/pdf.api";
import Layout from "../../../components/layout/Layout";
import LoaderComponent from "../../../components/LoaderComponent";
import { useDrawerItems } from "../../../components/DrawerItems";
import Certificate from "../certificates/Certificate";
import AppBarButtons from "../../../components/AppBarButtons";
import EditableTitle from "../../../components/EditableTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import useBeforeUnload from "../../../hooks/useBeforeUnload";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import Skills from "../../../components/Skills";
import TextArea from "../../../components/TextArea";
import ChatApp from "../../../components/chat/ChatApp";
import ResumeDocument from "../preview/ResumeDocument";
import Preview from "../preview/Preview";
import StateSelect from "../../../components/StateSelect";

export default function TechResumePage(props: { title: string }) {
  const token = useSelector((state: RootState) => state.auth.token);
  const { title } = props;
  let { resumeId } = useParams() as { resumeId: string };

  const drawerItems = useDrawerItems();

  const { data: resumeData } = useGetResumeByIdQuery(resumeId);
  const [updateResume, { isLoading: isLoadingUpdateResume }] = useUpdateResumeMutation();
  const dispatch = useAppDispatch();

  /**
   * Updates a specific field of the resume data within the Redux store.
   *
   * @param {K} fieldId - The field identifier within the resume object to update.
   * @param {IResume[K]} value - The new value for the specified field.
   * @returns {void}
   */
  function updateFieldValue<K extends keyof IResume>(fieldId: K, value: IResume[K]): void {
    dispatch(
      cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
        draft[fieldId] = value;
      })
    );
  }

  const location = useLocation();
  const isLeavingPage = useRef(false);
  const currentResumeData = useRef(resumeData);

  useEffect(() => {
    currentResumeData.current = resumeData;
  }, [resumeData]);

  /**
   * Asynchronously updates the resume data by calling the `updateResume` mutation.
   * Ensures that current resume data changes are saved, especially when navigating away from the page.
   *
   * @returns {Promise<void>}
   */
  const handleUpdateResume = async (): Promise<void> => {
    if (currentResumeData.current) {
      await updateResume(currentResumeData.current);
    }
    isLeavingPage.current = false;
  };

  /**
   * Invoked before route changes to ensure any changes to the resume data are saved.
   * It's designed to prevent data loss by updating the resume data upon navigation.
   *
   * @returns {void}
   */
  const handleRouteChange = useCallback(() => {
    if (currentResumeData.current && isLeavingPage.current) {
      updateResume(currentResumeData.current);
    }
  }, [updateResume]); // Add dependencies if any

  /**
   * Ensures data is saved when the user attempts to leave the page (e.g., closing the browser tab).
   * It's a safeguard against data loss during unexpected page exits.
   *
   * @returns {void}
   */
  const handleUnload = (): void => {
    if (resumeData) {
      updateResume(resumeData);
    }
  };

  useBeforeUnload(handleUnload); // Use the custom hook

  useEffect(() => {
    return () => {
      isLeavingPage.current = true;
      handleRouteChange();
    };
  }, [location.pathname, handleRouteChange]); // Add handleUnloadOrRouteChange to dependencies

  // const [jobTitleChanged, setJobTitleChanged] = useState(false);

  /**
   * Processes and potentially modifies the value of a field before it's saved, based on specific rules.
   * For example, it might add "https://" to a LinkedIn profile link if missing.
   *
   * @param {string} fieldId - The ID of the field being processed.
   * @param {string} fieldValue - The value of the field to process.
   * @returns {string} The processed field value.
   */
  function processFieldValue(fieldId: string, fieldValue: string): string {
    if (fieldId === "totalYears" && fieldValue.match(/[^0-9]/)) {
      return fieldValue; // Return the original value if it contains non-numeric characters
    }
    if (fieldId === "city") {
      return capitalizeFirstLetter(fieldValue);
    }
    if (fieldId === "linkedInProfileLink" && !/^https?:\/\//i.test(fieldValue)) {
      return "https://" + fieldValue;
    }
    // Additional processing can be added here as needed
    return fieldValue;
  }

  /**
   * Handles changes to text fields, applying any necessary processing to the field values before updating the resume data.
   * This function is triggered on text field change events.
   *
   * @param {ChangeEvent<HTMLInputElement>} e - The event object from the text field.
   * @returns {void}
   */
  function handleTextFieldOnChange(e: ChangeEvent<HTMLInputElement>): void {
    const fieldId = e.currentTarget.id as keyof IResume;
    let fieldValue = e.currentTarget.value;

    fieldValue = processFieldValue(fieldId, fieldValue);

    updateFieldValue(fieldId, fieldValue);
  }

  /**
   * Handles text field changes on blur events, applying necessary processing to the field values before updating the resume data.
   * This function ensures data is updated when the field loses focus.
   *
   * @param {React.FocusEvent<HTMLInputElement>} e - The event object from the text field.
   * @returns {void}
   */
  function handleTextFieldOnBlur(e: React.FocusEvent<HTMLInputElement>): void {
    const fieldId = e.target.id as keyof IResume;
    let fieldValue = e.target.value;

    fieldValue = processFieldValue(fieldId, fieldValue);

    updateFieldValue(fieldId, fieldValue);
  }

  /**
   * Specifically handles changes to the "state" field in the resume data.
   * This can be adapted to handle changes from a dropdown or select element.
   *
   * @param {any} event - The change event object.
   * @param {any} newValue - The new value for the state field.
   * @param {any} reason - The reason for the change (not always used).
   * @returns {void}
   */
  const handleChangeUsState = (event: any, newValue: any, reason: any) => {
    if (newValue) {
      updateFieldValue("state", newValue.code);
    } else {
      updateFieldValue("state", undefined);
    }
  };

  /**
   * Updates the "expLevel" field in the resume data based on user selection from a dropdown menu.
   *
   * @param {SelectChangeEvent} event - The change event object from the select component.
   * @returns {void}
   */
  const handleChangeTargetLevel = (event: SelectChangeEvent) => {
    updateFieldValue("expLevel", event.target.value);
  };

  const [getSummary, { data: summaryData, isLoading }] = useGetSummaryMutation();

  useEffect(() => {
    if (summaryData) {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
          draft.summary = summaryData;
        })
      );
    }
  }, [summaryData, dispatch, resumeId]);

  /**
   * Triggers the AI-generation of a resume summary using the provided resume data.
   *
   * @returns {Promise<void>}
   */
  const handleGenerateSummaryClick = async (): Promise<void> => {
    if (resumeData) await getSummary(resumeData);
  };

  const [rephraseSummary, { data: rephraseSummaryData, isLoading: rephraseSummaryIsLoading }] = useGetRephraseSummaryMutation();

  useEffect(() => {
    if (rephraseSummaryData) {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
          draft.summary = rephraseSummaryData;
        })
      );
    }
  }, [dispatch, rephraseSummaryData, resumeId]);

  /**
   * Asynchronously triggers the AI-rephrasing of the resume summary.
   *
   * @returns {Promise<void>}
   */
  const handleRephraseSummaryClick = async () => {
    if (resumeData) await rephraseSummary(resumeData);
  };

  const [generateCv, { data: dataGenerateCv, isLoading: isLoadingGenerateCv }] = useGenerateCvMutation();

  /**
   * Initiates the download of the generated resume as a PDF document.
   *
   * @returns {Promise<void>}
   */
  const downloadDOCXHandle = async () => {
    resumeData && (await generateCv({ resumeData: resumeData, docType: "docx" }));
  };

  useEffect(() => {
    if (dataGenerateCv) {
      fetch(dataGenerateCv.link, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", dataGenerateCv.fileName);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          if (link.parentNode) link.parentNode.removeChild(link);
        });
    }
  }, [dataGenerateCv, token]);

  /**
   * Deletes a specific skill from the resume, updating the resume's skills list in the Redux store.
   *
   * @param {string} skillName - The name of the skill to delete.
   * @returns {void}
   */
  const handleDeleteSkillFromResume = useCallback(
    (skillName: string) => {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
          draft.skills = draft.skills.filter((skill: ISkill) => skill.name !== skillName);
        })
      );
      // Optionally, add the skill back to a pool of available skills if needed
    },
    [dispatch, resumeId]
  );

  /**
   * Adds a new skill to the resume if it doesn't already exist in the resume's skills list.
   *
   * @param {string} skillName - The name of the skill to add.
   * @param {string} skillType - The type of the skill being added.
   * @returns {void}
   */
  const handleAddSkillToResume = useCallback(
    (skillName: string, skillType: string) => {
      if (!resumeData) return;
      const skillExists = resumeData.skills.some((skill) => skill.name.toLowerCase() === skillName.toLowerCase());
      if (!skillExists) {
        dispatch(
          cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
            draft.skills.push({
              name: capitalizeFirstLetter(skillName),
              resumeId: Number(resumeId),
              type: skillType,
            });
          })
        );
      }
    },
    [dispatch, resumeData, resumeId]
  );

  const [getSkills] = useLazyGetSkillsQuery();

  const [generatedSkills, setGeneratedSkills] = useState<{ Skill: string[]; Tech: string[] }>({
    Skill: [],
    Tech: [],
  });
  const [isFetching, setIsFetching] = useState<{ Skill: boolean; Tech: boolean }>({
    Skill: false,
    Tech: false,
  });

  /**
   * Initiates the fetching of skills or technologies from the server based on the specified skill type.
   * This function is asynchronous and updates the local state with the fetched data and loading status.
   *
   * @param {"Skill" | "Tech"} skillType - The type of skills to fetch, distinguishing between general skills and technologies.
   * @returns {Promise<void>}
   */
  const generateSkillSet = async (skillType: "Skill" | "Tech") => {
    setIsFetching((prev) => ({ ...prev, [skillType]: true }));
    try {
      if (!resumeData) return;
      // Assuming getSkills is an async operation; adapt based on your actual API call
      const skillsData = await getSkills({
        id: resumeData.id,
        resumeTemplateId: resumeData.resumeTemplateId,
        jobTitle: resumeData.jobTitle,
        totalYears: resumeData.totalYears,
        relevantYears: resumeData.relevantYears,
        skills: resumeData.skills,
        type: skillType,
      }).unwrap(); // RTK Query's .unwrap() method will return the payload in case of success

      setGeneratedSkills((prev) => ({ ...prev, [skillType]: skillsData }));
    } catch (error) {
    } finally {
      setIsFetching((prev) => ({ ...prev, [skillType]: false }));
    }
  };

  return (
    <>
      {!resumeData ? (
        // <LoaderComponent />
        <Layout drawerItems={drawerItems} leftContent={<LoaderComponent />} title={title} />
      ) : (
        // Render a loader or spinner here

        // Render your content when data is loaded
        <Layout
          title={<EditableTitle title={resumeData.title} onChange={handleTextFieldOnChange} onBlur={handleTextFieldOnBlur} />}
          drawerItems={drawerItems}
          leftContent={
            <>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="basicInfo" sx={{ mb: 3, fontSize: 14 }}>
                    <Typography>Basic information</Typography>
                  </Divider>
                  <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
                    <TextField required fullWidth id="firstName" label="First name" onChange={handleTextFieldOnChange} value={resumeData.firstName} />
                    <TextField required fullWidth id="lastName" label="Last name" onChange={handleTextFieldOnChange} value={resumeData.lastName} />
                  </Box>
                  <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
                    <TextField required sx={{ flexGrow: 2 }} id="city" label="City" onChange={handleTextFieldOnChange} value={resumeData.city} fullWidth />
                    <StateSelect handleChange={handleChangeUsState} currentValue={resumeData.state} id="basicInfoState" label="State" width="50%" />
                  </Box>
                  <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      type="email"
                      onChange={handleTextFieldOnChange}
                      value={resumeData.email}
                      autoComplete="email"
                    />
                    <TextField
                      required
                      fullWidth
                      id="phone"
                      label="Phone"
                      type="tel"
                      onChange={handleTextFieldOnChange}
                      value={resumeData.phone}
                      autoComplete="phone"
                    />
                  </Box>
                  <TextField
                    id="linkedInProfileLink"
                    label="LinkedIn profile link"
                    sx={{ mb: 3 }}
                    fullWidth
                    onChange={handleTextFieldOnChange}
                    value={resumeData.linkedInProfileLink}
                  />
                  <TextField
                    required
                    id="jobTitle"
                    label="Your targeted role"
                    sx={{ mb: 3 }}
                    fullWidth
                    onChange={handleTextFieldOnChange}
                    // onBlur={generateSkillsSet}
                    value={resumeData.jobTitle}
                  />
                  <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
                    <Box sx={{ flexGrow: 25 }}>
                      <FormControl fullWidth>
                        <InputLabel id="targetLevelLabel">Target level</InputLabel>
                        <Select
                          labelId="targetLevelLabel"
                          id="targetLevelSelect"
                          value={resumeData.expLevel}
                          label="Target market"
                          onChange={handleChangeTargetLevel}
                        >
                          <MenuItem key={"Entry"} value={"Entry"}>
                            Entry
                          </MenuItem>
                          <MenuItem key={"Middle"} value={"Middle"}>
                            Middle
                          </MenuItem>
                          <MenuItem key={"Senior"} value={"Senior"}>
                            Senior
                          </MenuItem>
                          <MenuItem key={"Lead"} value={"Lead"}>
                            Lead
                          </MenuItem>
                          <MenuItem key={"Manager"} value={"Manager"}>
                            Manager
                          </MenuItem>
                          <MenuItem key={"Director"} value={"Director"}>
                            Director
                          </MenuItem>
                          <MenuItem key={"VP"} value={"VP"}>
                            VP
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <TextField
                        fullWidth
                        id="totalYears"
                        label="Total years of experience"
                        inputProps={{ min: 0, inputMode: "numeric" }}
                        onChange={handleTextFieldOnChange}
                        value={resumeData.totalYears}
                      />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <TextField
                        fullWidth
                        id="relevantYears"
                        label="Experience relevant to target role"
                        inputProps={{ min: 0, inputMode: "numeric" }}
                        onChange={handleTextFieldOnChange}
                        value={resumeData.relevantYears}
                      />
                    </Box>
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="skills">
                    <Typography sx={{ mb: 1 }}>Skills</Typography>
                  </Divider>
                  <Alert severity="info" sx={{ mb: 3 }}>
                    <Typography>
                      Please select all the skills relevant to your experience. This field serves as keywords for your resume. Recruiters and HR will use these
                      keywords to search for you, and they will be used for resume generation. Please take 5 minutes to carefully choose your skills. Select up
                      to
                      {10} skills.
                    </Typography>
                  </Alert>
                  <Skills
                    parentId={Number(resumeId)}
                    skillType="Skill"
                    maxSkills={10}
                    initialSkillSet={generatedSkills.Skill}
                    resumeOrWorkExperienceskillSet={resumeData.skills}
                    regenerateWithAi={true}
                    generateSkillSet={() => generateSkillSet("Skill")}
                    isFetching={isFetching.Skill}
                    onSkillDelete={handleDeleteSkillFromResume}
                    onSkillAdd={handleAddSkillToResume}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="skills">
                    <Typography sx={{ mb: 1 }}>Technologies</Typography>
                  </Divider>
                  <Skills
                    parentId={Number(resumeId)}
                    skillType="Tech"
                    maxSkills={10}
                    initialSkillSet={generatedSkills.Tech}
                    resumeOrWorkExperienceskillSet={resumeData.skills}
                    regenerateWithAi={true}
                    generateSkillSet={() => generateSkillSet("Tech")}
                    isFetching={isFetching.Tech}
                    onSkillDelete={handleDeleteSkillFromResume}
                    onSkillAdd={handleAddSkillToResume}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="summaryDivider" sx={{ mb: 3 }}>
                    <Typography>Summary</Typography>
                  </Divider>
                  <TextArea
                    id="summary"
                    label="Summary will be generated with AI. You can edit it after the generation"
                    minRows={6}
                    onChange={handleTextFieldOnChange}
                    value={resumeData.summary}
                  />
                </CardContent>
                <CardActions>
                  <Box sx={{ mb: 2, mr: 2 }}>
                    <Controls.Button
                      text={isLoading ? "Generating..." : resumeData.summary === "" ? "Generate with AI" : "Regenerate with AI"}
                      onClick={handleGenerateSummaryClick}
                      disabled={isLoading || rephraseSummaryIsLoading}
                      isLoading={isLoading}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Controls.Button
                      text={rephraseSummaryIsLoading ? "Generating..." : "Rephrase with AI"}
                      onClick={handleRephraseSummaryClick}
                      disabled={isLoading || rephraseSummaryIsLoading}
                      isLoading={rephraseSummaryIsLoading}
                    />
                  </Box>
                </CardActions>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="experience" sx={{ mb: 3 }}>
                    <Typography>Experience</Typography>
                  </Divider>
                  <WorkExperience
                    resumeId={resumeId}
                    initialSkills={resumeData.skills}
                    workExperiences={resumeData.workExperiences}
                    relevantYears={resumeData.relevantYears}
                    expLevel={resumeData.expLevel}
                    jobTitle={resumeData.jobTitle}
                    resumeTemplateId={resumeData.resumeTemplateId}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="education" sx={{ mb: 3 }}>
                    <Typography>Education</Typography>
                  </Divider>
                  <Education resumeData={resumeData} />
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Divider textAlign="left" id="certificates" sx={{ mb: 3 }}>
                    <Typography>Certificates</Typography>
                  </Divider>
                  <Certificate resumeData={resumeData} />
                </CardContent>
              </Card>
            </>
          }
          rightContent={
            <>
              <Preview resumeData={resumeData} />
              <ChatApp />
            </>
          }
          appBarButtons={
            <AppBarButtons
              isLoadingGenerateCv={isLoadingGenerateCv}
              document={<ResumeDocument resumeData={resumeData} />}
              downloadDOCXHandle={downloadDOCXHandle}
              isLoadingUpdateResume={isLoadingUpdateResume}
              updateResume={handleUpdateResume}
              resumeData={resumeData}
              dispatch={dispatch}
            />
          }
        />
      )}
    </>
  );
}
