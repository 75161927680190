// AppBarComponent.tsx
import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { Controls } from "../controls/Controls";
import { useNavigate } from "react-router-dom";
import { customColors } from "../../theme";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { useGetActiveSubscriptionQuery } from "../../store/api/billing.api";

/**
 * Props for AppBarComponent
 * @param {boolean} isDrawerOpen - State to determine if the drawer is open.
 * @param {() => void} handleDrawerToggle - Function to toggle the drawer.
 * @param {React.RefObject<HTMLDivElement>} appBarRef - Ref for the AppBar for height calculation.
 * @param {string} title - Title to be displayed in the AppBar.
 * @param {React.ReactNode} [actions] - Optional actions to be placed in the AppBar.
 */
interface AppBarComponentProps {
  isDrawerOpen: boolean;
  handleDrawerToggle: () => void;
  appBarRef: React.RefObject<HTMLDivElement>;
  title: string | React.ReactNode;
  actions?: React.ReactNode;
}

/**
 * AppBarComponent
 * Renders the top AppBar of the application, including a title and optional action items.
 *
 * @param {AppBarComponentProps} props - Props for the AppBarComponent.
 * @returns {React.ReactElement} - A Material-UI AppBar component.
 */
const AppBarComponent: React.FC<AppBarComponentProps> = ({ isDrawerOpen, handleDrawerToggle, appBarRef, title, actions }) => {
  const { data } = useGetActiveSubscriptionQuery();

  const navigate = useNavigate();
  return (
    <AppBar
      ref={appBarRef}
      position="fixed"
      elevation={0}
      sx={{
        borderBottom: 1,
        borderBlockColor: (theme) => theme.palette.primary.main, // Set primary color as border color
        backgroundColor: "white",
        width: { sm: `calc(100% - ${isDrawerOpen ? 180 : 57}px)` },
        ml: { sm: `${isDrawerOpen ? 180 : 57}px` },
        transition: (theme) =>
          theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <Toolbar variant="dense">
        {typeof title === "string" ? (
          <Typography variant="h4" noWrap component="div" sx={{ flexGrow: 1, px: 1 }} color={"primary"}>
            {title}
          </Typography>
        ) : (
          title // Directly render the React node
        )}
        {data && data.subscriptionStatus !== "ACTIVE" && (
          <Controls.Button
            onClick={() => navigate("/profile/payment-info")}
            text="Upgrade plan"
            sx={{
              maxWidth: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "bold",
              backgroundColor: customColors.darkGold.background,
              color: customColors.darkGold.text,
              "&:hover": {
                backgroundColor: customColors.darkGold.hover,
              },
            }}
            startIcon={<UpgradeIcon />}
          />
        )}

        {actions}
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
