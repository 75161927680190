// useDrawerItems.ts
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DrawerItemProps } from "../models/models";
import { logout } from "../store/auth/auth.slice";
import { api } from "../store/api/api";

export const useDrawerItems = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const drawerItems: Array<DrawerItemProps> = [
    {
      text: "Home",
      icon: <HomeIcon />,
      href: "/",
    },
    {
      text: "Account",
      icon: <PersonIcon />,
      href: "/profile",
      position: "bottom",
    },
    {
      text: "Logout",
      icon: <LogoutIcon />,
      onClick: () => {
        navigate("/signin");
        dispatch(logout());
        dispatch(api.util.resetApiState());
      },
      position: "bottom",
    },
  ];

  return drawerItems;
};
