import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a TypeScript interface for our state
interface AuthState {
  userId: number;
  email: string;
  token: string | null;
}

// Initial state with types applied
const initialState: AuthState = {
  userId: 0,
  email: "",
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ userId: number; email: string; token: string | null }>) => {
      const { userId, email, token } = action.payload;
      state.userId = userId;
      state.email = email;
      state.token = token;
    },
    logout: (state) => {
      // Instead of returning initialState directly, we should reset the state.
      // This ensures that the state update is done immutably.
      state.userId = initialState.userId;
      state.email = initialState.email;
      state.token = initialState.token;
    },
    updateEmail: (state, action: PayloadAction<{ email: string; token: string | null }>) => {
      const { email, token } = action.payload;
      state.email = email;
      state.token = token;
    },
  },
});

export const { actions, reducer } = authSlice;
export const { logout, setUser, updateEmail } = authSlice.actions;
