import { GridSortDirection } from "@mui/x-data-grid/models/gridSortModel";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface TableSort {
  field: string;
  direction: GridSortDirection;
}

// Define a type for the state structure
interface DashboardState {
  tableSorts: {
    resumesTable: TableSort;
    tailoredResumesTable: TableSort;
  };
  lastSelectedResumes: number[]; // Array to hold the IDs of the last selected resumes
  splitRatio: string; // New state field for split ratio
}

// Define a type for the payload of your setTableSort action
interface SetTableSortPayload {
  tableName: keyof DashboardState["tableSorts"];
  sortField: string;
  sortDirection: GridSortDirection;
}

const initialState = {
  isDrawerOpen: false,
  tableSorts: {
    resumesTable: { field: "createdAt", direction: "desc" as GridSortDirection },
    tailoredResumesTable: { field: "createdAt", direction: "desc" as GridSortDirection },
  },
  lastSelectedResumes: [] as number[],
  splitRatio: "50%", // Default split ratio
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    // Action to set the sorting for a specific table
    setTableSort: (state, action: PayloadAction<SetTableSortPayload | null>) => {
      if (action.payload === null) {
        state.tableSorts.resumesTable = { field: "", direction: null };
      } else {
        const { tableName, sortField, sortDirection } = action.payload;
        state.tableSorts[tableName] = { field: sortField, direction: sortDirection };
      }
    },

    addSelectedResume: (state, action: PayloadAction<number>) => {
      const newSelectedResume = action.payload;
      // Remove the resume if it's already in the array to avoid duplicates
      state.lastSelectedResumes = state.lastSelectedResumes.filter((id) => id !== newSelectedResume);
      // Add the new resume to the start of the array
      state.lastSelectedResumes.unshift(newSelectedResume);
      // Keep only the last 3 selected resumes
      state.lastSelectedResumes = state.lastSelectedResumes.slice(0, 3);
    },
    setSplitRatio: (state, action: PayloadAction<string>) => {
      state.splitRatio = action.payload;
    },
  },
});

export const { actions, reducer } = settingsSlice;

// Export the action creators
export const { toggleDrawer, setTableSort, addSelectedResume, setSplitRatio } = settingsSlice.actions;
