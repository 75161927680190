import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { TypographyProps } from "@mui/material/Typography/Typography";

// Extend the TypographyProps to ensure any additional props can be passed to the Typography component
interface CopyrightProps extends TypographyProps {}

const Copyright: React.FC<CopyrightProps> = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        cuvilab.com
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
};

export default Copyright;
