import React from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, IconButton, ThemeProvider } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import SignIn from "./scenes/signin/SignIn";
import SignUp from "./scenes/signin/SignUp";
import Page404 from "./scenes/global/404Page";
import ProtectedRoute from "./components/ProtectedRoute";
import ResetPasswordRequest from "./scenes/signin/ResetPasswordRequest";
import ResetPasswordForm from "./scenes/signin/ResetPasswordForm";
import PrivacyPolicy from "./scenes/global/PrivacyPolicyPage";
import Terms from "./scenes/global/TermsPage";
import { SnackbarProvider, closeSnackbar } from "notistack";
import Dashboard from "./scenes/dashboard/DashboardPage";
import ResumePage from "./scenes/resume/ResumePage";
import CloseIcon from "@mui/icons-material/Close";
import UserProfileTabs from "./scenes/profile/UserProfileTabs";
import TailoredResumePage from "./scenes/tailoredResume/TailoredResumePage";
import PaymentInfoPage from "./scenes/profile/PaymentInfoPage";
import ProfilePage from "./scenes/profile/ProfilePage";
import ContactUsPage from "./scenes/profile/ContactUsPage";

function App(): JSX.Element {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          action={(snackbarId) => (
            <IconButton aria-label="close" onClick={() => closeSnackbar(snackbarId)}>
              <CloseIcon />
            </IconButton>
          )}
        >
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="profile" element={<UserProfileTabs />}>
                <Route path="payment-info" element={<PaymentInfoPage />} />
                <Route path="change-password" element={<ProfilePage />} /> {/* Assuming this is the correct component for "Change password" */}
                <Route path="contact-us" element={<ContactUsPage />} />
              </Route>
              <Route path="resume/:resumeId" element={<ResumePage />} />
              <Route path="tailoredResume/:resumeId" element={<TailoredResumePage />} />
              <Route index element={<Dashboard />} />
            </Route>
            <Route path="signin" element={<SignIn />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="password-reset-request" element={<ResetPasswordRequest />} />
            <Route path="password-reset" element={<ResetPasswordForm />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="terms" element={<Terms />} />

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </SnackbarProvider>
        <CssBaseline />
        {/* <div className="app">
          <main className="content">
            <ResumePage />
          </main>
        </div> */}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
