import { Box } from "@mui/material";
import React from "react";

export default function PrivacyPolicy() {
  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} margin={5}>
      <div>
        <p>
          <b>
            <span>Privacy Policy</span>
          </b>{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          Protecting your private information is our priority. This Statement of Privacy applies to cuvilab.com, and Lavendo Inc and CUVI LLC and governs data
          collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Lavendo Inc and Resume Generator LLC include
          cuvilab.com. The Lavendo Inc and CUVI LLC website is a AI based job search products site. By using the Lavendo Inc and Resume Generator LLC website,
          you consent to the data practices described in this statement.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Collection of your Personal Information</b>{" "}
        </p>

        <p>
          In order to better provide you with products and services offered, Lavendo Inc and CUVI LLC may collect personally identifiable information, such as
          your:{" "}
        </p>

        <p>&nbsp; </p>

        <p>&nbsp;-          First and Last Name </p>

        <p>&nbsp;-          Mailing Address </p>

        <p>&nbsp;-          E-mail Address </p>

        <p>&nbsp;-          Phone Number </p>

        <p>&nbsp;-          Employer </p>

        <p>&nbsp;-          Job Title </p>

        <p>&nbsp;-          Education, Social media profiles links, Skills, Experience and other job related data </p>

        <p>&nbsp; </p>

        <p>
          If you purchase Lavendo Inc and CUVI LLC's products and services, we collect billing and credit card information. This information is used to complete
          the purchase transaction.{" "}
        </p>

        <p>&nbsp; </p>

        <p>Lavendo Inc and CUVI LLC may also collect anonymous demographic information, which is not unique to you, such as your: </p>

        <p>&nbsp; </p>

        <p>&nbsp;-          Age </p>

        <p>&nbsp;-          Gender </p>

        <p>&nbsp; </p>

        <p>
          We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal
          information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or
          contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e)
          submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for,
          but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal
          or non-personal information in the future.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Use of your Personal Information </b>
        </p>

        <p>Lavendo Inc and CUVI LLC collects and uses your personal information to operate and deliver the services you have requested. </p>

        <p>&nbsp; </p>

        <p>
          Lavendo Inc and CUVI LLC may also use your personally identifiable information to inform you of other products or services available from Lavendo Inc
          and CUVI LLC and its affiliates.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Sharing Information with Third Parties</b>{" "}
        </p>

        <p>Lavendo Inc and CUVI LLC does not sell, rent or lease its customer lists to third parties. </p>

        <p>&nbsp; </p>

        <p>
          Lavendo Inc and CUVI LLC may, from time to time, contact you on behalf of external business partners about a particular offering that may be of
          interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is transferred to the third
          party. Lavendo Inc and CUVI LLC may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide
          customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these
          services to Lavendo Inc and CUVI LLC, and they are required to maintain the confidentiality of your information.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          Lavendo Inc and CUVI LLC may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such
          action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Lavendo Inc and CUVI LLC or the site; (b) protect
          and defend the rights or property of Lavendo Inc and CUVI LLC; and/or (c) act under exigent circumstances to protect the personal safety of users of
          Lavendo Inc and CUVI LLC, or the public.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Opt-Out of Disclosure of Personal Information to Third Parties</b>{" "}
        </p>

        <p>In connection with any personal information we may disclose to a third party for a business purpose, you have the right to know: </p>

        <p>•     The categories of personal information that we disclosed about you for a business purpose. </p>

        <p>&nbsp; </p>

        <p>
          You have the right under the California Consumer Privacy Act of 2018 (CCPA) and certain other privacy and data protection laws, as applicable, to
          opt-out of the disclosure of your personal information. If you exercise your right to opt-out of the disclosure of your personal information, we will
          refrain from disclosing your personal information, unless you subsequently provide express authorization for the disclosure of your personal
          information. To opt-out of the disclosure of your personal information, visit this Web page _________________.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Tracking User Behavior</b>{" "}
        </p>

        <p>
          Lavendo Inc and CUVI LLC may keep track of the websites and pages our users visit within Lavendo Inc and CUVI LLC, in order to determine what Lavendo
          Inc and CUVI LLC services are the most popular. This data is used to deliver customized content and advertising within Lavendo Inc and CUVI LLC to
          customers whose behavior indicates that they are interested in a particular subject area.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Automatically Collected Information</b>{" "}
        </p>

        <p>
          Information about your computer hardware and software may be automatically collected by Lavendo Inc and CUVI LLC. This information can include: your
          IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to
          maintain quality of the service, and to provide general statistics regarding use of the Lavendo Inc and CUVI LLC website.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Use of Cookies</b>{" "}
        </p>

        <p>
          The Lavendo Inc and CUVI LLC website may use &quot;cookies&quot; to help you personalize your online experience. A cookie is a text file that is
          placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely
          assigned to you, and can only be read by a web server in the domain that issued the cookie to you.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that
          you have returned to a specific page. For example, if you personalize Lavendo Inc and CUVI LLC pages, or register with Lavendo Inc and CUVI LLC site
          or services, a cookie helps Lavendo Inc and CUVI LLC to recall your specific information on subsequent visits. This simplifies the process of
          recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Lavendo Inc and CUVI LLC
          website, the information you previously provided can be retrieved, so you can easily use the Lavendo Inc and CUVI LLC features that you customized.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to
          decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Lavendo Inc
          and CUVI LLC services or websites you visit.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Links</b>{" "}
        </p>

        <p>
          This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We
          encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable
          information.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Right to Deletion</b>{" "}
        </p>

        <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will: </p>

        <p>•     Delete your personal information from our records; and </p>

        <p>•     Direct any service providers to delete your personal information from their records. </p>

        <p>&nbsp; </p>

        <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to: </p>

        <p>
          •     Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted
          in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business
          relationship with you, or otherwise perform a contract between you and us;{" "}
        </p>

        <p>
          •     Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that
          activity;{" "}
        </p>

        <p>•     Debug to identify and repair errors that impair existing intended functionality; </p>

        <p>
          •     Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for
          by law;{" "}
        </p>

        <p>•     Comply with the California Electronic Communications Privacy Act; </p>

        <p>
          •     Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable
          ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research,
          provided we have obtained your informed consent;{" "}
        </p>

        <p>•     Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; </p>

        <p>•     Comply with an existing legal obligation; or </p>

        <p>
          •     Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the
          information.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Children Under Thirteen</b>{" "}
        </p>

        <p>
          Lavendo Inc and CUVI LLC does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the
          age of thirteen, you must ask your parent or guardian for permission to use this website.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Opt-Out &amp; Unsubscribe from Third Party Communications</b>
        </p>

        <p>
          We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving any
          or all communications from third-party partners of Lavendo Inc and CUVI LLC by contacting us here:{" "}
        </p>

        <p>- Web page: _________________ </p>

        <p>- Email: team@cuvilab.com </p>

        <p>- Phone: _________________ </p>

        <p>&nbsp; </p>

        <p>
          <b>E-mail Communications</b>{" "}
        </p>

        <p>
          From time to time, Lavendo Inc and CUVI LLC may contact you via email for the purpose of providing announcements, promotional offers, alerts,
          confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email
          from Lavendo Inc and CUVI LLC or click on a link therein.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          If you would like to stop receiving marketing or promotional communications via email from Lavendo Inc and CUVI LLC, you may opt out of such
          communications by clicking on the UNSUBSCRIBE button/link.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>External Data Storage Sites</b>{" "}
        </p>

        <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted. </p>

        <p>&nbsp; </p>

        <p>
          <b>Changes to this Statement</b>{" "}
        </p>

        <p>
          Lavendo Inc and CUVI LLC reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way
          we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our
          website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will
          constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.{" "}
        </p>

        <p>&nbsp; </p>

        <p>
          <b>Contact Information</b>{" "}
        </p>

        <p>
          Lavendo Inc and CUVI LLC welcomes your questions or comments regarding this Statement of Privacy. If you believe that Lavendo Inc and CUVI LLC has not
          adhered to this Statement, please contact Lavendo Inc and CUVI LLC at:{" "}
        </p>

        <p>&nbsp; </p>

        <p>Lavendo Inc and CUVI LLC </p>

        <p>_________________ </p>

        <p>_________________, _________________ _________________ </p>

        <p>&nbsp; </p>

        <p>Email Address: </p>

        <p>_________________ </p>

        <p>&nbsp; </p>

        <p>Telephone number: </p>

        <p>_________________ </p>

        <p>&nbsp; </p>

        <p>Effective as of October 03, 2023 </p>

        <p>&nbsp; </p>
      </div>
    </Box>
  );
}
