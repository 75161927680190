import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { cvApi } from "../store/api/resume.api";
import { useAppDispatch } from "../store/hooks";
import { useState } from "react";

const filter = createFilterOptions<IndustryOptionType>();

export default function Industry(props: any) {
  const { resumeId, resumeData } = props;
  const dispatch = useAppDispatch();

  const [value, setValue] = useState(resumeData.industries);
  // const { addIndustry, deleteIndustry, clearIndustry } = useActions();
  return (
    <Autocomplete
      fullWidth
      clearOnBlur
      multiple
      handleHomeEndKeys
      id="industry"
      options={industries}
      value={value}
      freeSolo
      isOptionEqualToValue={(option, value) => {
        const res = option.name === value.name || option.name === `Add "${value.name}"`;
        return res;
      }}
      getOptionDisabled={(options) => (value.length > 4 ? true : false)}
      renderInput={(params) => <TextField {...params} label="Industry (max 5)" />}
      onChange={(event, newValue: any, reason) => {
        if (newValue.length > 5) return;

        // setValue([...newValue.slice(0, -1), newValue[newValue.length - 1]]);
        // .map((obj: any) => (typeof obj === "string" ? obj : obj.inputValue ? obj.inputValue : { name: obj.name }))
        setValue(newValue.map((obj: any) => (obj.inputValue ? { name: obj.inputValue } : typeof obj === "string" ? { name: obj } : obj)));

        if (reason === "removeOption") {
          // setValue(newValue);
          dispatch(
            cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
              draft.industries = draft.industries.filter((obj) => newValue.find((obj2: any) => (obj2.inputValue || obj2.name) === obj.name));
            })
          );
        } else if (reason === "clear") {
          // setValue(newValue);
          dispatch(
            cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
              draft.industries = [];
            })
          );
        } else if (reason === "selectOption") {
          const newVal = newValue[newValue.length - 1];
          // setValue([
          //   ...newValue.slice(0, -1),
          //   {
          //     name: newVal.inputValue || newVal.name,
          //   },
          // ]);
          dispatch(
            cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
              if (draft.industries.findIndex((obj) => obj.name === newVal.name) === -1)
                draft.industries.push({
                  name: newVal.inputValue || newVal.name,
                  resumeId,
                });
            })
          );
        } else if (reason === "createOption") {
          const newVal = newValue[newValue.length - 1];
          // setValue([
          //   ...newValue.slice(0, -1),
          //   {
          //     name: newVal,
          //     resumeId,
          //   },
          // ]);
          dispatch(
            cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
              if (draft.industries.findIndex((obj) => obj.name === newVal) === -1)
                draft.industries.push({
                  name: newVal,
                  resumeId,
                });
            })
          );
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        // if (option.inputValue) {
        //   return option.inputValue;
        // }
        return option.name;
      }}
    />
    // <Autocomplete
    // isOptionEqualToValue={(option, value) => option.name === value.name}

    //
    //   getOptionDisabled={(options) => (value.length > 4 ? true : false)}
    //   renderInput={(params) => <TextField {...params} label="Industry (max 5)" />}
    //   sx={{ mb: 3 }}
    //   multiple

    //   // limitTags={2}
    // />
  );
}

interface IndustryOptionType {
  inputValue?: string;
  name: string;
}

//{title: Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const industries: readonly IndustryOptionType[] = [
  { name: "Accounting" },
  { name: "Agriculture" },
  { name: "Airlines/Aviation" },
  { name: "Alternative Dispute Resolution" },
  { name: "Alternative Medicine" },
  { name: "Animation" },
  { name: "Apparel & Fashion" },
  { name: "Application" },
  { name: "Architecture & Planning" },
  { name: "Art" },
  { name: "Artificial Intelligence (AI)" },
  { name: "Arts & Crafts" },
  { name: "Automotive" },
  { name: "Aviation & Aerospace" },
  { name: "Banking" },
  { name: "Biotechnology" },
  { name: "Broadcast Media" },
  { name: "Building Materials" },
  { name: "Business Supplies & Equipment" },
  { name: "Capital Markets" },
  { name: "Chemicals" },
  { name: "Civic & Social Organization" },
  { name: "Civil Engineering" },
  { name: "Cloud" },
  { name: "Commercial Real Estate" },
  { name: "Communications" },
  { name: "Computer & Network Security" },
  { name: "Computer Games" },
  { name: "Computer Hardware" },
  { name: "Computer Networking" },
  { name: "Computer Software" },
  { name: "Construction" },
  { name: "Consulting" },
  { name: "Consumer Electronics" },
  { name: "Consumer Goods" },
  { name: "Consumer Services" },
  { name: "Corporate Services" },
  { name: "Cosmetics" },
  { name: "Customer Relationship Management" },
  { name: "Dairy" },
  { name: "Data Integration" },
  { name: "Data platforms" },
  { name: "Data Storage" },
  { name: "Defense & Space" },
  { name: "Design" },
  { name: "E-commerce" },
  { name: "E-Learning" },
  { name: "Education" },
  { name: "Education Management" },
  { name: "Electrical & Electronic Manufacturing" },
  { name: "Energy" },
  { name: "Entertainment" },
  { name: "Environmental Services" },
  { name: "Events Services" },
  { name: "Executive Office" },
  { name: "Facilities & Services" },
  { name: "Farming" },
  { name: "Fashion" },
  { name: "Finance" },
  { name: "Financial Services" },
  { name: "Fine Art" },
  { name: "Fishery" },
  { name: "Fitness" },
  { name: "Food & Beverages" },
  { name: "Food Production" },
  { name: "Fundraising" },
  { name: "Furniture" },
  { name: "Gambling & Casinos" },
  { name: "Glass, Ceramics & Concrete" },
  { name: "Government Administration" },
  { name: "Government Relations" },
  { name: "Graphic Design" },
  { name: "Grocery" },
  { name: "Hardware" },
  { name: "Health Care" },
  { name: "Health, Wellness & Fitness" },
  { name: "Higher Education" },
  { name: "Hospital & Health Care" },
  { name: "Hospitality" },
  { name: "Human Resources (HR)" },
  { name: "Import & Export" },
  { name: "Individual & Family Services" },
  { name: "Industrial Automation" },
  { name: "Information Services" },
  { name: "Information Technology & Services" },
  { name: "Infrastructure" },
  { name: "Insurance" },
  { name: "International Affairs" },
  { name: "International Trade & Development" },
  { name: "Internet" },
  { name: "Investment Banking/Venture" },
  { name: "Investment Management" },
  { name: "IoT" },
  { name: "Judiciary" },
  { name: "Law Enforcement" },
  { name: "Law Practice" },
  { name: "Legal Services" },
  { name: "Legislative Office" },
  { name: "Leisure & Travel" },
  { name: "Leisure, Travel & Tourism" },
  { name: "Libraries" },
  { name: "Logistics & Supply Chain" },
  { name: "Luxury Goods & Jewelry" },
  { name: "Machinery" },
  { name: "Management Consulting" },
  { name: "Manufacturing" },
  { name: "Maritime" },
  { name: "Market Research" },
  { name: "Marketing & Advertising" },
  { name: "Mechanical or Industrial Engineering" },
  { name: "Media" },
  { name: "Media Production" },
  { name: "Medical Device" },
  { name: "Medical Practice" },
  { name: "Mental Health Care" },
  { name: "Military" },
  { name: "Mining & Metals" },
  { name: "Mobile Games" },
  { name: "Motion Pictures & Film" },
  { name: "Museums & Institutions" },
  { name: "Music" },
  { name: "Nanotechnology" },
  { name: "Networking" },
  { name: "Newspapers" },
  { name: "Nonprofit" },
  { name: "Oil & Energy" },
  { name: "Online Media" },
  { name: "Online Publishing" },
  { name: "Outsourcing/Offshoring" },
  { name: "Package/Freight Delivery" },
  { name: "Packaging & Containers" },
  { name: "Paper & Forest Products" },
  { name: "Performing Arts" },
  { name: "Pharmaceuticals" },
  { name: "Philanthropy" },
  { name: "Photography" },
  { name: "Plastics" },
  { name: "Political Organization" },
  { name: "Primary/Secondary Education" },
  { name: "Printing" },
  { name: "Professional Training & Coaching" },
  { name: "Program Development" },
  { name: "Public Administration" },
  { name: "Public Policy" },
  { name: "Public Relations & Communications" },
  { name: "Public Safety" },
  { name: "Publishing" },
  { name: "Railroad Manufacture" },
  { name: "Ranching" },
  { name: "Real Estate" },
  { name: "Recreation" },
  { name: "Recreational" },
  { name: "Recruiting" },
  { name: "Religious Institutions" },
  { name: "Renewables & Environment" },
  { name: "Research" },
  { name: "Restaurants" },
  { name: "Retail" },
  { name: "Robotics" },
  { name: "SAAS" },
  { name: "Science" },
  { name: "Security" },
  { name: "Security & Investigations" },
  { name: "Semiconductors" },
  { name: "Shipbuilding" },
  { name: "Software" },
  { name: "Sports" },
  { name: "Staffing & Recruiting" },
  { name: "Supermarkets" },
  { name: "Supply Chain" },
  { name: "Telecommunications" },
  { name: "Textiles" },
  { name: "Think Tanks" },
  { name: "Tobacco" },
  { name: "Translation & Localization" },
  { name: "Transportation & Logistics" },
  { name: "Transportation/Trucking/Railroad" },
  { name: "Travel" },
  { name: "Utilities" },
  { name: "Venture Capital" },
  { name: "Veterinary" },
  { name: "Warehousing" },
  { name: "Wellness" },
  { name: "Wholesale" },
  { name: "Wine & Spirits" },
  { name: "Wine and Spirits" },
  { name: "Wireless" },
  { name: "Workforce Management" },
  { name: "Writing & Editing" },
];
