// DrawerComponent.tsx
import { Drawer, ListItemIcon, ListItemText, ListItemButton, List } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link as RouterLink } from "react-router-dom";
import { DrawerItemProps } from "../../models/models";

/**
 * Props for DrawerComponent
 * @param {boolean} isDrawerOpen - State to control if the drawer is open or closed.
 * @param {() => void} handleDrawerToggle - Function to toggle the drawer's open/close state.
 * @param {React.ReactNode} drawerItems - Dynamic content to be rendered inside the drawer.
 * @param {React.CSSProperties} [style] - Optional custom styling for the drawer component.
 */
interface DrawerComponentProps {
  isDrawerOpen: boolean;
  handleDrawerToggle: () => void;
  drawerItems: DrawerItemProps[]; // Array of items with position
  style?: React.CSSProperties;
}

/**
 * DrawerComponent
 * Renders a navigation drawer that can be toggled open and closed. The content of the drawer
 * is dynamic, allowing for flexible usage. Includes a transition effect for the opening and closing action.
 *
 * @param {DrawerComponentProps} props - Props for the DrawerComponent.
 * @returns {React.ReactElement} - A MUI Drawer component.
 */
const DrawerComponent: React.FC<DrawerComponentProps> = ({
  isDrawerOpen,
  handleDrawerToggle,
  drawerItems,
  style,
}: DrawerComponentProps): React.ReactElement => {
  // Function to render items based on position
  const renderDrawerItems = (position: "top" | "bottom") =>
    drawerItems
      .filter((item) => item.position === position || (!item.position && position === "top")) // Default to 'top'
      .map((item, index) => {
        // Decide whether to render a link or a button with an onClick handler
        const key = `${item.text}-${index}`;
        if (item.href) {
          return (
            <ListItemButton component={RouterLink} to={item.href} key={key}>
              <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          );
        } else {
          return (
            <ListItemButton onClick={item.onClick} key={key}>
              <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          );
        }
      });

  return (
    <Drawer
      color="black"
      variant="permanent"
      sx={{
        width: 57,
        "& .MuiDrawer-paper": {
          backgroundColor: (theme) => theme.palette.primary.main,
          color: "white",
          width: isDrawerOpen ? 180 : 57,
          boxSizing: "border-box",
          overflowX: "hidden",
          ...style,
        },
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
      }}
      open={isDrawerOpen}
    >
      <List sx={{ p: 0 }}>
        <ListItemButton divider={true} onClick={handleDrawerToggle} key={"toggle-drawer"} sx={{ height: "49px", mb: "10px" }}>
          {isDrawerOpen && (
            <ListItemText sx={{ m: 0 }} primary={"CUVI"} primaryTypographyProps={{ letterSpacing: "8px", color: "white", fontSize: "24px", fontWeight: 900 }} />
          )}
          <ListItemIcon sx={{ color: "white", minWidth: "0px" }}>{isDrawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}</ListItemIcon>
        </ListItemButton>
        {renderDrawerItems("top")}
      </List>
      <List sx={{ marginTop: "auto" }}>{renderDrawerItems("bottom")}</List>
    </Drawer>
  );
};

export default DrawerComponent;
