import { IPasswordRequestResponse } from "../../models/models";
import { api } from "./api";

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    signup: build.mutation({
      query: (body) => ({
        url: "signup",
        method: "POST",
        body,
      }),
    }),
    signin: build.mutation({
      query: (body) => ({
        url: "signin",
        method: "POST",
        body,
      }),
    }),
    resetPasswordRequest: build.mutation<IPasswordRequestResponse, any>({
      query: (body) => ({
        url: "resetPasswordRequest",
        method: "POST",
        body,
      }),
    }),
    resetPassword: build.mutation({
      query: (body) => ({
        url: "resetPassword",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useSignupMutation, useSigninMutation, useResetPasswordRequestMutation, useResetPasswordMutation } = authApi;

export const {
  endpoints: { signup, signin },
} = authApi;
