import { format, isDate, parseISO } from "date-fns";
import { IResume, IWorkExperience } from "../models/models.js";
import { getStateLabelByCode } from "./stateUtils";
// pdfUtils.ts
import * as pdfjsLib from "pdfjs-dist";
import { getDocument } from "pdfjs-dist";

// Setup PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.mjs"; // Ensure this path is correct

// Export PDF.js instance
export const pdfjsInstance = pdfjsLib;

export async function loadAndSetPageCount(pdfBlob: Blob, setPageCount: (count: number) => void) {
  const url = URL.createObjectURL(pdfBlob); // Create object URL
  const loadingTask = getDocument(url); // Use getDocument directly with updated import
  try {
    const pdf = await loadingTask.promise;
    setPageCount(pdf.numPages);

    // Ensure cleanup is performed to free memory
    pdf.cleanup();
  } catch (error) {
    console.error("Error loading PDF:", error);
    // Handle error (e.g., display error message)
  } finally {
    URL.revokeObjectURL(url); // Revoke object URL after use

    // Explicitly terminate the worker associated with the loading task
    loadingTask.destroy();
  }
}

function concatenateEducation(degree: string, fieldOfStudy: string) {
  // If both degree and fieldOfStudy are provided
  if (degree && fieldOfStudy) {
    return `${degree}'s degree in ${fieldOfStudy}`;
  }
  // If only degree is provided
  if (degree) {
    return `${degree}'s degree`;
  }
  // If only fieldOfStudy is provided
  if (fieldOfStudy) {
    return fieldOfStudy;
  }
  // If neither is provided or both are empty strings
  return "";
}

/**
 * Sorts work experiences by current position and end date.
 * Current positions are prioritized, followed by more recent end dates.
 *
 */
export const sortWorkExperiences = (workExperiences: IWorkExperience[]) => {
  return workExperiences.sort((a, b) => {
    if (a.currentPosition && !b.currentPosition) return -1; // a is current, sort first
    if (!a.currentPosition && b.currentPosition) return 1; // b is current, sort first

    // Handle null/undefined end dates by setting them to a fallback date (e.g., epoch)
    const dateA: Date = a.endDate ? new Date(a.endDate) : new Date(0);
    const dateB: Date = b.endDate ? new Date(b.endDate) : new Date(0);

    return dateB.getTime() - dateA.getTime() || a.id - b.id; // Sort by endDate, then by id as a tiebreaker
  });
};

function formatWorkExperience3rdRow(item: IWorkExperience) {
  const parts: string[] = []; // Initialize parts array

  // Handle the presence of companyDesc
  if (item.companyDesc) {
    parts.push(item.companyDesc); // Start with company description
    const details: string[] = [];

    // Add employmentType if present
    if (item.employmentType) {
      details.push(item.employmentType);
    }

    // Add locationType if it exists
    if (item.locationType) {
      details.push(item.locationType);
    }

    // Concatenate details with comma and wrap in parentheses if there are any details
    if (details.length > 0) {
      parts.push(`(${details.join(", ")})`);
    }
  } else {
    // Handle when companyDesc is not present
    const details: string[] = [];

    // Only add employmentType if it's "Internship" or "Contractor"
    if (item.employmentType && ["Internship", "Contractor"].includes(item.employmentType)) {
      details.push(item.employmentType);

      // Since employmentType is a condition for adding locationType, check it here
      if (item.locationType) {
        details.push(item.locationType);
      }

      // If after the checks, there are details to add, concatenate them
      if (details.length > 0) {
        parts.push(`(${details.join(", ")})`);
      }
    }
  }

  // Join parts into a single string and return
  return parts.join(" ");
}

//Format start\end date for Work experience
const formatDateRange = (item: IWorkExperience): string => {
  // Helper function to safely format dates
  const safeFormat = (date: string | Date | null, formatStr: string): string => {
    if (!date) return "";
    if (typeof date === "string") {
      // Parse and format if it's a string
      return format(parseISO(date), formatStr);
    } else if (isDate(date)) {
      // Directly format if it's a Date object
      return format(date, formatStr);
    }
    return "";
  };

  const startDateStr = safeFormat(item.startDate, "MMMM yyyy");
  const endDateStr = item.currentPosition ? "Now" : safeFormat(item.endDate, "MMMM yyyy");

  // Construct the formatted date range string.
  if (startDateStr && (endDateStr || item.currentPosition)) {
    return `${startDateStr} - ${endDateStr}`;
  }

  return startDateStr || endDateStr;
};

export function prepareCv(originalResume: IResume): IResume {
  const resume: IResume = JSON.parse(JSON.stringify(originalResume));

  // Sort and format educations
  if (resume.educations) {
    resume.educations = resume.educations
      .sort((a, b) => Number(a.endYear) - Number(b.endYear))
      .map((education) => {
        education.degree = concatenateEducation(education.degree, education.fieldOfStudy);
        return education;
      });
  }

  // Sort and format certificates
  if (resume.certificates) {
    resume.certificates = resume.certificates
      .sort((a, b) => Number(a.issueDate) - Number(b.issueDate))
      .map((certificate) => {
        certificate.issueDate = certificate.issueDate ? format(parseISO(certificate.issueDate), "MMMM yyyy") : "";
        return certificate;
      });
  }

  // Filter and format skills
  if (resume.skills) {
    const techSkills = resume.skills
      .filter((skill) => skill.type === "Tech")
      .map((skill) => skill.name)
      .join(", ");
    const otherSkills = resume.skills
      .filter((skill) => skill.type === "Skill")
      .map((skill) => skill.name)
      .join(", ");
    resume.techSkillsText = techSkills;
    resume.otherSkillsText = otherSkills;
  }

  // Sort and format work experiences
  if (resume.workExperiences) {
    resume.workExperiences = sortWorkExperiences(resume.workExperiences).map((job) => {
      job.startDate = formatDateRange(job);
      job.companyDesc = formatWorkExperience3rdRow(job);
      job.countryCityStateText = `${job.city || ""}${job.city && job.country ? ", " : ""}${
        job.country === "US" || job.country === "United States" ? job.state : job.country || ""
      }`;
      job.hascompanyDesc = !!job.companyDesc.trim();
      return job;
    });
  }

  // Format state label
  resume.state = resume.state ? getStateLabelByCode(resume.state) : "";

  // Check various conditions
  resume.haslinkedInProfileLink = !!resume.linkedInProfileLink;
  resume.haseducations = Array.isArray(resume.educations) && resume.educations.length > 0;
  resume.hascertificates = Array.isArray(resume.certificates) && resume.certificates.length > 0;
  resume.hasotherSkillsText = !!resume.otherSkillsText;
  resume.hastechSkillsText = !!resume.techSkillsText;

  return resume;
}
