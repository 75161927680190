import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Chip,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { ISkill, IWorkExperience, IWorkExperienceSkill } from "../models/models";
import { format, parseISO } from "date-fns";
import { useAppDispatch } from "../store/hooks";
import { cvApi } from "../store/api/resume.api";
import StarIcon from "@mui/icons-material/Star";
import { capitalizeFirstLetter } from "../utils/stringUtils";

type ISkillsTechnologies = {
  skills: {
    required: string[];
    nice_to_have: string[];
  };
  technologies: {
    required: string[];
    nice_to_have: string[];
  };
};

type SkillDialogProps = {
  isOpen: boolean;
  skills: ISkillsTechnologies; // Array of skills extracted from the job description
  experiences: IWorkExperience[];
  onClose: (selectedSkills: { [skill: string]: number[] }) => void;
  onOneClickTailor: (selectedSkills: { [skill: string]: number[] }) => void;
  onStepByStepTailor: (selectedSkills: { [skill: string]: number[] }) => void;
  resumeId: number;
};

const AddSkillToWorkDialog: React.FC<SkillDialogProps> = ({ isOpen, skills, experiences, onClose, onOneClickTailor, onStepByStepTailor, resumeId }) => {
  const dispatch = useAppDispatch();
  const [selectedSkills, setSelectedSkills] = useState<{ [skill: string]: number[] }>({});
  const [activeSkill, setActiveSkill] = useState<string | null>(null);
  const [isFinalStep, setIsFinalStep] = useState(false);
  const isInitialized = useRef(false);

  // Combine skills and technologies into a single array
  const allSkillsAndTechs = useMemo(() => {
    // Correctly accessing required and nice_to_have from both skills and technologies
    const combinedSkills = [
      ...skills.skills.required.map((skill) => ({ name: skill, required: true, type: "Skill" })),
      ...skills.technologies.required.map((tech) => ({ name: tech, required: true, type: "Technology" })),
      ...skills.skills.nice_to_have.map((skill) => ({ name: skill, required: false, type: "Skill" })),
      ...skills.technologies.nice_to_have.map((tech) => ({ name: tech, required: false, type: "Technology" })),
    ];
    return Array.from(new Set(combinedSkills)); // Converts Set back to Array
  }, [skills]);

  useEffect(() => {
    // Function to initialize selectedSkills based on existing work experiences
    const initializeSelectedSkills = () => {
      const initialSkills: { [skill: string]: number[] } = {};

      // Flatten the structure to get a combined list of all skill and technology names
      const allSkillNames = [
        ...skills.skills.required,
        ...skills.skills.nice_to_have,
        ...skills.technologies.required,
        ...skills.technologies.nice_to_have,
      ].map((skillName) => skillName.toLowerCase());

      experiences.forEach((experience) => {
        experience.skills.forEach((skill) => {
          const expId = Number(experience.id);
          if (allSkillNames.includes(skill.name.toLowerCase())) {
            // Initialize or update the array of experience IDs for this skill
            if (!initialSkills[skill.name.toLowerCase()]) {
              initialSkills[skill.name.toLowerCase()] = [];
            }
            if (!initialSkills[skill.name.toLowerCase()].includes(expId)) {
              initialSkills[skill.name.toLowerCase()].push(expId);
            }
          }
        });
      });
      return initialSkills;
    };

    if (isOpen && !isInitialized.current) {
      const initialSkills = initializeSelectedSkills();
      setSelectedSkills(initialSkills);
      // Assuming allSkillsAndTechs is an array of objects { name: string, required: boolean, type: string }
      // and you want to set the first skill or technology as the active one:
      setActiveSkill(allSkillsAndTechs.length > 0 ? allSkillsAndTechs[0].name : null);
      setIsFinalStep(false);
      isInitialized.current = true; // Set the ref to true after initial setup
    }

    // Reset the ref when dialog closes
    if (!isOpen) {
      isInitialized.current = false;
    }
  }, [isOpen, skills, experiences, allSkillsAndTechs]);

  const handleToggle = (experienceId: number) => {
    if (activeSkill === null) return;

    setSelectedSkills((prevSelectedSkills) => {
      const currentExperiences = prevSelectedSkills[activeSkill.toLowerCase()] || [];
      const isCurrentlyChecked = currentExperiences.includes(experienceId);

      const updatedExperiences = isCurrentlyChecked ? currentExperiences.filter((id) => id !== experienceId) : [...currentExperiences, experienceId];

      if (isCurrentlyChecked === updatedExperiences.includes(experienceId)) {
        // If the state doesn't change, return previous state to avoid unnecessary re-render
        return prevSelectedSkills;
      }

      dispatchUpdateLogic(experienceId, updatedExperiences.includes(experienceId), activeSkill);

      return {
        ...prevSelectedSkills,
        [activeSkill.toLowerCase()]: updatedExperiences,
      };
    });
  };

  const dispatchUpdateLogic = (experienceId: number, isAdding: boolean, activeSkill: string) => {
    let skillType: "Skill" | "Tech" | undefined;

    // Check if activeSkill is in any of the skills arrays
    if (skills.skills.required.includes(activeSkill) || skills.skills.nice_to_have.includes(activeSkill)) {
      skillType = "Skill";
    } else if (skills.technologies.required.includes(activeSkill) || skills.technologies.nice_to_have.includes(activeSkill)) {
      skillType = "Tech";
    } else {
      // If activeSkill is not found in any category, handle as needed (e.g., undefined or "Unknown")
      skillType = undefined; // Adjust based on your scenario
    }
    if (isAdding) {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId.toString(), (draft: any) => {
          // Check if the skill already exists in draft.skills to prevent duplicates
          const skillExists = draft.skills.some(
            (skillObj: ISkill) => skillObj.name.toLowerCase() === activeSkill.toLowerCase() && skillObj.resumeId === resumeId
          );

          // Only add the skill if it does not exist
          if (!skillExists) {
            draft.skills.unshift({ name: capitalizeFirstLetter(activeSkill), resumeId, ...(skillType && { type: skillType }) });
          }
          const index = draft.workExperiences.findIndex((exp: any) => exp.id === experienceId);
          if (index !== -1) {
            draft.workExperiences[index].skills.unshift({ name: capitalizeFirstLetter(activeSkill), workExperienceId: experienceId });
          }
        })
      );
    } else {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId.toString(), (draft: any) => {
          draft.skills = draft.skills.filter((skillObj: ISkill) => skillObj.name.toLowerCase() !== activeSkill.toLowerCase());
          const objWithIdIndex = draft.workExperiences.findIndex((exp: any) => exp.id === experienceId);
          if (objWithIdIndex !== -1) {
            draft.workExperiences[objWithIdIndex].skills = draft.workExperiences[objWithIdIndex].skills.filter(
              (skillObj: IWorkExperienceSkill) => skillObj.name.toLowerCase() !== activeSkill.toLowerCase()
            );
          }
        })
      );
    }
  };

  const handleNextPrev = (direction: "next" | "prev") => {
    const currentIndex = allSkillsAndTechs.findIndex((skill) => skill.name.toLowerCase() === (activeSkill + "").toLowerCase());
    let newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;

    // Check if the new index is beyond the skills array, indicating the final step
    if (newIndex >= allSkillsAndTechs.length) {
      setIsFinalStep(true);
    } else {
      setIsFinalStep(false); // Reset if going back
      newIndex = Math.max(0, Math.min(newIndex, allSkillsAndTechs.length - 1)); // Keep index within bounds
      setActiveSkill(allSkillsAndTechs[newIndex].name);
    }
  };

  const handleClose = () => {
    onClose(selectedSkills); // Pass the selectedSkills when manually closing the dialog
  };

  const handleSkillClick = (skillName: string) => {
    setSelectedSkills((prevSelectedSkills) => {
      if (!prevSelectedSkills[skillName]) {
        return { ...prevSelectedSkills, [skillName]: [] };
      }
      return prevSelectedSkills;
    });
    setActiveSkill(skillName);
  };

  const handleOneClickTailor = () => {
    onOneClickTailor(selectedSkills); // Pass the selectedSkills when manually closing the dialog
  };

  const handleStepByStepTailor = () => {
    onStepByStepTailor(selectedSkills);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        style: {
          minWidth: "800px", // Set your desired fixed width
          minHeight: "400px", // Set your desired fixed height
        },
      }}
    >
      <DialogTitle>
        {isFinalStep ? (
          <>Choose how you would like to tailor your resume:</>
        ) : (
          <>
            <Box m={2}>
              <Typography>
                Based on <b>AI-analyzed</b> job information, choose skills that align with your work experience.
              </Typography>
              <Typography>
                Skills marked with a <b>star</b> have <b>high priority</b> for this position.
              </Typography>
              <Typography mb={2}>
                Please <b>avoid</b> including all skills for every job. We <b>recommend</b> prioritizing the most relevant skills for each job position.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {allSkillsAndTechs.map((skill, index) => (
                <Chip
                  key={`${skill.name}-${index}`}
                  label={skill.name}
                  onClick={() => handleSkillClick(skill.name)}
                  // color={skill.required ? "primary" : "default"}
                  color={"primary"}
                  // Highlight the chip if it's the active skill
                  variant={(activeSkill + "").toLowerCase() === skill.name.toLowerCase() ? "filled" : "outlined"}
                  icon={skill.required ? <StarIcon /> : undefined}
                  style={{
                    margin: 2,
                    fontWeight: skill.required ? "bold" : "normal",
                    backgroundColor: (activeSkill + "").toLowerCase() === skill.name.toLowerCase() ? "green" : "", // Additional highlighting for active skill
                  }}
                />
              ))}
            </Box>
          </>
        )}
      </DialogTitle>
      {/* <DialogTitle>Select where you've used the skill: {currentSkill}</DialogTitle> */}

      <DialogContent>
        {allSkillsAndTechs.length === 0 ? (
          <Typography variant="body1" sx={{ textAlign: "center", my: 2 }}>
            No skills available to select.
          </Typography>
        ) : isFinalStep ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    One-click tailor
                  </Typography>
                  <Typography variant="body1">Automatically tailor your resume based on selected skills.</Typography>
                  <Button variant="contained" onClick={handleOneClickTailor} sx={{ marginTop: 2 }}>
                    One-click tailor
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Tailor step by step
                  </Typography>
                  <Typography variant="body1">Manually tailor your resume, step by step.</Typography>
                  <Button variant="contained" onClick={handleStepByStepTailor} sx={{ marginTop: 2 }}>
                    Tailor step by step
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <List>
            {activeSkill &&
              experiences.map((experience, index) => (
                <ListItem key={`${experience.id}-${index}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedSkills[activeSkill.toLowerCase()]?.includes(experience.id) || false}
                        onChange={() => handleToggle(experience.id)}
                      />
                    }
                    label={
                      <Box display={"flex"} flexDirection={"column"}>
                        <Typography>
                          {experience.position !== "" ? experience.position : "Position"} at{" "}
                          {experience.companyName !== "" ? experience.companyName : "Company"}
                        </Typography>
                        <Typography>
                          {experience.startDate === "" || experience.startDate === null ? "Start date" : format(parseISO(experience.startDate), "MMMM yyyy")} -{" "}
                          {experience.endDate === "" || experience.endDate === null ? "End date" : format(parseISO(experience.endDate), "MMMM yyyy")}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        {allSkillsAndTechs.length === 0 ? (
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        ) : isFinalStep ? (
          <Button onClick={() => setIsFinalStep(false)}>Back</Button>
        ) : (
          <>
            {activeSkill && (
              <>
                {allSkillsAndTechs.findIndex((skill) => skill.name.toLowerCase() === activeSkill.toLowerCase()) > 0 && (
                  <Button onClick={() => handleNextPrev("prev")}>Prev</Button>
                )}
                {allSkillsAndTechs.findIndex((skill) => skill.name.toLowerCase() === activeSkill.toLowerCase()) < allSkillsAndTechs.length - 1 && (
                  <Button onClick={() => handleNextPrev("next")}>Next</Button>
                )}
                <Button onClick={() => setIsFinalStep(true)}>Finish</Button>
              </>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(AddSkillToWorkDialog);
