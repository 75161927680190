import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import { IEducation, IResume } from "../../../models/models";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controls } from "../../../components/controls/Controls";
import EducationForm from "./EducationForm";
import { cvApi, useCreateEducationMutation, useDeleteEducationMutation } from "../../../store/api/resume.api";
import { useAppDispatch } from "../../../store/hooks";
import AlertDialog from "../../../components/AlertDialog";

interface EducationProps {
  resumeData: IResume;
  showActionButtons?: boolean;
}

export default function Education({ resumeData, showActionButtons = true }: EducationProps) {
  const [deletingIetemId, setDeletingIetemId] = useState<null | string>(null);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [createEducation, { data: newEducationData, isLoading: isLoadingCreate }] = useCreateEducationMutation();
  const [deleteEducation] = useDeleteEducationMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    newEducationData && setExpanded(newEducationData.id.toString());
  }, [newEducationData]);

  const handleDeleteEducation = async (id: string | null) => {
    if (id) {
      setDeletingIetemId(null);
      await deleteEducation(id);
      dispatch(cvApi.util.invalidateTags([{ type: "Resume", id: resumeData.id }]));
    }
  };

  const handleAddEducation = async (resumeId: string) => {
    await createEducation(resumeData);
  };

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        {resumeData.educations.map((item: IEducation, index) => (
          <Box display={"flex"} flexDirection={"row"} key={`edu-${index}`} alignItems={"flex-start"}>
            {/* <Accordion key={item.id} expanded={expand && elementsCount === index}> */}
            <Accordion key={item.id} sx={{ width: "100%" }} expanded={expanded === item.id.toString()} onChange={handleAccordionChange(item.id.toString())}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography>
                    {item.degree !== "" ? `${item.degree}'s degree` : "Degree"} in {item.fieldOfStudy !== "" ? `${item.fieldOfStudy}` : "Study"} at{" "}
                    {item.name !== "" ? item.name : "College/University"}
                  </Typography>
                  {/* 
                
                  <Typography>{item.name}</Typography>
                  <Typography>{item.degree}</Typography> */}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <EducationForm education={item} resumeData={resumeData} />
              </AccordionDetails>
            </Accordion>
            {showActionButtons && (
              <Tooltip title="Delete">
                <IconButton onClick={() => setDeletingIetemId(item.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ))}
      </Box>
      {showActionButtons && (
        <Box sx={{ mt: 1 }}>
          <Controls.Button
            disabled={isLoadingCreate}
            text={isLoadingCreate ? "Creating.." : "Add new education"}
            onClick={() => handleAddEducation(resumeData.id.toString())}
            isLoading={isLoadingCreate}
          ></Controls.Button>
        </Box>
      )}
      <AlertDialog
        title="Do you want to delete this education?"
        buttonOneCallback={() => handleDeleteEducation(deletingIetemId)}
        onClose={() => setDeletingIetemId(null)}
        isOpen={!!deletingIetemId}
      />
    </>
  );
}
