import { IBullet, IBulletsRequest, ICertificate, IEducation, IGetSkillsRequest, IResume, IWorkExperience } from "../../models/models";
import { api } from "./api";

export const cvApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSummary: build.mutation<string, IResume>({
      query: (body: IResume) => ({
        url: `resume/summary`,
        method: "POST",
        body,
      }),
    }),
    getRephraseSummary: build.mutation<string, IResume>({
      query: (body: IResume) => ({
        url: `resume/rephraseSummary`,
        method: "POST",
        body,
      }),
    }),
    getSkills: build.query<string[], IGetSkillsRequest>({
      query: (requestParams: IGetSkillsRequest) => {
        const params = new URLSearchParams();
        // Append each skill in the array to the query string
        // JSON stringify the skills array
        if (requestParams.skills) {
          params.append("skills", JSON.stringify(requestParams.skills));
        }
        params.append("id", requestParams.id.toString());
        params.append("jobTitle", requestParams.jobTitle);
        requestParams.resumeTemplateId && params.append("resumeTemplateId", String(requestParams.resumeTemplateId));
        requestParams.totalYears && params.append("totalYears", requestParams.totalYears.toString());
        requestParams.relevantYears && params.append("relevantYears", requestParams.relevantYears.toString());
        requestParams.type && params.append("type", requestParams.type);
        return {
          url: `resume/skills`,
          params: params, // The URLSearchParams object will be correctly interpreted
        };
      },
    }),
    getBullets: build.mutation<{ [key: string]: IBullet[] }, IBulletsRequest>({
      query: (body: IBulletsRequest) => ({
        url: `resume/bullets`,
        method: "POST",
        body,
      }),
    }),
    getResumeList: build.query<Array<IResume>, void>({
      query: () => "resume/getResumeList",
      providesTags: (result, error, arg) => (result ? [...result.map(({ id }) => ({ type: "Resume" as const, id })), "Resume"] : ["Resume"]),
    }),
    newResume: build.mutation<{ resumeId: number; template: string; title: string }, string>({
      query: (type: string) => ({
        url: `resume/newResume/${type}`,
        method: "POST",
      }),
      invalidatesTags: ["Resume"],
    }),
    updateResume: build.mutation<void, Partial<IResume>>({
      query: (body) => ({
        url: `resume/updateResume/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Resume", id: arg.id }],
    }),
    getResumeById: build.query<IResume, string>({
      query: (id: string) => ({
        url: `resume/getResumeById/${id}`,
      }),
      transformResponse: (response: IResume, meta, arg) => {
        return { ...response, type: "IResume" };
      },
      providesTags: (result, error, arg) => (result ? [{ type: "Resume" as const, id: result.id }, "Resume"] : ["Resume"]),
    }),
    deleteResume: build.mutation<void, number>({
      query: (id: number) => ({
        url: `resume/deleteResume/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Resume", id: arg }],
    }),
    copyResume: build.mutation<{ resumeId: number; template: string; title: string }, string>({
      query: (id: string) => ({
        url: `resume/copyResume/${id}`,
        method: "COPY",
      }),
      invalidatesTags: ["Resume"],
    }),
    createWorkExperience: build.mutation<IWorkExperience, string>({
      query: (resumeId: string) => ({
        url: `resume/createWorkExperience/${resumeId}`,
        method: "POST",
      }),
      transformResponse: (response: IWorkExperience, meta, arg) => {
        return { ...response, type: "IWorkExperience" };
      },
      // invalidatesTags: (result, error, arg) => [{ type: "Resume", id: arg }],
    }),
    deleteWorkExperience: build.mutation<void, number>({
      query: (id: number) => ({
        url: `resume/deleteWorkExperience/${id}`,
        method: "DELETE",
      }),
    }),
    getWorkExperienceListByResumeId: build.query<IWorkExperience[], string>({
      query: (resumeId: string) => ({
        url: `resume/getWorkExperienceListByResumeId/${resumeId}`,
        method: "GET",
      }),
    }),
    createEducation: build.mutation<IEducation, IResume>({
      query: (resume: IResume) => ({
        url: `resume/createEducation/${resume.id}`,
        method: "POST",
        body: resume,
      }),
      transformResponse: (response: IEducation, meta, arg) => {
        return { ...response, type: "IEducation" };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Resume", id: arg.id }],
    }),
    deleteEducation: build.mutation<void, string>({
      query: (id: string) => ({
        url: `resume/deleteEducation/${id}`,
        method: "DELETE",
      }),
    }),
    createCertificate: build.mutation<ICertificate, IResume>({
      query: (resume: IResume) => ({
        url: `resume/createCertificate/${resume.id}`,
        method: "POST",
        body: resume,
      }),
      transformResponse: (response: ICertificate, meta, arg) => {
        return { ...response, type: "ICertificate" };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Resume", id: arg.id }],
    }),
    deleteCertificate: build.mutation<void, string>({
      query: (id: string) => ({
        url: `resume/deleteCertificate/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetSummaryMutation,
  useLazyGetSkillsQuery,
  useGetBulletsMutation,
  useGetRephraseSummaryMutation,
  useGetResumeListQuery,
  useUpdateResumeMutation,
  useGetResumeByIdQuery,
  useLazyGetResumeByIdQuery,
  useNewResumeMutation,
  useCreateWorkExperienceMutation,
  useDeleteWorkExperienceMutation,
  useCreateEducationMutation,
  useDeleteEducationMutation,
  useDeleteResumeMutation,
  useCreateCertificateMutation,
  useDeleteCertificateMutation,
  useCopyResumeMutation,
  useGetWorkExperienceListByResumeIdQuery,
} = cvApi;
