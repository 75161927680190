import React, { ChangeEvent, FocusEvent } from "react";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";

interface EditableTitleProps {
  title: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
}

const EditableTitle: React.FC<EditableTitleProps> = ({ title, onChange, onBlur }) => {
  const theme = useTheme();

  return (
    <Tooltip title="Edit resume name" placement="bottom-start">
      <TextField
        value={title}
        onChange={onChange}
        onBlur={onBlur}
        id="title"
        variant="outlined"
        fullWidth
        InputProps={{
          sx: {
            fontSize: theme.typography.h4.fontSize,
            fontWeight: theme.typography.h4.fontWeight,
            lineHeight: theme.typography.h4.lineHeight,
            cursor: "pointer",
            color: theme.palette.primary.main,
            "&::placeholder": {
              color: theme.palette.primary.main,
            },
            ".MuiInputBase-input": {
              px: 1,
              py: 1,
              cursor: "pointer",
            },
          },
        }}
        sx={{
          cursor: "pointer",
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "primary.main",
          },
        }}
        placeholder="Resume Title Here"
      />
    </Tooltip>
  );
};

export default EditableTitle;
