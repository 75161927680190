import { api } from "./api";

export const profileApi = api.injectEndpoints({
  endpoints: (build) => ({
    changePassword: build.mutation({
      query: (body) => ({
        url: "user/changePassword",
        method: "POST",
        body,
      }),
    }),
    changeEmail: build.mutation({
      query: (body) => ({
        url: "user/changeEmail",
        method: "POST",
        body,
      }),
    }),
    submitSupportForm: build.mutation({
      query: (body) => ({
        url: "user/submitSupportForm",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useChangePasswordMutation, useChangeEmailMutation, useSubmitSupportFormMutation } = profileApi;

export const {
  endpoints: { changePassword, changeEmail, submitSupportForm },
} = profileApi;
