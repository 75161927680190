import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useResetPasswordMutation } from "../../store/api/auth.api";
import { useSearchParams } from "react-router-dom";

import { FormContainer, PasswordElement } from "react-hook-form-mui";
import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { IPasswordRequestResponse } from "../../models/models";
import Copyright from "./Copyright";

type FormProps = {
  password: string;
};

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function ResetPasswordForm() {
  const [resetPassword, { isLoading, data }] = useResetPasswordMutation();
  const [searchParams] = useSearchParams();
  const [alert, setAlert] = useState<IPasswordRequestResponse>();

  useEffect(() => {
    if (data) {
      setAlert(data);
    }
  }, [data]);

  const onSubmit = async (data: FormProps) => {
    await resetPassword({ password: data.password, id: searchParams.get("id"), token: searchParams.get("token") });
  };
  const defaultValues: FormProps = { password: "" };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {alert ? <Alert severity={alert.severity || "info"}>{alert.message}</Alert> : <></>}

          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Typography variant="body2" component="p">
            Here you can change your password.
          </Typography>
          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <PasswordElement
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              validation={{
                minLength: {
                  value: 6,
                  message: "Minimum 6 symbols",
                },
              }}
            />
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isLoading}>
              {isLoading ? "Submitting..." : "Reset"}
            </Button>
          </FormContainer>
          <Grid container>
            <Grid item>
              {"Don't have an account? "}
              <Link href="/signup" variant="body2">
                {"Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              {"Have an account? "}
              <Link href="/signin" variant="body2">
                {"Sign In"}
              </Link>
            </Grid>
          </Grid>
        </Box>

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
