import { IGeneratePDFResponse, IResume } from "../../models/models";
import { api } from "./api";

// Define a type for the request to specify the document type along with the resume data
interface IGenerateDocumentRequest {
  resumeData: Partial<IResume>;
  docType: "pdf" | "docx"; // Specify the document type as either 'pdf' or 'docx'
}

export const pdfApi = api.injectEndpoints({
  endpoints: (build) => ({
    generateCv: build.mutation<IGeneratePDFResponse, IGenerateDocumentRequest>({
      query: ({ resumeData, docType }) => ({
        url: `document/generateCV/${docType}`, // Modify the URL to include the document type
        method: "POST",
        body: resumeData,
      }),
    }),
    generateCvByResumeId: build.query<IGeneratePDFResponse, { id: number; docType: "pdf" | "docx" }>({
      query: ({ id, docType }) => ({
        url: `document/generateCV/${docType}/${id}`, // Include the document type in the URL
      }),
    }),
  }),
});

export const { useGenerateCvMutation, useLazyGenerateCvByResumeIdQuery } = pdfApi;
