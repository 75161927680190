import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";

import Layout from "../../components/layout/Layout";
import { useDrawerItems } from "../../components/DrawerItems";
import { getAppVersion } from "../../utils/versionUtils";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

export default function UserProfileTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const drawerItems = useDrawerItems();

  const [appVersion, setAppVersion] = useState<string>("N/A");

  useEffect(() => {
    getAppVersion().then((version: string) => {
      setAppVersion(version);
    });
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  // Change handler for tabs not needed to navigate, as navigation is handled by <Link> components or useNavigate

  useEffect(() => {
    // Mapping of tab indices to their corresponding path segments
    const tabPaths = ["/profile/payment-info", "/profile/change-password", "/profile/contact-us"];

    // Determine the selected tab based on the current path
    const currentPath = location.pathname;
    const tabIndex = tabPaths.findIndex((path) => currentPath.endsWith(path));
    if (tabIndex !== -1) {
      setValue(tabIndex);
    } else {
      // Default to the first tab if no matching path is found
      setValue(0);
      navigate(tabPaths[0], { replace: true });
    }
  }, [location, navigate]);

  return (
    <Layout
      title={"Account settings"}
      leftContent={
        <Box display={"flex"} flexDirection={"column"} height={"100%"}>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <Tabs orientation="vertical" variant="standard" value={value} onChange={handleChange} sx={{ borderRight: 1, borderColor: "divider" }}>
              <Tab component={Link} to="/profile/payment-info" label="Payment Info" sx={{ alignItems: "start" }} />
              <Tab component={Link} to="/profile/change-password" label="Change password" sx={{ alignItems: "start" }} />
              <Tab component={Link} to="/profile/contact-us" label="Contact Us" sx={{ alignItems: "start" }} />
            </Tabs>
            <Box sx={{ flexGrow: 1, width: "80%" }}>
              <Outlet />
            </Box>
          </Box>
          <Typography color={"primary"} pl={2}>
            Version: {appVersion}
          </Typography>
        </Box>
      }
      drawerItems={drawerItems}
    />
  );
}
