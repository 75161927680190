import { IApplicationState, ITailoredResume, TailorResumeRequest, TailorResumeResponse } from "../../models/models";
import { api } from "./api";

export const cvtApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTailoredResumeList: build.query<Array<ITailoredResume>, void>({
      query: () => "tailoredResume/getResumeList",
      providesTags: (result, error, arg) =>
        result ? [...result.map(({ id }) => ({ type: "TailoredResume" as const, id })), "TailoredResume"] : ["TailoredResume"],
    }),
    newTailoredResume: build.mutation<{ resumeId: number; title: string }, number>({
      query: (sourceResumeId: number) => ({
        url: `tailoredResume/newResume/${sourceResumeId}`,
        method: "POST",
      }),
      invalidatesTags: ["TailoredResume"],
    }),
    getTailoredResumeById: build.query<ITailoredResume, string>({
      query: (id: string) => ({
        url: `tailoredResume/getResumeById/${id}`,
      }),
      transformResponse: (response: ITailoredResume, meta, arg) => {
        return { ...response, type: "ITailoredResume" };
      },
      providesTags: (result, error, arg) => (result ? [{ type: "TailoredResume" as const, id: result.id }, "TailoredResume"] : ["TailoredResume"]),
    }),
    updateTailoredResume: build.mutation<void, Partial<ITailoredResume>>({
      query: (body) => ({
        url: `tailoredResume/updateResume/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "TailoredResume", id: arg.id }],
    }),
    deleteTailoredResume: build.mutation<void, string>({
      query: (id: string) => ({
        url: `tailoredResume/deleteResume/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "TailoredResume", id: arg }],
    }),
    tailorResume: build.mutation<TailorResumeResponse, TailorResumeRequest>({
      query: (body) => ({
        url: `tailoredResume/tailorResume`,
        method: "POST",
        body,
      }),
    }),
    tailorSkills: build.mutation<
      { requiredTechs: { skills: { required: []; nice_to_have: [] }; technologies: { required: []; nice_to_have: [] } } },
      Partial<ITailoredResume>
    >({
      query: (body) => ({
        url: `tailoredResume/tailorSkills`,
        method: "POST",
        body,
      }),
    }),
    getApplicationStateList: build.query<Array<IApplicationState>, void>({
      query: () => "tailoredResume/getApplicationStateList",
    }),
    updateJobLink: build.mutation<void, { id: string; value: string }>({
      query: (body) => ({
        url: `tailoredResume/updateJobLink/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "TailoredResume", id: arg.id }],
    }),
    updateNotes: build.mutation<void, { id: string; value: string }>({
      query: (body) => ({
        url: `tailoredResume/updateNotes/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "TailoredResume", id: arg.id }],
    }),
    updateApplicationDate: build.mutation<void, { id: string; value: string }>({
      query: (body) => ({
        url: `tailoredResume/updateApplicationDate/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "TailoredResume", id: arg.id }],
    }),
    updateApplicationStatus: build.mutation<void, { id: string; value: string }>({
      query: (body) => ({
        url: `tailoredResume/updateApplicationStatus/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "TailoredResume", id: arg.id }],
    }),
    getTailoredSummary: build.mutation<string, TailorResumeRequest>({
      query: (body: TailorResumeRequest) => ({
        url: `tailoredResume/getTailoredSummary`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useNewTailoredResumeMutation,
  useGetTailoredResumeByIdQuery,
  useGetTailoredResumeListQuery,
  useUpdateTailoredResumeMutation,
  useDeleteTailoredResumeMutation,
  useTailorResumeMutation,
  useGetApplicationStateListQuery,
  useUpdateJobLinkMutation,
  useUpdateNotesMutation,
  useUpdateApplicationDateMutation,
  useUpdateApplicationStatusMutation,
  useTailorSkillsMutation,
  useGetTailoredSummaryMutation,
} = cvtApi;
