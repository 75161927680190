import { Autocomplete, Box, TextField, createFilterOptions } from "@mui/material";
import { Controls } from "../../../components/controls/Controls";

import { parseISO } from "date-fns";
import { IEducation, IResume } from "../../../models/models";
import { useAppDispatch } from "../../../store/hooks";
import { cvApi } from "../../../store/api/resume.api";
import { ChangeEvent, useState } from "react";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import { handleDateChange } from "../../../utils/dateUtils";

export default function EducationForm(props: { education: IEducation; resumeData: IResume }) {
  const { education } = props;
  const dispatch = useAppDispatch();

  const getFirstPartOfId = (id: string) => id.split("-")[0];

  function updateFiledValue(fieldId: any, value: any) {
    dispatch(
      cvApi.util.updateQueryData("getResumeById", education.resumeId.toString(), (draft) => {
        const objWithIdIndex = draft.educations.findIndex((obj) => obj.id === education.id);
        draft.educations = draft.educations.map((obj, i) => {
          if (i === objWithIdIndex) {
            return { ...draft.educations[objWithIdIndex], [fieldId]: value };
          }
          return obj;
        });
      })
    );
  }

  const handleNameChange = (e: any) => {
    updateFiledValue(getFirstPartOfId(e.currentTarget.id), capitalizeFirstLetter(e.currentTarget.value));
  };
  const handleDegreeChange = (e: any) => {
    updateFiledValue(getFirstPartOfId(e.currentTarget.id), capitalizeFirstLetter(e.currentTarget.value));
  };

  interface DegreeOptionType {
    inputValue?: string;
    title: string;
  }

  const DEGREE_OPTIONS: readonly DegreeOptionType[] = [
    { title: "Associate Degree" },
    { title: "Associate of Arts (AA)" },
    { title: "Associate of Business Administration" },
    { title: "Associate of Science (AS)" },
    { title: "Bachelor" },
    { title: "Bachelor of Arts (BA)" },
    { title: "Bachelor of Business Administration" },
    { title: "Bachelor of Science (BS)" },
    { title: "Certificate" },
    { title: "Doctor of Medicine (MD)" },
    { title: "Doctor of Pharmacy (PharmD)" },
    { title: "Doctorate" },
    { title: "GED / High School Diploma" },
    { title: "Juris Doctor (JD)" },
    { title: "L.L.M. (Taxation)" },
    { title: "Master" },
    { title: "Master of Arts (MA)" },
    { title: "Master of Science (MS)" },
    { title: "Masters in Business Administration (MBA)" },
    { title: "PHD" },
    // Add other preset degree options here
  ];

  const filter = createFilterOptions<DegreeOptionType>();
  const [value, setValue] = useState<DegreeOptionType | null>(
    DEGREE_OPTIONS.find((option) => option.title === education.degree) || (education.degree ? { title: education.degree } : null)
  );

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Controls.Input
          id={`name-edu-${education.id}`}
          name="name"
          label="College/University"
          value={education.name}
          onChange={handleNameChange}
          sx={{ mb: 3 }}
          fullWidth
          autoComplete="on"
        />
        <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
          <Box sx={{ flexGrow: 1 }}>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                let newDegreeValue;

                if (typeof newValue === "string") {
                  newDegreeValue = newValue;
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  newDegreeValue = newValue.inputValue;
                } else {
                  newDegreeValue = newValue ? newValue.title : "";
                }

                setValue({ title: newDegreeValue }); // Update local state
                updateFiledValue("degree", newDegreeValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.title);
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={DEGREE_OPTIONS}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              freeSolo
              renderInput={(params) => <TextField {...params} label="Degree" />}
            />
          </Box>
          <Box sx={{ flexGrow: 2 }}>
            <Controls.Input
              id={`fieldOfStudy-edu-${education.id}`}
              name="Field of study"
              label="Field of study (Science, Marketing, etc)"
              value={education.fieldOfStudy}
              onChange={handleDegreeChange}
              fullWidth
            ></Controls.Input>
          </Box>
        </Box>
        <Controls.DatePicker
          value={education.endYear === "" || education.endYear === null ? new Date() : parseISO(education.endYear)}
          // value={parse(education.endYear, "yyyy", new Date())}
          name="endYear"
          id={`endYear-edu-${education.id}`}
          label={"Graduation Year"}
          // helperText={'The "year" field will be used solely for sorting. Year will not be visible on your resume.'}
          views={["year"]}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleDateChange(e, updateFiledValue, getFirstPartOfId)}
          sx={{ mb: 3 }}
          helperText={`The "year" field will be used solely for sorting. Year will not be visible on your resume.`}
        />
        {/* <Controls.Input name="endYear" label="End year" value={education.degree} onChange={handleEndYearChange} sx={{ mb: 3 }} fullWidth></Controls.Input> */}

        {/* <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
          <Controls.Button text="Submit" sx={{ mb: 3, mr: 5 }} />
          <Controls.Button text="Cancel" color="secondary" sx={{ mb: 3 }} onClick={resetForm} />
        </Box> */}
      </Box>
    </>
  );
}
