import React, { useState, useCallback } from "react";
import ChatWithAI from "./ChatWithAI";
import SpeedDialChat from "./SpeedDialChat";
import { Box } from "@mui/material";

const ChatApp: React.FC = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [hasWarning, setHasWarning] = useState(false); // New state to track warnings

  const toggleChat = useCallback(() => {
    setIsChatOpen((prevIsOpen) => !prevIsOpen);
    if (isChatOpen) {
      setHasUnreadMessages(false);
    }
  }, [isChatOpen]);

  const notifyNewMessage = useCallback(() => {
    if (!isChatOpen) {
      setHasUnreadMessages(true);
    }
  }, [isChatOpen]);

  const updateWarningState = useCallback((warningExists: boolean) => {
    setHasWarning(warningExists);
  }, []);

  return (
    <Box sx={{ zIndex: 1300, position: "fixed", right: 16, bottom: 16 }}>
      <SpeedDialChat onOpen={toggleChat} isOpen={isChatOpen} hasUnreadMessages={hasUnreadMessages} hasWarning={hasWarning} />
      <Box sx={{ display: isChatOpen ? "block" : "none" }}>
        {/* Always render ChatWithAI but control its visibility with CSS */}
        <ChatWithAI notifyNewMessage={notifyNewMessage} updateWarningState={updateWarningState} />
      </Box>
    </Box>
  );
};

export default ChatApp;
