import { Box } from "@mui/material";
import { Controls } from "../../../components/controls/Controls";

import { parseISO } from "date-fns";
import { ICertificate, IResume } from "../../../models/models";
import { useAppDispatch } from "../../../store/hooks";
import { cvApi } from "../../../store/api/resume.api";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import { ChangeEvent } from "react";
import { handleDateChange } from "../../../utils/dateUtils";

export default function CertificateForm(props: { certificate: ICertificate; resumeData: IResume }) {
  const { certificate } = props;
  const dispatch = useAppDispatch();

  const getFirstPartOfId = (id: string) => id.split("-")[0];

  function updateFiledValue(fieldId: any, value: any) {
    dispatch(
      cvApi.util.updateQueryData("getResumeById", certificate.resumeId.toString(), (draft) => {
        const objWithIdIndex = draft.certificates.findIndex((obj) => obj.id === certificate.id);
        draft.certificates = draft.certificates.map((obj, i) => {
          if (i === objWithIdIndex) {
            return { ...draft.certificates[objWithIdIndex], [fieldId]: value };
          }
          return obj;
        });
      })
    );
  }

  const handleNameChange = (e: any) => {
    updateFiledValue(getFirstPartOfId(e.currentTarget.id), capitalizeFirstLetter(e.currentTarget.value));
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Controls.Input
          id={`name-cert-${certificate.id}`}
          name="name"
          label="Certificate name"
          value={certificate.name}
          onChange={handleNameChange}
          sx={{ mb: 3 }}
          fullWidth
          autoComplete="on"
        />
        <Controls.DatePicker
          value={certificate.issueDate === "" || certificate.issueDate === null ? new Date() : parseISO(certificate.issueDate)}
          // value={parse(education.endYear, "yyyy", new Date())}
          name="issueDate"
          id={`issueDate-cert-${certificate.id}`}
          label={"Issue date"}
          // helperText={'The "year" field will be used solely for sorting. Year will not be visible on your resume.'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleDateChange(e, updateFiledValue, getFirstPartOfId)}
          sx={{ mb: 3 }}
        />
        {/* <Controls.Input name="endYear" label="End year" value={education.degree} onChange={handleEndYearChange} sx={{ mb: 3 }} fullWidth></Controls.Input> */}

        {/* <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
          <Controls.Button text="Submit" sx={{ mb: 3, mr: 5 }} />
          <Controls.Button text="Cancel" color="secondary" sx={{ mb: 3 }} onClick={resetForm} />
        </Box> */}
      </Box>
    </>
  );
}
