import * as React from "react";
import CheckIcon from "@mui/icons-material/Check";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSignupMutation } from "../../store/api/auth.api";
import { useNavigate } from "react-router-dom";

import { CheckboxElement, FormContainer, PasswordElement, TextFieldElement } from "react-hook-form-mui";
import { useEffect } from "react";
import { useActions } from "../../hooks/useActions";
import { CircularProgress, Fab } from "@mui/material";
import green from "@mui/material/colors/green";
import Copyright from "./Copyright";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type FormProps = {
  email: string;
  password: string;
};

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const { setUser } = useActions();
  const [signup, { isLoading, data }] = useSignupMutation();

  const onSubmit = async (data: FormProps) => {
    await signup({ email: data.email, password: data.password });
  };

  const isAuthenticated = useSelector((state: RootState) => !!state.auth.token);

  useEffect(() => {
    // Redirect to dashboard if the user is already logged in
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (data) {
      setUser(data);
      navigate("/");
    }
  }, [data, navigate, setUser]);

  const defaultValues: FormProps = { email: "", password: "" };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1, position: "relative" }}>
            <Fab aria-label="save" color="primary">
              {data ? <CheckIcon /> : <LockOutlinedIcon />}
            </Fab>
            {isLoading && (
              <CircularProgress
                size={68}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
              />
            )}
          </Box>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>

          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <TextFieldElement
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              validation={{
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
            />
            <PasswordElement
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              validation={{
                minLength: {
                  value: 6,
                  message: "Minimum 6 symbols",
                },
              }}
            />
            <CheckboxElement
              value="privacy"
              color="primary"
              label={
                <div>
                  <span>By creating an account, you agree to Cuvi’s </span>
                  <Link target="_blank" href={"/terms"}>
                    Terms and Conditions
                  </Link>
                  <span> and </span>
                  <Link target="_blank" href={"/privacy"}>
                    Privacy Policy
                  </Link>
                </div>
              }
              name={"privacy"}
              required
            />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isLoading}>
              {isLoading ? "Signing Up..." : "Sign Up"}
            </Button>
          </FormContainer>
          <Grid container>
            <Grid item xs>
              <Link href="/password-reset-request" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signin" variant="body2">
                {"Have an account? Sign In"}
              </Link>
            </Grid>
          </Grid>
        </Box>

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
