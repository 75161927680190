import {
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  IconButton,
  InputBase,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ChangeEventHandler, MouseEvent, KeyboardEventHandler, useEffect, useState, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IBullet, IWorkExperienceSkill } from "../models/models";
import { useGetBulletsMutation } from "../store/api/resume.api";
import TextArea from "./TextArea";
import Markdown from "markdown-to-jsx";
import { stripMarkdown } from "../utils/stringUtils";

const BULLET_PREFIX = "• ";

interface NewBulletsProps {
  workExperienceId: number;
  resumeId: string;
  jobTitle: string;
  relevantYears: number;
  expLevel: string;
  resumeTemplateId: number;
  position?: string;
  companyName?: string;
  achievments?: string;
  skills: IWorkExperienceSkill[];
  bullets: string;
  onBulletUpdate: (value: string) => void;
  tailoredResume?: boolean;
}

export default function Bullets(props: NewBulletsProps) {
  const {
    jobTitle,
    relevantYears,
    expLevel,
    resumeTemplateId,
    position = "",
    companyName = "",
    achievments = "",
    skills,
    bullets,
    onBulletUpdate,
    tailoredResume = true,
    resumeId,
    workExperienceId,
  } = props;

  const [value, setValue] = useState<string>(bullets);
  const [isEnter, setIsEnter] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [searched, setSearched] = useState<string>("");
  const [items, setItems] = useState<{ [key: string]: IBullet[] }>({});
  const [clickedItems, setClickedItems] = useState<{ [key: string]: boolean }>({});
  const [initialItems, setInitialItems] = useState<{ [key: string]: IBullet[] }>({});
  const [filteredItems, setFilteredItems] = useState<{ [key: string]: IBullet[] }>({});
  const [regenerateBullets, setRegenerateBullets] = useState<boolean>(false);
  const [getBullets, { data, isLoading }] = useGetBulletsMutation();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  //Set flag ready to update bullets, if fields has changed
  useEffect(() => {
    setRegenerateBullets(true);
  }, [achievments, companyName, position, skills]);

  //Update data from server
  useEffect(() => {
    if (data) {
      setItems(data);
      setInitialItems(data);
      const lines = value.split("\n");
      setClickedItems((prev) => updateClickedItems(prev, lines, data));
      setRegenerateBullets(false);
    }
  }, [data, value]);

  // Function to set focus to the input
  const setFocus = () => {
    // Check if the inputRef is not null, then focus
    if (inputSearchRef.current) {
      inputSearchRef.current.focus();
    }
  };

  // Function to handle search input change
  const handleInputSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    requestSearch(event.target.value);
  };

  // Function to execute the search logic and update filtered items
  const requestSearch = (searchedVal: string) => {
    setSearched(searchedVal);
    const newFilteredItems: { [key: string]: IBullet[] } = {};

    for (const category in initialItems) {
      if (Object.prototype.hasOwnProperty.call(initialItems, category)) {
        newFilteredItems[category] = initialItems[category].filter((item) => stripMarkdown(item.title).toLowerCase().includes(searchedVal.toLowerCase()));
      }
    }

    setFilteredItems(newFilteredItems);
  };

  // Function to handle opening of the popover
  const handleClickOpen = async (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (regenerateBullets) {
      // Clear the current lists before fetching new bullets
      setItems({});
      setClickedItems({});
      setFilteredItems({});
      setSearched("");
      getBulletsHandler();
      // setValue(""); //  clear the input value
    }
  };

  const getBulletsHandler = async () => {
    await getBullets({
      resume: { id: Number(resumeId), resumeTemplateId, jobTitle, relevantYears, expLevel },
      workExperience: { resumeId: Number(resumeId), id: workExperienceId, position, companyName, achievments, skills },
    });
  };

  // Function to handle closing of the popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Function to handle clicking of an item in the list
  const handleItemClick = (category: string, index: number) => {
    const uniqueKey = `${category}-${index}`;
    setClickedItems((prev) => ({ ...prev, [uniqueKey]: true }));

    const newItem = BULLET_PREFIX + stripMarkdown(items[category][index].title);
    const newValue = value ? `${value}\n${newItem}` : newItem;
    setValue(newValue);
    onBulletUpdate(newValue);
  };

  // Function to update the clicked items state
  const updateClickedItems = (current: { [key: string]: boolean }, lines: string[], groupedItems: { [key: string]: IBullet[] }): { [key: string]: boolean } => {
    return Object.keys(groupedItems).reduce<{ [key: string]: boolean }>(
      (acc, category) => {
        groupedItems[category].forEach((item, index) => {
          const uniqueKey = `${category}-${index}`;
          acc[uniqueKey] = lines.some((line) => line.replace(BULLET_PREFIX.trim(), "").trim() === stripMarkdown(item.title));
        });
        return acc;
      },
      { ...current }
    );
  };

  // Function to handle changes in the input(multiline) field
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = event.target.value;
    const lines = inputValue.split("\n");
    const lastLine = lines.pop() || "";
    const isSingleLine = lines.length === 0;

    if (isEnter) {
      const newVal = [...lines, lastLine === "" ? BULLET_PREFIX : BULLET_PREFIX + lastLine].join("\n");
      setValue(newVal);
      onBulletUpdate(newVal);
      setIsEnter(false);
    } else if (isSingleLine && lastLine === BULLET_PREFIX.trim()) {
      setValue(BULLET_PREFIX);
      onBulletUpdate(BULLET_PREFIX);
    } else if (isSingleLine && !lastLine.startsWith(BULLET_PREFIX.trim())) {
      setValue(BULLET_PREFIX + lastLine);
      onBulletUpdate(BULLET_PREFIX + lastLine);
    } else {
      setValue(inputValue);
      onBulletUpdate(inputValue);
    }
  };

  // Function to handle key down events in the input field
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter") {
      setIsEnter(true);
    }
  };
  return (
    // <Box position="relative" width="100%">
    <>
      <Box>
        <Toolbar
          sx={{
            flexDirection: "column",
            minHeight: 0,
            paddingRight: 0,
            pb: 1,
            "@media (min-width: 600px)": {
              paddingRight: 0,
              minHeight: 0,
              pb: 1,
            },
          }}
        >
          {/* Other toolbar items can be added here as needed */}
          <Button
            variant="contained"
            onClick={handleClickOpen}
            sx={{ ml: "auto" }} // Positions the button to the right
            disabled={skills.length < 4}
          >
            Generate work experience with AI
          </Button>
          {skills.length < 4 && (
            <Typography variant="caption" style={{ whiteSpace: "pre-wrap" }} color="red" ml={"auto"}>
              Please select minimum 4 skills
            </Typography>
          )}
        </Toolbar>
        <TextArea
          id={`bullets-workExp-${workExperienceId}`}
          name="bullets"
          label={`Bullet points`}
          value={tailoredResume ? value : bullets}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={(event) => (event.target.value.trim() === BULLET_PREFIX.trim() ? setValue("") : "")}
          onFocus={(event) => (event.target.value.trim() === "" ? setValue(BULLET_PREFIX) : "")}
        />
      </Box>
      <Popover
        sx={{ minWidth: 700 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        onClose={handleClose}
        // slotProps={{
        //   backdrop: { invisible: true },
        // }}
      >
        <DialogTitle sx={{ m: 0, p: 0, display: "flex", alignItems: "center", justifyContent: "space-between", minWidth: 420 }}>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <Tooltip title="Serach the bullets here">
              <IconButton sx={{ p: "10px" }} aria-label="search" onClick={setFocus}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                "& .MuiInputBase-input": {
                  border: 0,
                  padding: "10px 0",
                },
              }}
              placeholder={isLoading ? "Bullets are loading.." : "Search the bullets by text"}
              inputProps={{ "aria-label": "Search the bullets by text" }}
              value={searched}
              onChange={handleInputSearchChange}
              inputRef={inputSearchRef}
            />
          </Box>
          {isLoading && <CircularProgress color="success" size={"20px"} />}
          {!isLoading && (
            <Tooltip title="Refresh the bullets">
              <IconButton
                aria-label="Refresh"
                onClick={async () => {
                  // Clear the current lists before fetching new bullets
                  setItems({});
                  setClickedItems({});
                  setFilteredItems({});
                  setSearched("");
                  // setValue(""); //  clear the input value
                  getBulletsHandler();
                }}
                sx={{
                  p: "10px",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Close the window">
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                p: "10px",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <List sx={{ maxWidth: "420px" }}>
          {Object.entries(searched.length > 0 ? filteredItems : items).map(([category, categoryItems]) => (
            <div key={category}>
              <ListSubheader sx={{ color: "#ec930c", fontSize: "14px", letterSpacing: "1.8px", textTransform: "uppercase" }}>{category}</ListSubheader>
              {categoryItems.map((item, index) => {
                // Creating a unique key for each item based on category and index
                const uniqueKey = `${category}-${index}`;
                const isItemClicked = clickedItems[uniqueKey];

                return (
                  <Tooltip title="Add bullet to Resume" key={`bullet-tooltip-${uniqueKey}`}>
                    <ListItemButton
                      key={uniqueKey}
                      disabled={isItemClicked}
                      disableRipple={isItemClicked}
                      onClick={() => handleItemClick(category, index)}
                      sx={{
                        p: "6,16",
                        ...(isItemClicked
                          ? {
                              cursor: "default",
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                              "&.Mui-disabled": { opacity: 1 },
                            }
                          : {
                              "&:hover": {
                                backgroundColor: "transparent",
                                "& .MuiListItemText-primary, & .MuiSvgIcon-root": {
                                  color: "#1a91f0",
                                },
                              },
                            }),
                      }}
                    >
                      <ListItemIcon sx={{ "& .MuiSvgIcon-root": { fontSize: "2rem" }, minWidth: 0, mr: "16px" }}>
                        {isItemClicked ? <CheckCircleOutlineIcon color="success" /> : <ArrowCircleLeftIcon />}
                      </ListItemIcon>
                      <ListItemText
                        primary={<Markdown>{item.title}</Markdown>}
                        primaryTypographyProps={{
                          style: {
                            color: isItemClicked ? "#828ba2" : "",
                          },
                        }}
                        sx={{ m: 0 }}
                      />
                    </ListItemButton>
                  </Tooltip>
                );
              })}
            </div>
          ))}
        </List>
      </Popover>
    </>
  );
}
