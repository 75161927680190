import React, { useEffect } from "react";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Typography, useTheme } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { useCreateCheckoutSessionMutation } from "../store/api/billing.api";
import { customColors } from "../theme";

interface IPlan {
  id: number;
  title: string;
  price: number;
  description: string;
  featured: boolean;
  buttonText: string;
  lookupKey: string;
}

export default function Plan({ title, price, description, featured, buttonText, lookupKey }: IPlan) {
  const theme = useTheme();
  const [createCheckoutSession, { data }] = useCreateCheckoutSessionMutation();

  useEffect(() => {
    if (data?.url) {
      window.location.href = data.url;
    }
  }, [data]);

  const handleCreateCheckoutSession = async (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    await createCheckoutSession({ lookupKey });
  };

  return (
    <Card
      sx={{
        ":hover": {
          transform: "scale(1.05)",
          boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
          cursor: "pointer",
        },
      }}
      onClick={handleCreateCheckoutSession}
    >
      <CardHeader
        title={
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, textAlign: "center", color: theme.palette.primary.dark }}>
              {title}
            </Typography>
            {featured && <Chip label="- 60%" color="primary" size="small" />}
          </Box>
        }
        sx={{
          backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[700],
        }}
      />
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "baseline", mb: 2 }}>
          <Typography component="h2" variant="h3" color="primary">
            ${price}
          </Typography>
        </Box>
        <Typography component="div" color={theme.palette.primary.dark}>
          <Markdown>{description}</Markdown>
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant="contained"
          onClick={handleCreateCheckoutSession}
          sx={{
            fontWeight: "bold",
            backgroundColor: customColors.darkGold.background,
            color: customColors.darkGold.text,
            "&:hover": {
              backgroundColor: customColors.darkGold.hover,
            },
          }}
        >
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );
}
