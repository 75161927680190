import { Button, Card, CardActions, CardContent, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { FormContainer, PasswordElement, TextFieldElement } from "react-hook-form-mui";
import { enqueueSnackbar } from "notistack";
import { useChangeEmailMutation, useChangePasswordMutation } from "../../store/api/profile.api";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useActions } from "../../hooks/useActions";

type PasswordFormProps = {
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
};

type EmailFormProps = {
  password: string;
  email: string;
};

export default function ProfilePage() {
  const { email } = useSelector((state: RootState) => state.auth);
  const { updateEmail } = useActions();

  const [changePassword, { isLoading: isLoadingChangePassword, data: dataChangePassword }] = useChangePasswordMutation();
  const [changeEmail, { isLoading: isLoadingChangeEmail, data: dataChangeEmail }] = useChangeEmailMutation();

  const onSubmit = async (data: PasswordFormProps) => {
    await changePassword({ currentPassword: data.currentPassword, newPassword: data.newPassword, repeatNewPassword: data.repeatNewPassword });
  };

  const onSubmitChangeEmail = async (data: EmailFormProps) => {
    await changeEmail({ currentPassword: data.password, newEmail: data.email });
  };

  useEffect(() => {
    if (dataChangePassword) {
      enqueueSnackbar("Password was successfully changed", { variant: "success" });
    }
  }, [dataChangePassword]);

  useEffect(() => {
    if (dataChangeEmail) {
      updateEmail(dataChangeEmail);
      enqueueSnackbar("Email was successfully changed", { variant: "success" });
    }
  }, [dataChangeEmail, updateEmail]);

  const defaultValuesPassword: PasswordFormProps = { currentPassword: "", newPassword: "", repeatNewPassword: "" };
  const defaultValuesEmail: EmailFormProps = { password: "", email };

  return (
    <>
      <FormContainer defaultValues={defaultValuesPassword} onSuccess={onSubmit}>
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Divider textAlign="left" id="newPasswordDivider" sx={{ mb: 3, fontSize: 14 }}>
              <Typography>Change your password</Typography>
            </Divider>
            <PasswordElement
              margin="normal"
              required
              fullWidth
              name="currentPassword"
              label="Current password"
              type="password"
              id="currentPassword"
              autoComplete="current-password"
              validation={{
                minLength: {
                  value: 6,
                  message: "Minimum 6 symbols",
                },
              }}
              disabled={isLoadingChangePassword}
            />
            <PasswordElement
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New password"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              validation={{
                minLength: {
                  value: 6,
                  message: "Minimum 6 symbols",
                },
                validate: (value: string, formValues: PasswordFormProps) => {
                  return value === formValues.repeatNewPassword ? true : "Passwords do not match";
                },
              }}
              disabled={isLoadingChangePassword}
            />
            <PasswordElement
              margin="normal"
              required
              fullWidth
              name="repeatNewPassword"
              label="Confirm new password"
              type="password"
              id="repeatNewPassword"
              autoComplete="new-password"
              validation={{
                minLength: {
                  value: 6,
                  message: "Minimum 6 symbols",
                },
              }}
              disabled={isLoadingChangePassword}
            />
          </CardContent>
          <CardActions>
            <Button type="submit" variant="contained" sx={{ mb: 2 }} disabled={isLoadingChangePassword}>
              {isLoadingChangePassword ? "Saving..." : "Save changes"}
            </Button>
          </CardActions>
        </Card>
      </FormContainer>
      <FormContainer defaultValues={defaultValuesEmail} onSuccess={onSubmitChangeEmail}>
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Divider textAlign="left" id="newEmailDivider" sx={{ mb: 3, fontSize: 14 }}>
              <Typography>Change your email</Typography>
            </Divider>
            <TextFieldElement
              value={email}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              validation={{
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
              disabled={isLoadingChangeEmail}
            />
            <PasswordElement
              margin="normal"
              required
              fullWidth
              name="password"
              label="Current password"
              type="password"
              id="password"
              autoComplete="new-password"
              validation={{
                minLength: {
                  value: 6,
                  message: "Minimum 6 symbols",
                },
              }}
              disabled={isLoadingChangeEmail}
            />
          </CardContent>
          <CardActions>
            <Button type="submit" variant="contained" sx={{ mb: 2 }} disabled={isLoadingChangeEmail}>
              {isLoadingChangeEmail ? "Saving..." : "Save changes"}
            </Button>
          </CardActions>
        </Card>
      </FormContainer>
    </>
  );
}
