import { Button, Card, CardActions, CardContent, Divider, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import { useSubmitSupportFormMutation } from "../../store/api/profile.api";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

type ContactUsFormProps = {
  message: string;
  email: string;
};

export default function ContactUsPage() {
  const [submitSupportForm, { isLoading, data }] = useSubmitSupportFormMutation();
  const { email } = useSelector((state: RootState) => state.auth);
  const onSubmit = async (data: ContactUsFormProps) => {
    await submitSupportForm({ message: data.message });
  };

  useEffect(() => {
    if (data) {
      enqueueSnackbar("Email to support was sent", { variant: "success" });
    }
  }, [data]);

  const defaultValues: ContactUsFormProps = { message: "", email };
  return (
    <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Divider textAlign="left" id="contactUsDivider" sx={{ mb: 3, fontSize: 14 }}>
            <Typography>Write a message to us</Typography>
          </Divider>
          <TextFieldElement margin="normal" required fullWidth id="name" label="Your Name" name="name" autoComplete="name" />
          <TextFieldElement margin="normal" required fullWidth id="email" label="Your Email" name="email" autoComplete="email" type="email" value={email} />
          <TextFieldElement
            margin="normal"
            required
            multiline
            fullWidth
            rows={6}
            id="message"
            label="Have questions or need to report an issue with our service? We've got you covered."
            name="message"
            autoComplete="message"
          />
        </CardContent>
        <CardActions>
          <Button type="submit" variant="contained" sx={{ mb: 2 }} disabled={isLoading}>
            {isLoading ? "Sending..." : "Send"}
          </Button>
        </CardActions>
      </Card>
    </FormContainer>
  );
}
