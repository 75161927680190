import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Breakpoint } from "@mui/material";

interface AlertDialogProps {
  title?: string;
  content?: string | React.ReactNode;
  buttonOneText?: string;
  buttonOneCallback?: () => void;
  buttonTwoText?: string;
  buttonTwoCallback?: () => void;
  isOpen?: boolean;
  onClose?: () => void;
  renderContent?: (content: string | React.ReactNode) => React.ReactNode;
  maxWidth?: Breakpoint | false;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  title = "",
  content,
  buttonOneText = "Yes",
  buttonOneCallback,
  buttonTwoText = "No",
  buttonTwoCallback,
  isOpen = false,
  onClose,
  renderContent = (content) => {
    if (typeof content === "string") {
      return <DialogContentText id="alert-dialog-description">{content}</DialogContentText>;
    } else {
      return <Box sx={{ mt: 1 }}>{content}</Box>;
    }
  },
  maxWidth,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="alert-dialog-title" maxWidth={maxWidth} fullWidth={maxWidth ? true : false}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{renderContent(content)}</DialogContent>
      <DialogActions>
        <Button onClick={buttonOneCallback}>{buttonOneText}</Button>
        <Button onClick={buttonTwoCallback || onClose} autoFocus>
          {buttonTwoText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
