import { Autocomplete, Box, Divider, Link, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography, styled, useTheme } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useCopyResumeMutation, useDeleteResumeMutation, useGetResumeListQuery, useNewResumeMutation } from "../../store/api/resume.api";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import AlertDialog from "../../components/AlertDialog";
import { useLazyGenerateCvByResumeIdQuery } from "../../store/api/pdf.api";
import Layout from "../../components/layout/Layout";
import { useDrawerItems } from "../../components/DrawerItems";
import {
  useDeleteTailoredResumeMutation,
  useGetApplicationStateListQuery,
  useGetTailoredResumeListQuery,
  useNewTailoredResumeMutation,
  useUpdateApplicationDateMutation,
  useUpdateApplicationStatusMutation,
  useUpdateJobLinkMutation,
  useUpdateNotesMutation,
} from "../../store/api/tailored.api";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useActions } from "../../hooks/useActions";
import { enqueueSnackbar } from "notistack";
import CardButton from "../../components/CardButton";

const columns: GridColDef[] = [
  { field: "title", headerName: "Resume", flex: 2 },
  { field: "jobTitle", headerName: "Job Title", flex: 2 },
  {
    field: "createdAt",
    headerName: "Created",
    flex: 0,
    valueFormatter: (params: GridValueFormatterParams<string>) => {
      if (params.value == null) {
        return "";
      }
      return format(parseISO(params.value), "MM/dd/yy");
    },
  },
  {
    field: "updatedAt",
    flex: 0,
    headerName: "Updated",
    valueFormatter: (params: GridValueFormatterParams<string>) => {
      if (params.value == null) {
        return "";
      }
      return format(parseISO(params.value), "MM/dd/yy");
    },
  },
];

// Define your row model based on the actual data structure
interface RowModel {
  id: string;
  title: string;
  jobTitle: string;
  companyName: string;
  jobLink: string; // Assuming jobLink is a string URL
  applicationState: { id: number; name: string };
  applicationDate: string;
  updatedAt: string;
  createdAt: string;
  notes: string;
}

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path className="ant-empty-img-2" d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Resumes</Box>
    </StyledGridOverlay>
  );
}

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter />
      {/* You can add other toolbar items here but omit the GridToolbarExport */}
    </GridToolbarContainer>
  );
};

export default function Dashboard() {
  const [deletingIetemId, setDeletingIetemId] = useState<null | number>(null);
  const [deletingTIetemId, setDeletingTIetemId] = useState<null | string>(null);
  const [tailoredResumeId, setTailoredResumeId] = useState<null | string>(null);

  const [tailoreDialogOpen, setTailoreDialogOpen] = useState<boolean>(false);
  const [editJobLinkDialogOpen, setEditJobLinkDialogOpen] = useState(false);
  const [currentJobLink, setCurrentJobLink] = useState("");
  const [editNotesDialogOpen, setEditNotesDialogOpen] = useState(false);
  const [currentNotes, setCurrentNotes] = useState("");

  const [copiedResumeData, setCopiedResumeData] = useState<{ resumeId: number; template: string; title: string } | null>(null);

  const [deleteResume] = useDeleteResumeMutation();
  const [deleteTailoredResume] = useDeleteTailoredResumeMutation();

  const [newResume, { data: newResumeData }] = useNewResumeMutation();
  const [updateJobLinkMutation] = useUpdateJobLinkMutation();
  const [updateNotesMutation] = useUpdateNotesMutation();
  const [updateApplicationDateMutation] = useUpdateApplicationDateMutation();
  const [updateApplicationStatusMutation, { isLoading: applicationStatusUpdateIsLoading }] = useUpdateApplicationStatusMutation();

  const [newTailoredResume, { data: newTailoredResumeData }] = useNewTailoredResumeMutation();
  const navigate = useNavigate();
  const drawerItems = useDrawerItems();

  const { data: resumeList, isSuccess } = useGetResumeListQuery();
  const { data: tailoredResumeList, isSuccess: isTailoredSuccess } = useGetTailoredResumeListQuery();
  const { data: applicationStateList, isSuccess: isApplicationStateList } = useGetApplicationStateListQuery();

  const { setTableSort, addSelectedResume } = useActions();
  const sortState = useSelector((state: RootState) => state.settings.tableSorts);
  const lastSelectedResumes = useSelector((state: RootState) => state.settings.lastSelectedResumes);

  interface ResumeOption {
    label: string;
    id: number;
  }

  // State to manage the currently selected resume object for defaultValue
  const [selectedResume, setSelectedResume] = useState<ResumeOption | null>(null);

  useEffect(() => {
    if (!resumeList) return;
    // If there are last selected resumes, find the most recent one in resumeList
    if (lastSelectedResumes.length > 0) {
      const mostRecentResumeId = lastSelectedResumes[0]; // Get the most recent resume ID
      const mostRecentResume = resumeList.find((resume) => resume.id === mostRecentResumeId);
      if (mostRecentResume) {
        setSelectedResume({ label: mostRecentResume.title, id: mostRecentResume.id }); // Set the resume object
      } else {
        setSelectedResume(null); // Explicitly setting to null if no last selected resume exists
      }
    }
  }, [lastSelectedResumes, resumeList]); // Depend on lastSelectedResumes and resumeList

  const handleSortModelChange = (sortModel: any, tableName: "resumesTable" | "tailoredResumesTable") => {
    // Check if sortModel is empty and if the current state already indicates no sorting
    if (sortModel.length === 0 && !sortState[tableName].field && !sortState[tableName].direction) {
      // State is already in the desired "no sort" state, so do nothing
      return;
    } else if (sortModel.length > 0) {
      // Proceed with updating the state as usual
      const { field, sort } = sortModel[0];
      setTableSort({ tableName, sortField: field, sortDirection: sort });
    } else {
      // Here, sortModel is empty, and we need to reset the sort state
      setTableSort({ tableName, sortField: "", sortDirection: null });
    }
  };

  function updateFiledValue(fieldId: any, value: any, id: any) {
    if (fieldId === "jobLink") updateJobLinkMutation({ id, value });
    if (fieldId === "notes") updateNotesMutation({ id, value });
    if (fieldId === "applicationDate") updateApplicationDateMutation({ id, value });
    if (fieldId === "applicationStatus") updateApplicationStatusMutation({ id, value });
    // dispatch(cvtApi.util.invalidateTags([{ type: "TailoredResume", id: resumeData.id }]));
    // dispatch(
    //   cvtApi.util.updateQueryData("getTailoredResumeById", resumeId, (draft: ITailoredResume) => {
    //     if (fieldId in draft) draft[fieldId as keyof ITailoredResume] = value;
    //   })
    // );
  }

  const renderStatusSelect = (params: GridRenderCellParams<RowModel>) =>
    isApplicationStateList && (
      <Select
        disabled={applicationStatusUpdateIsLoading}
        color="primary"
        fullWidth
        value={params.value.name || ""}
        size="small"
        sx={{
          ".MuiSelect-select": {
            color: "primary.dark", // Style for the selected value text
            px: 1, // shorthand for padding left and right
          },
        }}
        onChange={(event: SelectChangeEvent) => updateFiledValue("applicationStatus", event.target.value, params.row.id)}
      >
        {applicationStateList.map((state: any) => (
          <MenuItem key={state.id} value={state.name} sx={{ color: "primary.dark" }}>
            {state.name}
          </MenuItem>
        ))}
      </Select>
    );

  const renderJobLinkCell = (params: GridRenderCellParams<RowModel>) => {
    const handleLinkClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation(); // Prevent triggering row click
      const jobLink = params.row.jobLink;
      if (jobLink) {
        window.open(jobLink, "_blank");
      }
    };
    const handleEditIconClick = (jobLink: string) => {
      setTailoredResumeId(params.row.id);
      setCurrentJobLink(jobLink);
      setEditJobLinkDialogOpen(true);
    };

    return (
      <Box display={"flex"} gap={1}>
        {params.row.jobLink && (
          <Tooltip title={<Typography sx={{ fontSize: "1rem" }}>{params.row.jobLink}</Typography>}>
            <Link onClick={handleLinkClick} style={{ cursor: "pointer", textDecoration: "underline" }}>
              <Typography variant="h5">Link</Typography>
            </Link>
          </Tooltip>
        )}
        <EditIcon
          onClick={() => handleEditIconClick(params.row.jobLink)}
          style={{ cursor: "pointer", marginRight: 8 }}
          fontSize={"small"}
          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
        />
      </Box>
    );
  };

  const renderNotesCell = (params: GridRenderCellParams<RowModel>) => {
    const tooltipTitle = (
      <Typography variant="h5" sx={{ whiteSpace: "pre-line" }}>
        {params.row.notes}
      </Typography>
    );

    const handleEditIconClick = (notes: string) => {
      setTailoredResumeId(params.row.id);
      setCurrentNotes(notes);
      setEditNotesDialogOpen(true);
    };

    if (!params.row.notes) {
      // Render EditIcon if notes are empty, null, or undefined
      return (
        <EditIcon
          onClick={() => handleEditIconClick(params.row.notes)}
          style={{ cursor: "pointer" }}
          fontSize={"small"}
          sx={{ color: "rgba(0, 0, 0, 0.54)" }}
        />
      );
    } else {
      // Render "Notes" text with tooltip if notes exist
      return (
        <Tooltip title={tooltipTitle}>
          <Typography style={{ cursor: "pointer", textDecoration: "underline" }} variant="h5" onClick={() => handleEditIconClick(params.row.notes)}>
            Notes
          </Typography>
        </Tooltip>
      );
    }
  };

  const renderDatePicker = (params: GridRenderCellParams<RowModel>) => {
    const handleDateChange = (newValue: Date | null) => {
      updateFiledValue("applicationDate", newValue, params.row.id);
    };
    // const applicationDate = "2023-12-19T08:00:00.000Z";
    // const parsedDate = parseISO(applicationDate);
    const defaultDate = parseISO(params.row.createdAt);
    const parsedDate = params.value ? parseISO(params.value) : defaultDate;
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          // value={params.value}
          value={parsedDate}
          onChange={handleDateChange}
          format="MM/dd/yy"
          slotProps={{
            textField: {
              size: "small",
              sx: {
                "& .MuiInputBase-input": {
                  color: "primary.dark", // Apply primary.dark color to the input text
                  px: 1,
                  pr: 0,
                },
                "& .MuiButtonBase-root": {
                  p: 0.5,
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    );
  };

  const tailoreDcolumns: GridColDef[] = [
    { field: "title", headerName: "Resume", flex: 2 },
    { field: "jobTitle", headerName: "Job Title", flex: 2 },
    { field: "companyName", headerName: "Company", flex: 1.4 },
    { field: "jobLink", headerName: "Job Link", flex: 0, renderCell: renderJobLinkCell, align: "center", sortable: false },
    {
      field: "applicationState",
      flex: 1.7,
      headerName: "Status",
      renderCell: renderStatusSelect,
      valueFormatter: (params: GridValueFormatterParams<{ id: number; name: string }>) => {
        if (params.value == null) {
          return "";
        }
        return params.value.name;
      },
      editable: false, // Disable default editing
      sortComparator: (v1, v2) => {
        return v1.sortOrder - v2.sortOrder;
      },
    },
    {
      field: "applicationDate",
      flex: 1.1,
      headerName: "Applied",
      editable: false, // Disable default editing
      renderCell: renderDatePicker,
    },
    {
      field: "updatedAt",
      flex: 0,
      headerName: "Updated",
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value == null) {
          return "";
        }
        return format(parseISO(params.value), "MM/dd/yy");
      },
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 0.5,
      align: "center",
      renderCell: renderNotesCell,
    },
  ];

  const [copyResume, { isLoading: isLoadingCopy, data: copyResumeData }] = useCopyResumeMutation();

  const [generatePdfById, { data: dataGenerateCv }] = useLazyGenerateCvByResumeIdQuery();

  const handleCopyResume = async (id: string | null) => {
    if (id) {
      await copyResume(id);
    }
  };

  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    if (dataGenerateCv) {
      fetch(dataGenerateCv.link, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", dataGenerateCv.fileName);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          if (link.parentNode) link.parentNode.removeChild(link);
        });
    }
  }, [dataGenerateCv, token]);

  async function newResumeHandler(type: string) {
    await newResume(type);
  }

  useEffect(() => {
    if (newResumeData) navigate(`/resume/${newResumeData.resumeId}`, { state: { template: newResumeData.template, title: newResumeData.title } });
  }, [newResumeData, navigate]);

  useEffect(() => {
    if (newTailoredResumeData) navigate(`/tailoredResume/${newTailoredResumeData.resumeId}`, { state: { title: newTailoredResumeData.title } });
  }, [newTailoredResumeData, navigate]);

  useEffect(() => {
    if (copyResumeData) setCopiedResumeData(copyResumeData);
  }, [copyResumeData]);

  const handleDeleteResume = async (id: number | null) => {
    if (id) {
      await deleteResume(id);
      setDeletingIetemId(null);
      if (selectedResume && selectedResume.id === id) setSelectedResume(null);
    }
  };

  const handleDeleteTailoredResume = async (id: string | null) => {
    if (id) {
      await deleteTailoredResume(id);
      setDeletingTIetemId(null);
    }
  };
  // Inside your component
  const theme = useTheme();

  return (
    <>
      <Layout
        title={"Dashboard"}
        drawerItems={drawerItems}
        leftContent={
          <Box sx={{ mx: 5 }}>
            <Box display={"flex"} flexDirection={"row"} gap={3} justifyContent="center" alignItems="center">
              <CardButton
                onClick={() => newResumeHandler("sales")}
                icon={InsertDriveFileOutlinedIcon}
                text="New Sales Resume"
                subtext="Create a resume tailored for sales roles"
              />
              <CardButton
                onClick={() => newResumeHandler("tech")}
                icon={InsertDriveFileOutlinedIcon}
                text="New Tech Resume"
                subtext="Create a resume tailored for tech roles"
              />
              <CardButton
                onClick={() => setTailoreDialogOpen(true)}
                icon={FileCopyOutlinedIcon}
                text="Optimized Resume"
                subtext="Customize your resume for job offers"
              />
            </Box>

            <Divider sx={{ mt: 3, mb: 3 }} textAlign="left">
              <Typography variant="h4" color={"primary.dark"}>
                My basic resumes:
              </Typography>
            </Divider>

            <DataGrid
              sortModel={
                sortState && sortState.resumesTable && sortState.resumesTable.field && sortState.resumesTable.direction
                  ? [
                      {
                        field: sortState.resumesTable.field,
                        sort: sortState.resumesTable.direction,
                      },
                    ]
                  : []
              }
              onSortModelChange={(sortModel) => {
                handleSortModelChange(sortModel, "resumesTable");
              }}
              autoHeight
              sx={{
                width: "100%",
                // disable cell selection style
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none !important",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
                // Add borders to the cell and header
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  color: theme.palette.primary.dark, // This will use the theme's primary main color
                  fontSize: "14px",
                },
                "& .MuiDataGrid-columnHeader": {
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                },
                // Remove the right border from the last column header and the bottom border from the last cell of each row
                "& .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-child": {
                  borderRight: "none",
                },
                "& .MuiDataGrid-row .MuiDataGrid-cell:last-of-type": {
                  borderRight: "none",
                },
                "& .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                // Add border to the bottom of the headers
                "& .MuiDataGrid-columnHeaders": {
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  backgroundColor: theme.palette.secondary.light, // Use the primary color from the theme
                  color: theme.palette.primary.dark, // This will use the theme's primary main color
                  fontSize: "16px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold", // This will make the header text bold
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              columns={[
                ...columns,
                {
                  field: "actions",
                  type: "actions",
                  headerName: "Actions",
                  getActions: (params: any) => [
                    <GridActionsCellItem
                      icon={
                        <Tooltip title="Delete">
                          <DeleteIcon />
                        </Tooltip>
                      }
                      label="Delete"
                      onClick={() => setDeletingIetemId(params.id)}
                    />,
                    <GridActionsCellItem
                      icon={
                        <Tooltip title="Download PDF">
                          <DownloadIcon />
                        </Tooltip>
                      }
                      label="Download PDF"
                      onClick={() => generatePdfById({ id: params.id, docType: "pdf" })}
                    />,
                    <GridActionsCellItem
                      icon={
                        <Tooltip title="Copy Resume">
                          <ContentCopyIcon />
                        </Tooltip>
                      }
                      label="Copy Resume"
                      onClick={() => handleCopyResume(params.id)}
                      disabled={isLoadingCopy}
                    />,
                  ],
                },
              ]}
              slots={{ toolbar: CustomToolbar, noRowsOverlay: CustomNoRowsOverlay }}
              rows={resumeList || []}
              onRowClick={(params) => {
                navigate(`/resume/${params.row.id}`, { state: { template: params.row.resumeTemplate.name, title: params.row.title } });
              }}
              loading={!isSuccess}
            />

            <Divider sx={{ mt: 3, mb: 3 }} textAlign="left">
              <Typography variant="h4" color={"primary.dark"}>
                My optimized resumes:
              </Typography>
            </Divider>

            <DataGrid
              sortModel={
                sortState && sortState.tailoredResumesTable && sortState.tailoredResumesTable.field && sortState.tailoredResumesTable.direction
                  ? [
                      {
                        field: sortState.tailoredResumesTable.field,
                        sort: sortState.tailoredResumesTable.direction,
                      },
                    ]
                  : []
              }
              onSortModelChange={(sortModel) => {
                handleSortModelChange(sortModel, "tailoredResumesTable");
              }}
              autoHeight
              sx={{
                width: "100%",
                // disable cell selection style
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none !important",
                },
                // Pointer cursor only on the first three cells of every row
                "& .MuiDataGrid-row .MuiDataGrid-cell:nth-of-type(1):hover, & .MuiDataGrid-row .MuiDataGrid-cell:nth-of-type(2):hover, & .MuiDataGrid-row .MuiDataGrid-cell:nth-of-type(3):hover":
                  {
                    cursor: "pointer",
                  },
                // Add borders to the cell and header
                "& .MuiDataGrid-cell": {
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  color: theme.palette.primary.dark, // This will use the theme's primary main color
                  fontSize: "14px",
                },
                "& .MuiDataGrid-columnHeader": {
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                },
                // Remove the right border from the last column header and the bottom border from the last cell of each row
                "& .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-child": {
                  borderRight: "none",
                },
                "& .MuiDataGrid-row .MuiDataGrid-cell:last-of-type": {
                  borderRight: "none",
                },
                "& .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                // Add border to the bottom of the headers
                "& .MuiDataGrid-columnHeaders": {
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  backgroundColor: theme.palette.secondary.light, // Use the primary color from the theme
                  color: theme.palette.primary.dark, // This will use the theme's primary main color
                  fontSize: "16px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold", // This will make the header text bold
                },
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 30,
                  },
                },
              }}
              pageSizeOptions={[10, 30, 50]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              columns={[
                ...tailoreDcolumns,
                {
                  field: "actions",
                  type: "actions",
                  headerName: "Actions",
                  getActions: (params: any) => [
                    <GridActionsCellItem
                      icon={
                        <Tooltip title="Delete">
                          <DeleteIcon />
                        </Tooltip>
                      }
                      label="Delete"
                      onClick={() => setDeletingTIetemId(params.id)}
                    />,
                    <GridActionsCellItem
                      icon={
                        <Tooltip title="Download PDF">
                          <DownloadIcon />
                        </Tooltip>
                      }
                      label="Download PDF"
                      onClick={() => generatePdfById({ id: params.row.customizedResumeId, docType: "pdf" })}
                    />,
                    <GridActionsCellItem
                      icon={
                        <Tooltip title="Copy to basic resumes">
                          <NorthWestIcon />
                        </Tooltip>
                      }
                      label="Copy to based resume"
                      onClick={() => handleCopyResume(params.row.customizedResumeId)}
                      disabled={isLoadingCopy}
                    />,
                  ],
                },
              ]}
              slots={{ toolbar: CustomToolbar, noRowsOverlay: CustomNoRowsOverlay }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              rows={tailoredResumeList || []}
              onRowClick={(params, event) => {
                // Get the DOM element where the click event originated
                const target = event.target as HTMLElement;

                // Find the cell element that was clicked
                const cellElement = target.closest(".MuiDataGrid-cell");

                if (cellElement) {
                  // Get the data-field attribute, which indicates the field name of the column
                  const field = cellElement.getAttribute("data-field");

                  // Define the fields of the first three columns
                  const clickableColumns = ["title", "jobTitle", "companyName"]; // Replace with your actual column field names

                  // Check if the clicked column is one of the first three columns
                  if (field && clickableColumns.includes(field)) {
                    navigate(`/tailoredResume/${params.row.id}`, { state: { title: params.row.title } });
                  }
                }
              }}
              loading={!isTailoredSuccess}
            />

            <AlertDialog
              title="Do you want to delete this resume?"
              buttonOneCallback={() => handleDeleteResume(deletingIetemId)}
              onClose={() => setDeletingIetemId(null)}
              isOpen={!!deletingIetemId}
            />
            <AlertDialog
              title={`Do you want to open "${copiedResumeData?.title}"?`}
              buttonOneCallback={() => {
                if (copiedResumeData) {
                  navigate(`/resume/${copiedResumeData.resumeId}`, {
                    state: {
                      template: copiedResumeData.template,
                      title: copiedResumeData.title,
                    },
                  });
                }
              }}
              onClose={() => setCopiedResumeData(null)}
              isOpen={!!copiedResumeData}
            />
            <AlertDialog
              title="Do you want to delete this resume?"
              buttonOneCallback={() => handleDeleteTailoredResume(deletingTIetemId)}
              onClose={() => setDeletingTIetemId(null)}
              isOpen={!!deletingTIetemId}
            />
            <AlertDialog
              title="Create tailored Resume"
              buttonOneCallback={() => {
                if (!selectedResume) {
                  enqueueSnackbar("Basic resume is not selected", { variant: "error" });
                } else {
                  newTailoredResume(selectedResume.id);
                }
              }}
              onClose={() => setTailoreDialogOpen(false)}
              isOpen={tailoreDialogOpen}
              buttonOneText="Create"
              buttonTwoText="Cancel"
              maxWidth="sm"
              content={
                <Autocomplete
                  sx={{ my: 5 }}
                  disablePortal
                  id="base-resume"
                  value={selectedResume}
                  options={
                    resumeList
                      ? resumeList.map((resume) => {
                          return { label: resume.title, id: resume.id };
                        })
                      : []
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={`${option.label}-${option.id}`}>
                      {option.label}
                    </li>
                  )}
                  fullWidth
                  renderInput={(params) => <TextField {...params} label="Search basic resumes.." />}
                  loading={!isSuccess}
                  onChange={(event: any, newValue: any, reason: any) => {
                    if (newValue) {
                      addSelectedResume(newValue.id);
                    } else {
                      setSelectedResume(null);
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                />
              }
            />
            <AlertDialog
              title="Edit Job Link"
              // buttonOneCallback={() => handleJobLinkChange(currentJobLink)}
              onClose={() => setEditJobLinkDialogOpen(false)}
              buttonOneCallback={() => {
                updateFiledValue("jobLink", currentJobLink, tailoredResumeId);
                setEditJobLinkDialogOpen(false);
              }}
              isOpen={editJobLinkDialogOpen}
              buttonOneText="Save"
              buttonTwoText="Cancel"
              maxWidth="sm"
              content={<TextField label="Job Link" fullWidth value={currentJobLink} onChange={(e) => setCurrentJobLink(e.target.value)} />}
            />
            <AlertDialog
              title="Edit Notes"
              // buttonOneCallback={() => handleJobLinkChange(currentJobLink)}
              onClose={() => setEditNotesDialogOpen(false)}
              buttonOneCallback={() => {
                updateFiledValue("notes", currentNotes, tailoredResumeId);
                setEditNotesDialogOpen(false);
              }}
              isOpen={editNotesDialogOpen}
              buttonOneText="Save"
              buttonTwoText="Cancel"
              maxWidth="sm"
              content={<TextField multiline maxRows={20} label="Notes" fullWidth value={currentNotes} onChange={(e) => setCurrentNotes(e.target.value)} />}
            />
          </Box>
        }
      />
    </>
  );
}
