export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Strips Markdown bold syntax from the given text.
 * @param markdownText The text containing Markdown bold syntax.
 * @returns The text with Markdown bold syntax removed.
 */
export const stripMarkdown = (markdownText: string): string => {
  const boldSyntaxRegex = /\*\*(.*?)\*\*/g;
  return markdownText.replace(boldSyntaxRegex, "$1");
};
