import Box from "@mui/material/Box";
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Controls } from "../../../components/controls/Controls";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  cvApi,
  useGetRephraseSummaryMutation,
  useGetResumeByIdQuery,
  useGetSummaryMutation,
  useLazyGetSkillsQuery,
  useUpdateResumeMutation,
} from "../../../store/api/resume.api";
import { useAppDispatch } from "../../../store/hooks";
import { IResume, ISkill } from "../../../models/models";
import { useLocation, useParams } from "react-router-dom";
import WorkExperience from "../workExperience/WorkExperience";
import Education from "../education/Education";
import { useGenerateCvMutation } from "../../../store/api/pdf.api";
import Layout from "../../../components/layout/Layout";
import LoaderComponent from "../../../components/LoaderComponent";
import Preview from "../preview/Preview";
import { useDrawerItems } from "../../../components/DrawerItems";
import AppBarButtons from "../../../components/AppBarButtons";
import EditableTitle from "../../../components/EditableTitle";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import useBeforeUnload from "../../../hooks/useBeforeUnload";
import Certificate from "../certificates/Certificate";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import CheckIcon from "@mui/icons-material/Check";
import Skills from "../../../components/Skills";
import TextArea from "../../../components/TextArea";
import ChatApp from "../../../components/chat/ChatApp";
import ResumeDocument from "../preview/ResumeDocument";
import StateSelect from "../../../components/StateSelect";
import Industry from "../../../components/Industry";

export default function SalesResumePage(props: { title: string }) {
  const token = useSelector((state: RootState) => state.auth.token);
  let { resumeId } = useParams() as { resumeId: string };
  const { title } = props;

  const drawerItems = useDrawerItems();

  const { data: resumeData } = useGetResumeByIdQuery(resumeId);
  const [updateResume, { isLoading: isLoadingUpdateResume }] = useUpdateResumeMutation();
  const dispatch = useAppDispatch();

  function updateFieldValue<K extends keyof IResume>(fieldId: K, value: IResume[K]) {
    dispatch(
      cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
        draft[fieldId] = value;
      })
    );
  }

  const location = useLocation();
  const isLeavingPage = useRef(false);
  const currentResumeData = useRef(resumeData);

  useEffect(() => {
    currentResumeData.current = resumeData;
  }, [resumeData]);

  const handleUpdateResume = async () => {
    if (currentResumeData.current) {
      await updateResume(currentResumeData.current);
    }
    isLeavingPage.current = false;
  };

  const handleRouteChange = useCallback(() => {
    if (currentResumeData.current && isLeavingPage.current) {
      updateResume(currentResumeData.current);
    }
  }, [updateResume]); // Add dependencies if any

  const handleUnload = () => {
    if (resumeData) {
      updateResume(resumeData);
    }
  };

  useBeforeUnload(handleUnload); // Use the custom hook

  useEffect(() => {
    return () => {
      isLeavingPage.current = true;
      handleRouteChange();
    };
  }, [location.pathname, handleRouteChange]); // Add handleUnloadOrRouteChange to dependencies

  // const [jobTitleChanged, setJobTitleChanged] = useState(false);

  const handleChangeUsState = (event: any, newValue: any, reason: any) => {
    if (newValue) {
      updateFieldValue("state", newValue.code);
    } else {
      updateFieldValue("state", undefined);
    }
  };

  const handleChangeTargetLevel = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    updateFieldValue("expLevel", typeof value === "string" ? value : value.join(";"));
  };

  const [getSummary, { data, isLoading }] = useGetSummaryMutation();

  useEffect(() => {
    if (data) {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
          draft.summary = data;
        })
      );
    }
  }, [data, dispatch, resumeId]);

  const handleGenerateSummaryClick = async () => {
    await getSummary(resumeData!);
  };

  const [rephraseSummary, { data: rephraseSummaryData, isLoading: rephraseSummaryIsLoading }] = useGetRephraseSummaryMutation();

  useEffect(() => {
    if (rephraseSummaryData) {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
          draft.summary = rephraseSummaryData;
        })
      );
    }
  }, [dispatch, rephraseSummaryData, resumeId]);

  const handleRephraseSummaryClick = async () => {
    await rephraseSummary(resumeData!);
  };

  const [generateCv, { data: dataGenerateCv, isLoading: isLoadingGenerateCv }] = useGenerateCvMutation();

  const downloadDOCXHandle = async () => {
    resumeData && (await generateCv({ resumeData: resumeData, docType: "docx" }));
  };

  useEffect(() => {
    if (dataGenerateCv) {
      fetch(dataGenerateCv.link, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", dataGenerateCv.fileName);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          if (link.parentNode) link.parentNode.removeChild(link);
        });
    }
  }, [dataGenerateCv, token]);

  function handleTextFieldOnBlur(e: React.FocusEvent<HTMLInputElement>) {
    const fieldId = e.target.id as keyof IResume;
    let fieldValue = e.target.value;

    fieldValue = processFieldValue(fieldId, fieldValue);

    updateFieldValue(fieldId, fieldValue);
  }

  function handleTextFieldOnChange(e: ChangeEvent<HTMLInputElement>) {
    const fieldId = e.currentTarget.id as keyof IResume;
    let fieldValue = e.currentTarget.value;

    fieldValue = processFieldValue(fieldId, fieldValue);

    updateFieldValue(fieldId, fieldValue);
  }

  function processFieldValue(fieldId: string, fieldValue: string): string {
    if (fieldId === "totalYears" && fieldValue.match(/[^0-9]/)) {
      return fieldValue; // Return the original value if it contains non-numeric characters
    }
    if (fieldId === "city") {
      return capitalizeFirstLetter(fieldValue);
    }
    if (fieldId === "linkedInProfileLink" && !/^https?:\/\//i.test(fieldValue)) {
      return "https://" + fieldValue;
    }
    // Additional processing can be added here as needed
    return fieldValue;
  }

  const handleDeleteSkillFromResume = useCallback(
    (skillName: string) => {
      dispatch(
        cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
          draft.skills = draft.skills.filter((skill: ISkill) => skill.name !== skillName);
        })
      );
      // Optionally, add the skill back to a pool of available skills if needed
    },
    [dispatch, resumeId]
  );

  const handleAddSkillToResume = useCallback(
    (skillName: string, skillType: string) => {
      if (!resumeData) return;
      const skillExists = resumeData.skills.some((skill) => skill.name.toLowerCase() === skillName.toLowerCase());
      if (!skillExists) {
        dispatch(
          cvApi.util.updateQueryData("getResumeById", resumeId, (draft) => {
            draft.skills.push({
              name: capitalizeFirstLetter(skillName),
              resumeId: Number(resumeId),
              type: skillType,
            });
          })
        );
      }
    },
    [dispatch, resumeData, resumeId]
  );

  const [getSkills] = useLazyGetSkillsQuery();

  const [generatedSkills, setGeneratedSkills] = useState<{ Skill: string[]; Tech: string[] }>({
    Skill: [],
    Tech: [],
  });
  const [isFetching, setIsFetching] = useState<{ Skill: boolean; Tech: boolean }>({
    Skill: false,
    Tech: false,
  });

  const generateSkillSet = async (skillType: "Skill" | "Tech") => {
    setIsFetching((prev) => ({ ...prev, [skillType]: true }));
    try {
      if (!resumeData) return;
      // Assuming getSkills is an async operation; adapt based on your actual API call
      const skillsData = await getSkills({
        id: resumeData.id,
        resumeTemplateId: resumeData.resumeTemplateId,
        jobTitle: resumeData.jobTitle,
        totalYears: resumeData.totalYears,
        relevantYears: resumeData.relevantYears,
        skills: resumeData.skills,
        type: skillType,
      }).unwrap(); // RTK Query's .unwrap() method will return the payload in case of success

      setGeneratedSkills((prev) => ({ ...prev, [skillType]: skillsData }));
    } catch (error) {
    } finally {
      setIsFetching((prev) => ({ ...prev, [skillType]: false }));
    }
  };

  return (
    <>
      {!resumeData ? (
        // Render a loader or spinner here
        <Layout drawerItems={drawerItems} leftContent={<LoaderComponent />} title={title} />
      ) : (
        // Render your content when data is loaded
        <Layout
          title={<EditableTitle title={resumeData.title} onChange={handleTextFieldOnChange} onBlur={handleTextFieldOnBlur} />}
          drawerItems={drawerItems}
          leftContent={
            <>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="basicInfo" sx={{ mb: 3, fontSize: 14 }}>
                    <Typography>Basic information</Typography>
                  </Divider>
                  <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
                    <TextField required fullWidth id="firstName" label="First name" onChange={handleTextFieldOnChange} value={resumeData.firstName} />
                    <TextField required fullWidth id="lastName" label="Last name" onChange={handleTextFieldOnChange} value={resumeData.lastName} />
                  </Box>
                  <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
                    <TextField required sx={{ flexGrow: 2 }} id="city" label="City" onChange={handleTextFieldOnChange} value={resumeData.city} fullWidth />
                    <StateSelect handleChange={handleChangeUsState} currentValue={resumeData.state} id="basicInfoState" label="State" width="50%" />
                  </Box>
                  <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      type="email"
                      onChange={handleTextFieldOnChange}
                      value={resumeData.email}
                      autoComplete="email"
                    />
                    <TextField
                      required
                      fullWidth
                      id="phone"
                      label="Phone"
                      type="tel"
                      onChange={handleTextFieldOnChange}
                      value={resumeData.phone}
                      autoComplete="phone"
                    />
                  </Box>
                  <TextField
                    id="linkedInProfileLink"
                    label="LinkedIn profile link"
                    sx={{ mb: 3 }}
                    fullWidth
                    onChange={handleTextFieldOnChange}
                    value={resumeData.linkedInProfileLink}
                  />
                  <TextField
                    required
                    id="jobTitle"
                    label="Your targeted role"
                    sx={{ mb: 3 }}
                    fullWidth
                    onChange={handleTextFieldOnChange}
                    // onBlur={generateSkillsSet}
                    value={resumeData.jobTitle}
                  />
                  <Box display={"flex"} flexDirection={"row"} sx={{ mb: resumeData.industries.length > 4 ? 0 : 1 }}>
                    <Industry resumeId={resumeId} resumeData={resumeData} />
                  </Box>
                  <Box sx={{ mb: resumeData.industries.length > 4 ? 1 : 0 }}>
                    <Typography variant="caption" style={{ whiteSpace: "pre-wrap" }} color={"red"}>
                      {resumeData.industries.length > 4 ? "Maximum of 5 industries reached in this field." : " "}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexDirection={"row"} gap={1} mb={3}>
                    <FormControl fullWidth>
                      <InputLabel id="targetLevelLabel">Target market</InputLabel>
                      <Select
                        multiple
                        labelId="targetLevelLabel"
                        id="targetLevelSelect"
                        value={resumeData.expLevel.split(";")}
                        label="Target market"
                        onChange={handleChangeTargetLevel}
                        renderValue={(selected) => {
                          // Filter out empty strings
                          const filteredSelected = selected.filter((value) => value.trim() !== "");

                          return filteredSelected.length > 0 ? filteredSelected.join(", ") : <span style={{ color: "#6B6B6B" }}>Select target market</span>;
                        }}
                      >
                        {["SMB", "Mid-market", "Enterprise", "Startup", "B2B", "B2C"].map((option) => (
                          <MenuItem key={option} value={option}>
                            <CheckIcon
                              color="primary"
                              style={{ visibility: resumeData.expLevel.split(";").includes(option) ? "visible" : "hidden" }}
                              sx={{ mr: 1 }}
                            />
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      id="totalYears"
                      label="Total years of experience"
                      inputProps={{ min: 0, inputMode: "numeric" }}
                      onChange={handleTextFieldOnChange}
                      value={resumeData.totalYears}
                    />
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="skills">
                    <Typography sx={{ mb: 1 }}>Skills</Typography>
                  </Divider>
                  <Alert severity="info" sx={{ mb: 3 }}>
                    <Typography>
                      Please select all the skills relevant to your experience. This field serves as keywords for your resume. Recruiters and HR will use these
                      keywords to search for you, and they will be used for resume generation. Please take 5 minutes to carefully choose your skills. Select up
                      to
                      {10} skills.
                    </Typography>
                  </Alert>
                  <Skills
                    parentId={Number(resumeId)}
                    skillType="Skill"
                    maxSkills={10}
                    initialSkillSet={generatedSkills.Skill}
                    resumeOrWorkExperienceskillSet={resumeData.skills}
                    regenerateWithAi={true}
                    generateSkillSet={() => generateSkillSet("Skill")}
                    isFetching={isFetching.Skill}
                    onSkillDelete={handleDeleteSkillFromResume}
                    onSkillAdd={handleAddSkillToResume}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="skills">
                    <Typography sx={{ mb: 1 }}>Technologies</Typography>
                  </Divider>
                  <Skills
                    parentId={Number(resumeId)}
                    skillType="Tech"
                    maxSkills={10}
                    initialSkillSet={generatedSkills.Tech}
                    resumeOrWorkExperienceskillSet={resumeData.skills}
                    regenerateWithAi={true}
                    generateSkillSet={() => generateSkillSet("Tech")}
                    isFetching={isFetching.Tech}
                    onSkillDelete={handleDeleteSkillFromResume}
                    onSkillAdd={handleAddSkillToResume}
                  />
                </CardContent>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="summaryDivider" sx={{ mb: 3 }}>
                    <Typography>Summary</Typography>
                  </Divider>
                  <TextArea
                    id="summary"
                    label="Summary will be generated with AI. You can edit it after the generation"
                    rows={6}
                    onChange={handleTextFieldOnChange}
                    value={resumeData.summary}
                  />
                </CardContent>
                <CardActions>
                  {/* <FormControlLabel control={<Switch defaultChecked />} label="Regenerate with AI" /> */}
                  <Box sx={{ mb: 2, mr: 2 }}>
                    <Controls.Button
                      text={isLoading ? "Generating..." : resumeData.summary === "" ? "Generate with AI" : "Regenerate with AI"}
                      onClick={handleGenerateSummaryClick}
                      disabled={isLoading || rephraseSummaryIsLoading}
                      isLoading={isLoading}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Controls.Button
                      text={rephraseSummaryIsLoading ? "Generating..." : "Rephrase with AI"}
                      onClick={handleRephraseSummaryClick}
                      disabled={isLoading || rephraseSummaryIsLoading}
                      isLoading={rephraseSummaryIsLoading}
                    />
                  </Box>
                </CardActions>
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="experience" sx={{ mb: 3 }}>
                    <Typography>Experience</Typography>
                  </Divider>

                  <WorkExperience
                    resumeId={resumeId}
                    initialSkills={resumeData.skills}
                    workExperiences={resumeData.workExperiences}
                    relevantYears={resumeData.relevantYears}
                    expLevel={resumeData.expLevel}
                    jobTitle={resumeData.jobTitle}
                    resumeTemplateId={resumeData.resumeTemplateId}
                  />
                </CardContent>
                {/* <CardActions>
                <FormControlLabel control={<Switch defaultChecked />} label="Regenerate bullets with AI" />
              </CardActions> */}
              </Card>
              <Card sx={{ mb: 3 }}>
                <CardContent>
                  <Divider textAlign="left" id="education" sx={{ mb: 3 }}>
                    <Typography>Education</Typography>
                  </Divider>
                  <Education resumeData={resumeData} />
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Divider textAlign="left" id="certificates" sx={{ mb: 3 }}>
                    <Typography>Certificates</Typography>
                  </Divider>
                  <Certificate resumeData={resumeData} />
                </CardContent>
              </Card>
            </>
          }
          rightContent={
            <>
              <Preview resumeData={resumeData} />
              <ChatApp />
            </>
          }
          appBarButtons={
            <AppBarButtons
              isLoadingGenerateCv={isLoadingGenerateCv}
              document={<ResumeDocument resumeData={resumeData} />}
              downloadDOCXHandle={downloadDOCXHandle}
              isLoadingUpdateResume={isLoadingUpdateResume}
              updateResume={handleUpdateResume}
              resumeData={resumeData}
              dispatch={dispatch}
            />
          }
        />
      )}
    </>
  );
}
