// Layout.tsx
import React, { useEffect, useRef, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBarComponent from "./AppBarComponent";
import DrawerComponent from "./DrawerComponent";
import MainContent from "./MainContent";
import { DrawerItemProps } from "../../models/models";
import { useActions } from "../../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

/**
 * Props for the Layout component.
 * @param {React.ReactNode} leftContent - Content for the left side of the MainContent area.
 * @param {React.ReactNode} rightContent - Content for the right side of the MainContent area.
 * @param {React.CSSProperties} [style] - Optional custom styles for the layout.
 */

interface LayoutProps {
  title: string | React.ReactNode;
  appBarButtons?: React.ReactNode; // Optional buttons for AppBarComponent
  drawerItems?: Array<DrawerItemProps>; // Update type to include position
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  style?: React.CSSProperties;
}

/**
 * The Layout component acts as the main structure of the application.
 * It includes the AppBar, Drawer, and MainContent components.
 * This component manages the state for the drawer's open/close status and the AppBar's height,
 * and it orchestrates the layout of the application.
 */
const Layout: React.FC<LayoutProps> = ({ title, appBarButtons, drawerItems, leftContent, rightContent, style }) => {
  const { toggleDrawer } = useActions();
  const isDrawerOpen = useSelector((state: RootState) => state.settings.isDrawerOpen);
  const [appBarHeight, setAppBarHeight] = useState(0);
  const appBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Update the AppBar height when the AppBar component is mounted or updated
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, []);

  const handleDrawerToggle = useCallback(() => {
    toggleDrawer();
  }, [toggleDrawer]);

  return (
    <Box sx={{ display: "flex", ...style }}>
      <CssBaseline />
      {(appBarButtons || title) && (
        <AppBarComponent isDrawerOpen={isDrawerOpen} handleDrawerToggle={handleDrawerToggle} appBarRef={appBarRef} title={title} actions={appBarButtons} />
      )}
      {drawerItems && <DrawerComponent isDrawerOpen={isDrawerOpen} handleDrawerToggle={handleDrawerToggle} drawerItems={drawerItems} />}
      <MainContent isDrawerOpen={isDrawerOpen} appBarHeight={appBarHeight} leftContent={leftContent} rightContent={rightContent} />
    </Box>
  );
};

export default Layout;
