import { ChangeEvent } from "react";
import { formatISO, isValid } from "date-fns";

/**
 * Handles date input changes for form fields, formatting valid dates as ISO strings,
 * and clearing the field value for invalid or empty inputs.
 *
 * @param event - The change event from the date input field.
 * @param updateValueCallback - A callback function to update the field value in the form state.
 * @param getFieldId - A function to extract the field ID or name from the input's name attribute.
 */
export const handleDateChange = (
  event: ChangeEvent<HTMLInputElement>,
  updateValueCallback: (fieldId: string, value: string | null) => void,
  getFieldId: (name: string) => string
): void => {
  const { value, name } = event.target;
  const fieldId = getFieldId(name);

  if (!value) {
    updateValueCallback(fieldId, null);
  } else {
    // Use the Date constructor to parse the date string
    const parsedDate = new Date(value);
    // Check if the parsedDate is valid using isValid from date-fns,
    // which checks if the Date object represents a valid date
    if (isValid(parsedDate)) {
      // Format the valid date to ISO string using formatISO
      updateValueCallback(fieldId, formatISO(parsedDate));
    } else {
      updateValueCallback(fieldId, null);
    }
  }
};
